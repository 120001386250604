export default class Modal {
  constructor(_parm) {
    this.keyAttr = _parm.modalWrappersAttr
    this.elmsModalWrapper = Array.prototype.slice.call(document.querySelectorAll(`[${_parm.modalWrappersAttr}]`))
    this.attrSetValueRadioSelect = 'data-js-set-value-radio-select'
  }

  /**
   * モーダルを{表示|非表示}
   * @param {Element} _elmModalWrapper モーダルの親要素
   */
  show(_modalWrapper) {
    _modalWrapper.setAttribute('data-active', 'true')
  }
  hide(_modalWrapper) {
    _modalWrapper.setAttribute('data-active', 'false')
  }

  /**
   * 起動ボタンクリックでモーダル起動時に、起動ボタンからユニークなIDを取得して、モーダル内のフォームのaction属性の末尾に追加する
   * @param {Element} _elmModalWrapper モーダルの親要素
   * @param {String} _uniqId モーダルの中の<form>のaction属性の値に追加したいユニークなID
   * HTML側で対応すること
   *   1. <form>にベースとなるaction値を`data-js-base-action`属性で渡しす
   *      例）<form action="" data-js-base-action="/param/">
   *      → モーダル実行時に <form action="/param/取得したid" data-js-base-action="/param/"> になる
   *   2. クリックしたボタンに追加したいIDを`data-js-add-action-id`属性で渡す
   *      例）<a href="" data-js-add-action-id="動的なid" ・・・>
   */
  setFormActionUniqId(_elmModalWrapper, _uniqId) {
    if(!_uniqId || _uniqId === undefined) return;
    const elmForm = _elmModalWrapper.querySelector('form') || false
    if(elmForm) {
      const actionValue = elmForm.getAttribute('data-js-base-action')
      const resultActionValue = actionValue + _uniqId
      elmForm.setAttribute('action', resultActionValue)
    }
  }

  /**
   * this.setFormActionUniqId() のテキスト版
   */
  setFormAddTitleText(_elmModalWrapper, _addText) {
    if(!_addText || _addText === undefined) return;
    const elmsInsertText = Array.prototype.slice.call(_elmModalWrapper.querySelectorAll('[data-js-insert-title-text]'))
    if (elmsInsertText.length > 0) {
      elmsInsertText.forEach(_elmInsertText => {
        _elmInsertText.textContent = _addText
      });
    }
  }

  /**
   * ラジオボタンを選択すると、任意のinput要素に値を反映させる
   * HTML側で対応すること
   *   1. ラジオボタン要素に以下属性を追加
   *     ・this.attrSetValueRadioSelect="任意のinput要素のid"
   *     ・data-value="反映させる値"
   *   2. 任意のinput要素に「id」を付与
   * @param {Element} _elmRadio ラジオボタン要素
   */
  setValueRadioSelect(_elmRadio) {
    const targetInputSelector = _elmRadio.getAttribute(this.attrSetValueRadioSelect)
    const radioValue = _elmRadio.getAttribute('data-value')
    const elmInputTarget = document.querySelector(targetInputSelector)
    elmInputTarget.value = radioValue
  }

  /**
   * イベント付与
   * @param {Element} _elmModalWrapper モーダルの親要素
   */
  addEvent(_elmModalWrapper) {

      /**
       * モーダルを{表示|非表示}
       * @param {Element} _elmModalWrapper モーダルの親要素
       */
      let show = function (_modalWrapper) {
          _modalWrapper.setAttribute('data-active', 'true')
      }
      let hide = function(_modalWrapper) {
          _modalWrapper.setAttribute('data-active', 'false')
      }

      /**
       * 起動ボタンクリックでモーダル起動時に、起動ボタンからユニークなIDを取得して、モーダル内のフォームのaction属性の末尾に追加する
       * @param {Element} _elmModalWrapper モーダルの親要素
       * @param {String} _uniqId モーダルの中の<form>のaction属性の値に追加したいユニークなID
       * HTML側で対応すること
       *   1. <form>にベースとなるaction値を`data-js-base-action`属性で渡しす
       *      例）<form action="" data-js-base-action="/param/">
       *      → モーダル実行時に <form action="/param/取得したid" data-js-base-action="/param/"> になる
       *   2. クリックしたボタンに追加したいIDを`data-js-add-action-id`属性で渡す
       *      例）<a href="" data-js-add-action-id="動的なid" ・・・>
       */
      let setFormActionUniqId = function (_elmModalWrapper, _uniqId) {
          if(!_uniqId || _uniqId === undefined) return;
          const elmForm = _elmModalWrapper.querySelector('form') || false
          if(elmForm) {
              const actionValue = elmForm.getAttribute('data-js-base-action')
              const resultActionValue = actionValue + _uniqId
              elmForm.setAttribute('action', resultActionValue)
          }
      }

      /**
       * this.setFormActionUniqId() のテキスト版
       */
      let setFormAddTitleText = function (_elmModalWrapper, _addText) {
          if(!_addText || _addText === undefined) return;
          const elmsInsertText = Array.prototype.slice.call(_elmModalWrapper.querySelectorAll('[data-js-insert-title-text]'))
          if (elmsInsertText.length > 0) {
              elmsInsertText.forEach(_elmInsertText => {
                  _elmInsertText.textContent = _addText
              });
          }
      }

      /**
       * ラジオボタンを選択すると、任意のinput要素に値を反映させる
       * HTML側で対応すること
       *   1. ラジオボタン要素に以下属性を追加
       *     ・this.attrSetValueRadioSelect="任意のinput要素のid"
       *     ・data-value="反映させる値"
       *   2. 任意のinput要素に「id」を付与
       * @param {Element} _elmRadio ラジオボタン要素
       */
      let setValueRadioSelect= function (_elmRadio) {
          const targetInputSelector = _elmRadio.getAttribute(this.attrSetValueRadioSelect)
          const radioValue = _elmRadio.getAttribute('data-value')
          const elmInputTarget = document.querySelector(targetInputSelector)
          elmInputTarget.value = radioValue
      }

    const key = _elmModalWrapper.getAttribute(this.keyAttr)
    const elm = {
      modalWrapper: _elmModalWrapper,
      openBtns: Array.prototype.slice.call(document.querySelectorAll(`[data-js-modal-open-btn=${key}]`)),
      closeBtns: Array.prototype.slice.call(document.querySelectorAll(`[data-js-modal-close-btn=${key}]`)),
      setValueRadios: Array.prototype.slice.call(_elmModalWrapper.querySelectorAll(`[${this.attrSetValueRadioSelect}]`)),
      radioValueSetBtns: Array.prototype.slice.call(document.querySelectorAll(`[data-js-modal-select-confirm-btn=${key}]`)),
    }
    let getCheckedElmSelectRadio;
      //openBtns
      $(document).on('click', `[data-js-modal-open-btn=${key}]` ,function () {
          let _elmOpenBtn = this;
          show(elm.modalWrapper)
          const actionAddId = _elmOpenBtn.getAttribute('data-js-add-action-id')
          const addTitleText = _elmOpenBtn.getAttribute('data-js-add-title-text')
          setFormActionUniqId(elm.modalWrapper, actionAddId)
          setFormAddTitleText(elm.modalWrapper, addTitleText)
      })

      $(document).on('click', `[data-js-modal-close-btn=${key}]` ,function () {
          let _elmCloseBtn =this;
          hide(elm.modalWrapper)
      })

      $(document).on('click', `[data-js-modal-select-confirm-btn=${key}]` ,function () {
        let _elmSelectConfirmBtn = this;
        setValueRadioSelect(getCheckedElmSelectRadio ? getCheckedElmSelectRadio : elmSetValueRadios[0])
        hide(elm.modalWrapper)
    })

    let full_flag =  elm.modalWrapper.getAttribute('data-js-modal-content-full-flag') || false;
    if(!full_flag){
      elm.modalWrapper.addEventListener('click', (_ev) => {
        if (_ev.target.getAttribute('data-js-modal-content') === key) {
          this.hide(elm.modalWrapper)
        }
      })
    }
    elm.setValueRadios.forEach(_elmSetValueRadio => {
      _elmSetValueRadio.addEventListener('input', () => {
        getCheckedElmSelectRadio = _elmSetValueRadio
      })
    })
  }

  init() {
    this.elmsModalWrapper.forEach(_elmModalWrapper => {
      this.addEvent(_elmModalWrapper);
    })
  }
}
