/**
 * Laravel側で行うこと
 * - 読み込み時に、お気に入り済みの行に対して以下を実行
 *   - <tr>にクラス付与 → <tr class="__checkedFavorite">
 *   - 星の<input>にchecked属性付与
 * - お気に入りチェックボックスの thead > input のidを js-favorite-thead にする（lavelのforも変える）
 *   例）<input type="checkbox" id="js-favorite-thead" class="__star">
 * - dataTable実行のjsに、favoriteTypeを与える 例↓
 *    var dataTable = CUSTOM_DATA_TABLE.init('#js-datatable', {
        order: [2, 'asc'],
        favorite: {
          type: 2,
        },
      });
 */

/**
 * 一覧画面のお気に入りに関するクラス
 * ※ view側でdataTableと引数で紐付ける
 * ※ このファイル内のコメントで出てくる「チェックボックス」は全て「お気に入りのチェックボックス」を指す
 * ※ 参考docs:
 *   https://datatables.net/reference/api/
 *   https://datatables.net/reference/api/rows().nodes()
 */
export default class AsyncFavorite {
  constructor(_parm) {
    this.elmDataTableBefore = _parm.elmDataTableBefore // dataTable化する前の<table>
    this.elmDataTableBeforeChecked = '' // dataTable化する前の<table>
    this.dataTableObject = _parm.dataTableObject // dataTable後のdataTableオブジェクト
    this.dataTableId = _parm.dataTableObject.table().container().id.replace('_wrapper', '') // dataTableを実行する際に使用した<table>のID属性値
    this.elmTheadInputFavorite = _parm.dataTableObject.table().header().rows[0].querySelector('input.__star')
    this.elmsInitDataTableRow = _parm.dataTableObject.rows().nodes()
    this.favoriteType = _parm.favoriteType
    this.classCheckedRow = '__checkedFavorite'
    this.elmToggleFavoriteBtn = document.querySelector('#js-toggle-favorite-btn')
    this.elmToggleFavoriteInputHidden = document.querySelector('#js-toggle-favorite-btn-hidden')
  }

  /**
   * チェックボックスの親の<tr>のcheckedのクラスを切り替える（付けるor外す|付ける|外す）
   * checkedクラスが付いている = チェックボックスにチェック済み
   * @param {Object} _elmStarCheckbox チェックボックスのElementObject
   */
  toggleClassRowChecked(_elmStarCheckbox) {
    if(_elmStarCheckbox.closest('tr').classList.contains(this.classCheckedRow)) {
      _elmStarCheckbox.closest('tr').classList.remove(this.classCheckedRow);
    } else{
      _elmStarCheckbox.closest('tr').classList.add(this.classCheckedRow);
    }
  }
  addClassRowChecked(_elmStarCheckbox) {
    _elmStarCheckbox.closest('tr').classList.add(this.classCheckedRow);
  }
  removeClassRowChecked(_elmStarCheckbox) {
    _elmStarCheckbox.closest('tr').classList.remove(this.classCheckedRow);
  }

  /**
   * すべてのチェックボックスを取得
   * @returns {Array} すべてのチェックボックスのElementObjectのArray
   */
  getElmsAllInput() {
    const elmsRow = this.dataTableObject.rows().nodes();
    return Array.prototype.slice.call(elmsRow).map(_elmRow => {
      return _elmRow.querySelector('input[type="checkbox"].__star');
    });
  }

  /**
   * チェック済みのチェックボックスを全て取得
   * @returns {Array} チェック済みのチェックボックスのElementObjectのArray
   */
  getElmsCheckedInput() {
    const elmsCheckedRow = this.dataTableObject.rows(`.${this.classCheckedRow}`).nodes();
    return Array.prototype.slice.call(elmsCheckedRow).map(_elmCheckedRow => {
      return _elmCheckedRow.querySelector('input[type="checkbox"].__star');
    });
  }

  /**
   * リクエスト用のURLを生成する
   * 例）/api/favorite?type=2&id=5,11,3
   * @param {Array} _elmsCheckedInput チェック済みのinput（全て）
   */
  createRequestUrl(_elmsCheckedInput) {
    const base = '/api/favorite';
    const type = this.favoriteType;
    const getIds = () => {
      const arrayIds = _elmsCheckedInput.map((_elmCheckedInput) => {
        return _elmCheckedInput.value;
      });
      return arrayIds.join(',');
    }
    return `${base}?type=${type}&id=${getIds()}`;
  }

  /**
   * 非同期でリクエスト実行
   * @param {Array} _elmsCheckedInput チェック済みのinput（全て）
   */
  request(_elmsCheckedInput) {
    const resJson = async () => {
      // お気に入り切り替えボタンをloading中にする
      this.elmToggleFavoriteBtn.classList.add('__is-loading');

      const response = await fetch(this.createRequestUrl(_elmsCheckedInput));
      if(response.status == 200) {
        // const data = await response.json();
        // if(data.data.length) return JSON.stringify(data);
        return response;
      } else {
        alert('お気に入り機能の処理に失敗しました。ページをリロードします。\nこのエラーが何度も続く場合、お手数ですがサポートまでお問い合わせをお願い致します。\n' + response.status);
        // throw new Error(response.status);
      }
    }
    resJson()
      // 成功時
      .then(_data => {
        // console.log(_data)

        // お気に入り切り替えボタンのloadingを解除
        this.elmToggleFavoriteBtn.classList.remove('__is-loading');
      })
      // 失敗時
      .catch(_error => {
        // console.log(_error);
        // alert('お気に入り機能の処理に失敗しました。ページをリロードします。\nこのエラーが何度も続く場合、お手数ですがサポートまでお問い合わせをお願い致します。\n' + _error);
        // location.reload();
      });
  }

  /**
   * お気に入りのみを表示する or 全表示
   * @param {Boolean} isNowFavoriteSort trueならお気に入りのみ表示 / falseなら全表示
   *
   * 2021.09.10
   * お気に入りの表示非表示の切り替えはlaravel側で行う
   * jsだと課題多すぎて実現できなかった
   */
  /*
  toggleDisplayFavoriteRow(isNowFavoriteSort) {

    // チェック済みの<tr>を取得しておく
    const elmsCheckedRow = Array.prototype.slice.call(document.querySelectorAll(`#${this.dataTableId} tbody tr input[type="checkbox"].__star:checked`)).map(_elmInput => {
      return _elmInput.closest('tr');
    });

    // dataTable解除
    this.dataTableObject.destroy();

    // お気に入りのみ表示用のベースとなる<table>
    const elmCloneDataTableBefore = this.elmDataTableBefore.cloneNode(true);
    // 全件表示用のベースとなる<table>
    // （全件表示時にチェックしたのを反映させるために使用）
    if(isNowFavoriteSort) {
      const elmCloneDataTableBeforeChecked = document.querySelector(`#${this.dataTableId}`).cloneNode(true);
      this.elmDataTableBeforeChecked = elmCloneDataTableBeforeChecked;
    }

    // <table>の中身を全削除して新たな親となる要素を作成
    const elmDataTable = document.querySelector(`#${this.dataTableId}`);
    while(elmDataTable.firstChild){
      elmDataTable.removeChild(elmDataTable.firstChild)
    }

    // お気に入りのみ表示
    if(isNowFavoriteSort) {
      // <thead>作成
      elmDataTable.appendChild(elmCloneDataTableBefore.querySelector('thead'));
      // <tbody>作成
      elmDataTable.insertAdjacentHTML('beforeend', '<tbody></tbody>');
      elmsCheckedRow.forEach(_elmCheckedRow => {
        elmDataTable.querySelector('tbody').appendChild(_elmCheckedRow);
      });
    }
    // 全件表示
    else{
      elmDataTable.appendChild(this.elmDataTableBeforeChecked.cloneNode(true).querySelector('thead'));
      elmDataTable.appendChild(this.elmDataTableBeforeChecked.cloneNode(true).querySelector('tbody'));
    }

    // dataTable実行
    $(`#${this.dataTableId} thead th`).each(function (_index, _elm) {
      var childHtml = $(this).html()
      var addChildSpan = '<span>' + childHtml + '</span>'
      $(this).html(addChildSpan)
    });
    this.dataTableObject = $(`#${this.dataTableId}`).DataTable({
      displayLength: 50,
      dom: '<"c-js-datatable"<"c-js-datatable__table"t><"c-js-datatable__footer c-card__inner--col"<"c-js-datatable__pager"pi><"c-js-datatable__select-box"l>>>',
      order: [ [ 2, 'asc' ] ],
      lengthMenu: [50, 100, 300, 500, 1000],
      scrollX: true,
      scrollY: '55vh',
      scrollX: true,
      scrollCollapse: true,
      autoWidth: false,
      language: {
        sInfo: '_START_〜_END_件を表示 / _TOTAL_件',
        sInfoFiltered: 'が該当（_MAX_件中）',
        sInfoEmpty: '0件',
        sZeroRecords: '該当するデータはありません',
        sLengthMenu: '表示件数 _MENU_',
        oPaginate: {
          sNext: '',
          sPrevious: '',
        },
      },
    });
  }
  */

  /**
   * テーブルの<thead>のチェックボックスと、<tbody>の各行のチェックボックスにイベント付与
   * @param {Element} _elmTheadInputFavorite <thead>のチェックボックス
   * @param {Array} _elmsTable <tbody>の各行のチェックボックス配列
   */
  addEventTable(_elmTheadInputFavorite, _elmsTable) {
    /**
     * ヘッダーのチェックボックスにイベント付与
     * - 全部の行にcheckedクラス追加|削除
     * - 全部のinputをcheckedにする|解除
     * - リクエスト投げる（全ての行をDBへ登録|全削除）
     */
    _elmTheadInputFavorite.addEventListener('input', (_ev) => {
      // チェック可否
      const isChecked = _ev.currentTarget.checked;
      // リクエスト投げる（全ての行をDBへ登録|全削除）
      if(isChecked) {
        this.request(this.getElmsAllInput());
      } else{
        this.request([]);
      }
      // 一覧の全てのチェックボックスに対して処理する
      Array.prototype.slice.call(_elmsTable.rows().nodes()).forEach(_elmRow => {
        const elmRowCheckbox = _elmRow.querySelector('input[type="checkbox"].__star');
        // checkedクラス追加|削除 と inputをcheckedにする|解除 を実行
        if(isChecked) {
          this.addClassRowChecked(elmRowCheckbox);
          elmRowCheckbox.checked = true;
        } else{
          this.removeClassRowChecked(elmRowCheckbox);
          elmRowCheckbox.checked = false;
        }
      });
    })

    const classCheckedRow = this.classCheckedRow;
    const dataTableObject = this.dataTableObject;
    const elmToggleFavoriteBtn = this.elmToggleFavoriteBtn;
    const favoriteType = this.favoriteType;
    /**
     * 各行の全てのチェックボックスにイベント付与
     */
    const toggleClassRowChecked = function(_elmStarCheckbox) {
      if(_elmStarCheckbox.closest('tr').classList.contains(classCheckedRow)) {
        _elmStarCheckbox.closest('tr').classList.remove(classCheckedRow);
      } else{
        _elmStarCheckbox.closest('tr').classList.add(classCheckedRow);
      }
    }
    const getElmsCheckedInput = function() {
      const elmsCheckedRow = dataTableObject.rows(`.${classCheckedRow}`).nodes();
      return Array.prototype.slice.call(elmsCheckedRow).map(_elmCheckedRow => {
        return _elmCheckedRow.querySelector('input[type="checkbox"].__star');
      });
    }
    const   createRequestUrl = function(_elmsCheckedInput) {
      const base = '/api/favorite';
      const type = favoriteType;
      const getIds = () => {
        const arrayIds = _elmsCheckedInput.map((_elmCheckedInput) => {
          return _elmCheckedInput.value;
        });
        return arrayIds.join(',');
      }
      return `${base}?type=${type}&id=${getIds()}`;
    }
    const request = function(_elmsCheckedInput) {
      const resJson = async () => {
        // お気に入り切り替えボタンをloading中にする
        elmToggleFavoriteBtn.classList.add('__is-loading');
  
        const response = await fetch(createRequestUrl(_elmsCheckedInput));
        if(response.status == 200) {
          // const data = await response.json();
          // if(data.data.length) return JSON.stringify(data);
          return response;
        } else {
          alert('お気に入り機能の処理に失敗しました。ページをリロードします。\nこのエラーが何度も続く場合、お手数ですがサポートまでお問い合わせをお願い致します。\n' + response.status);
          // throw new Error(response.status);
        }
      }
      resJson()
        // 成功時
        .then(_data => {
          // console.log(_data)
  
          // お気に入り切り替えボタンのloadingを解除
          elmToggleFavoriteBtn.classList.remove('__is-loading');
        })
        // 失敗時
        .catch(_error => {
          // console.log(_error);
          // alert('お気に入り機能の処理に失敗しました。ページをリロードします。\nこのエラーが何度も続く場合、お手数ですがサポートまでお問い合わせをお願い致します。\n' + _error);
          // location.reload();
        });
    }
    _elmsTable.on('input','input[type="checkbox"].__star',function () {
        const elmCheckbox = this;
        toggleClassRowChecked(elmCheckbox);
        request(getElmsCheckedInput());
    })
  }

  /**
   * その他のイベント付与
   */
  addEvent() {
    /**
     * お気に入りのみ表示|全表示 の切り替えボタンにイベント付与
     */
    this.elmToggleFavoriteBtn.addEventListener('click', (_ev) => {
      if (this.elmToggleFavoriteBtn.getAttribute('data-js-is-sort') === 'true') {
        this.elmToggleFavoriteBtn.setAttribute('data-js-is-sort', 'false');
        this.elmToggleFavoriteBtn.textContent = '★のみ表示';
        this.elmToggleFavoriteInputHidden.value = '';
        this.elmToggleFavoriteInputHidden.dispatchEvent(new Event('change'));
      } else {
        this.elmToggleFavoriteBtn.setAttribute('data-js-is-sort', 'true');
        this.elmToggleFavoriteBtn.textContent = '全件表示';
        this.elmToggleFavoriteInputHidden.value = '1';
        this.elmToggleFavoriteInputHidden.dispatchEvent(new Event('change'));
      }
    })
  }

  init() {
    this.addEventTable(this.elmTheadInputFavorite, this.dataTableObject)
    this.addEvent()
  }
}
