import { result } from "lodash"
import closest from "./polyfill/closest"

export default class SidebarNav {
  constructor(_parm) {
    this.elmSidebar = document.querySelector('#js-sidebar-nav')
    this.elmNextLinks = Array.prototype.slice.call(this.elmSidebar.querySelectorAll('[data-id]'))
    this.elmChildBlocks = Array.prototype.slice.call(this.elmSidebar.querySelectorAll('.p-sidebar-nav-child__block'))
    this.elmBackBtns = Array.prototype.slice.call(this.elmSidebar.querySelectorAll('.p-sidebar-nav-child__back-btn'))
    this.elmBackToTopBtns = Array.prototype.slice.call(this.elmSidebar.querySelectorAll('.p-sidebar-nav-child__back-top-btn'))
    this.dataFlagAttr = 'data-open'
    this.idParentLevel_1 = 'js-sidebar-nav-child-level-01'
    this.idParentLevel_2 = 'js-sidebar-nav-child-level-02'
    this.lsNavLevel_1 = 'js-sidebar-nav-child-level-01'
    this.lsNavLevel_2 = 'js-sidebar-nav-child-level-02'
  }
  /**
   * 開く
   */
  open(_targetId) {
    this.elmChildBlocks.some(_elmTarget => {
      if (_elmTarget.id === _targetId) {
        _elmTarget.setAttribute(this.dataFlagAttr, 'true')
      }
    })
  }

  /**
   * 閉じる
   */
  close(_selector) {
    _selector.setAttribute(this.dataFlagAttr, 'false')
  }

  /**
   * 開いている階層をページリロードしても保持する
   *   ローカルストレージに、開いている階層IDを保存する
   *   対象イベントは、「次の階層へ進むリンクボタン」と「戻るボタン」
   * @param {Object or false} _elmNextLinkBtn 次の階層へ進むリンクボタン
   * @param {Object or false} _elmBackBtn 戻るボタン
   * 引数のどちらかは`false`で関数を実行する
   */

  setLsNavLevel(_obj) {
    localStorage.removeItem(this.lsNavLevel_1);
    localStorage.removeItem(this.lsNavLevel_2);
    let datas = {
      level_1: '',
      level_2: ''
    }
    /**
     * イベントが「次の階層へ進むリンクボタン」なら
     */
    if(_obj.elmNextLinkBtn) {
      const nowLevelId = _obj.elmNextLinkBtn.getAttribute('data-id');
      const elmNowLevelBlock = this.elmSidebar.querySelector(`#${nowLevelId}`);
      const elmNowLevelBlockParent = elmNowLevelBlock.parentNode;
      if(elmNowLevelBlockParent.id === this.idParentLevel_1){
        datas.level_1 = nowLevelId;
        datas.level_2 = '';
      } else{
        datas.level_1 = _obj.elmNextLinkBtn.closest('.p-sidebar-nav-child__block').id;
        datas.level_2 = nowLevelId;
      }
    }
    /**
     * イベントが「戻るボタン」なら
     */
    if(_obj.elmBackBtn) {
      const elmNowLevelBlock = _obj.elmBackBtn.closest('.p-sidebar-nav-child__block');
      const elmNowLevelToLinkBtn = this.elmSidebar.querySelector(`[data-id=${elmNowLevelBlock.id}]`);
      if(elmNowLevelBlock.parentNode.id === this.idParentLevel_1){
        datas.level_1 = '';
        datas.level_2 = '';
      } else{
        datas.level_1 = elmNowLevelToLinkBtn.closest('.p-sidebar-nav-child__block').id;
        datas.level_2 = '';
      }
      
    }
    /**
     * イベントが「戻るボタン」なら
     */
    if (_obj.elmBackToTopBtn) {
      datas.level_1 = '';
      datas.level_2 = '';
    }
    localStorage.setItem(this.lsNavLevel_1, datas.level_1);
    localStorage.setItem(this.lsNavLevel_2, datas.level_2);
  }

  addEvent() {
    /**
     * 次の階層へのリンクボタン
     */
    this.elmNextLinks.forEach(_elmNextLink => {
      _elmNextLink.addEventListener('click', (_ev) => {
        this.open(_ev.currentTarget.getAttribute('data-id'))
        this.setLsNavLevel({
          elmNextLinkBtn: _ev.currentTarget
        })
        return false;
      })
    })

    /**
     * 戻るボタン
     */
    this.elmBackBtns.forEach(_elmBackBtn => {
      _elmBackBtn.addEventListener('click', (_ev) => {
        this.close(_ev.currentTarget.parentNode)
        this.setLsNavLevel({
          elmBackBtn: _ev.currentTarget
        })
        return false;
      })
    })

    /**
     * Top戻るボタン
     */
    this.elmBackToTopBtns.forEach(_elmBackToTopBtn => {
      _elmBackToTopBtn.addEventListener('click', (_ev) => {
        this.close(_ev.currentTarget.parentNode);
        const elmNowLevelBlock = _ev.currentTarget.closest('.p-sidebar-nav-child__block');
        const elmNowLevelToBlock = this.elmSidebar.querySelector(`[data-id=${elmNowLevelBlock.id}]`).closest('.p-sidebar-nav-child__block');
        this.close(elmNowLevelToBlock);
        this.setLsNavLevel({
          elmBackToTopBtn: _ev.currentTarget
        })
        return false;
      })
    })
  }
  init() {
    this.addEvent()

    // 開いている階層をページリロードしても保持する
    const ls_1 = localStorage.getItem(this.lsNavLevel_1) || false
    const ls_2 = localStorage.getItem(this.lsNavLevel_2) || false
    if(ls_1) this.elmSidebar.querySelector(`#${ls_1}`).setAttribute('data-open', 'true');
    if(ls_2) this.elmSidebar.querySelector(`#${ls_2}`).setAttribute('data-open', 'true');
  }
}
