export default class Tab {
  constructor(_parm) {
    this.navAttr = 'data-js-tab-nav'
    this.contentAttr = 'data-js-tab-content'
    this.navs = Array.prototype.slice.call(document.querySelectorAll(`[${this.navAttr}]`))
    this.contents = Array.prototype.slice.call(document.querySelectorAll(`[${this.contentAttr}]`))
    this.currentClass = '__active'
  }
  show(_elm) {
    // _elm.style.display = 'block'
    // _elm.style.visibility = 'visible'
    // _elm.style.height = 'auto'
    _elm.style.position = 'static'
  }
  hide(_elm) {
    // _elm.style.display = 'none'
    // _elm.style.visibility = 'hidden'
    // _elm.style.height = '0'
    _elm.style.position = 'fixed'
    _elm.style.top = '200%'
    _elm.style.left = '200%'
  }
  addCurrentClass(_elm) {
    _elm.classList.add(this.currentClass)
  }
  removeCurrentClass(_elm) {
    _elm.classList.remove(this.currentClass)
  }
  addEvent() {
    this.navs.forEach(_elmNav => {

      const elmNavTargetContent = document.querySelector(`[${this.contentAttr}=${_elmNav.getAttribute(this.navAttr)}]`)
      if (elmNavTargetContent.style.display === 'none') {
        elmNavTargetContent.style.display = 'block'
        // elmNavTargetContent.style.visibility = 'hidden'
        // elmNavTargetContent.style.height = '0'
        elmNavTargetContent.style.position = 'fixed'
        elmNavTargetContent.style.top = '200%'
        elmNavTargetContent.style.left = '200%'
      }
      // elmNavTargetContent.style.overflow = 'hidden'

      _elmNav.addEventListener('click', (_ev) => {
        _ev.preventDefault();
        const nav = _ev.currentTarget
        const navBrothers = Array.prototype.slice.call(nav.parentNode.children)
        const key = _ev.currentTarget.getAttribute(this.navAttr)
        const target = document.querySelector(`[${this.contentAttr}=${key}]`)
        const targetBrothers = Array.prototype.slice.call(target.parentNode.children)
        navBrothers.forEach(_elmNavBrother => {
          this.removeCurrentClass(_elmNavBrother)
        })
        targetBrothers.forEach(_elmTargetBrother => {
          this.hide(_elmTargetBrother)
        })
        this.show(target)
        this.addCurrentClass(nav)
        if(nav.parentNode.parentNode.classList.contains('p-main-content-header__menu')){
          window.scrollTo(0, 0)
        }
      })
    })
  }
  init() {
    this.addEvent()
  }
}
