/**
 * ラベル印刷に関するクラス
 * マニュアル：https://fnt.atlassian.net/wiki/spaces/FNT/pages/416776351
 */

export default class LabelPrint {
  /**
   * @Property {Element} this.elmLabelModal ラベル印刷のモーダル要素
   * @Property {Element} this.elmInputUseReportId ラベル印刷のreport_idの<input hidden>要素
   *   （valueはbladeで定義したままの場合、ラジオ選択によって値が変わる場合 の2パターンがある）
   * @Property {Element} this.elmInputUseReportId ラベル印刷のidsの<input hidden>要素
   * @Property {Array} this.arrayElmsRadio 全部のラジオボタン要素の配列（ラジオボタンが無い画面ならfalseにする）
   * @Property {Array} this.objDataTable dataTableオブジェクト
   */
  constructor(_parm) {
    this.elmLabelModal = document.querySelector(_parm.labelModal) || false;
    this.elmInputUseReportId = document.querySelector(_parm.inputUseReportId);
    this.elmInputUseReportListType = document.querySelector(_parm.inputUseReportListType) || false;
    this.elmInputCheckedRowIds = document.querySelector(_parm.inputCheckedRowIds);
    this.elmLabelPrint12Id = document.querySelector(_parm.labelPrint12Id) || false;
    this.arrayElmsRadio = _parm.radioInput
      ? Array.prototype.slice.call(document.querySelectorAll(_parm.radioInput))
      : false;
    this.objDataTable = _parm.objDataTable;
    this.attr = {
      // 一覧の各行のidを持つinput（選択したラジオのvalueと、この属性値を紐付ける）
      hasRowIdInput_radioValue: 'data-js-label-print-radio',
    }
    this.labelPrint12ButtonId =  _parm.labelPrint12ButtonId || 'label-pdf-12-button';
  }

  /**
   * ラベル印刷の report_id の<input hidden>のvalueに値をセットする
   * @param {String} _value セットする値
   */
  setUseReportId(_value) {
    this.elmInputUseReportId.value = _value;
  }

  setUseReportListType(_value) {
    if (this.elmInputUseReportListType) {
        this.elmInputUseReportListType.value = _value;
    }
  }

  /**
   * ラベル印刷の ids の<input hidden>のvalueに値をセットする
   * @param {Array} _checkedRows 全てのチェック済みの行要素
   */
  setIds(_checkedRows) {
    // 選択済みのラジオボタンをもとに、どの行IDを取得するかを判別
    const getRadioKey = () => {
      let key = false;
      if(this.arrayElmsRadio.length > 0) {
        for (let i = 0; i < this.arrayElmsRadio.length; i++) {
          const elmRadio = this.arrayElmsRadio[i];
          if(elmRadio.checked) {
            key = elmRadio.getAttribute(this.attr.hasRowIdInput_radioValue);
            break;
          }
        }
      }
      return key;
    }
    const radioKey = getRadioKey();

    // ラジオボタンがあれば選択済みの値とマッチするIDを取得
    const ids = _checkedRows.map(_elmCheckedRow => {
      if(radioKey) {
        return _elmCheckedRow.querySelector(`input[${this.attr.hasRowIdInput_radioValue}="${radioKey}"]`).value;
      } else{
        return _elmCheckedRow.querySelector(`input[${this.attr.hasRowIdInput_radioValue}]`).value;
      }
    });
    this.elmInputCheckedRowIds.value = ids.join(',');
  }
  /**
   * チェック済みの一覧の行を取得
   */
  getCheckedRows() {
    return Array.prototype.slice.call(this.objDataTable.rows().nodes()).filter(_elmRow => {
      return _elmRow.querySelector('input[type="checkbox"][data-js-checkbox]').checked;
    });
  }

  /**
   * イベント付与
   */
  addEvent() {
    /**
     * 全部のラジオボタン要素にイベント付与
     */
    if(this.arrayElmsRadio) {
      this.arrayElmsRadio.forEach(_elmRadio => {
        _elmRadio.addEventListener('input', (_ev) => {
          const value = _elmRadio.value;
          const listType = _elmRadio.getAttribute('listType');
          // ラベル印刷の report_id の<input hidden>のvalueに値をセットする
          this.setUseReportId(value);
          this.setUseReportListType(listType);
        });
      });
    }

    /**
     * フォーム送信時
     */
    this.elmLabelModal.querySelector('form').addEventListener('submit', (_ev) => {
      _ev.preventDefault();
      // ラベル印刷の ids の<input hidden>のvalueに値をセットする
      this.setIds(this.getCheckedRows());
      //ラベル印刷の12枚場合
        if (this.elmLabelPrint12Id){
            if(_ev.submitter.parentNode.id == this.labelPrint12ButtonId){
                this.elmLabelPrint12Id.value = 'true';
            } else {
                this.elmLabelPrint12Id.value = '';
            }
        }
      // 送信
      if (this.elmInputCheckedRowIds.value == '') {
        alert('印刷の行をチェックしてください。')
      } else{
        _ev.target.submit();
      }
    });
  }

  init() {
    if(!this.elmLabelModal) return;
    this.addEvent();

    // ページ読み込み時にラジオボタン要素があれば、ラベル印刷のIDの<input hidden>のvalueに値をセットする
    if(this.arrayElmsRadio) {
      const value = this.arrayElmsRadio[0].value;
      const listType = this.arrayElmsRadio[0].getAttribute('listType');;
      this.setUseReportId(value);
      this.setUseReportListType(listType);
    }
  }

}
