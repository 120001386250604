export default class SelectSearch {
  constructor(_parm) {
    this.elmParents = Array.prototype.slice.call(document.querySelectorAll('.c-select-search'))
    this.attrActive = 'data-active'
    this.attrSelectInputLabel = 'data-js-select-search-input-label'
    this.attrSelectInputValue = 'data-js-select-search-input-value'
    this.attrSelectSearchBox = 'data-js-select-search-box'
    this.attrSelectBtn = 'data-js-select-btn'
    this.attrSelectBank = 'data-js-select-search-bank'
    this.attrSelectBankStore = 'data-js-select-search-bank-store'
  }

  /**
   * セレクトボックスを{表示|非表示}
   * @param {Element} _elmSelect セレクトボックスの親要素
   */
  hideSelect(_elmSelect) {
    _elmSelect.setAttribute(this.attrActive, 'false');
  }
  showSelect(_elmSelect) {
    _elmSelect.setAttribute(this.attrActive, 'true');
    // クリックした時に、セレクトボックスの位置によっては画面外に見切れることがあるから、
    // 座標を取得して位置を調整する
    _elmSelect.style.marginLeft = 'auto';
    const offsetX = _elmSelect.getBoundingClientRect().left;
    const selectWidth = _elmSelect.getBoundingClientRect().width;
    const windowWidth = window.innerWidth;
    if(offsetX + selectWidth > windowWidth) {
      _elmSelect.style.marginLeft = `${windowWidth - (offsetX + selectWidth) - 50}px`;
    } else{
      _elmSelect.style.marginLeft = 'auto';
    }
  }

  /**
   * inputに値を反映（セレクトボックスの選択肢のボタンクリック時に実行）
   * @param {Element} _elmSelectBtn セレクトボックスの選択肢のボタン
   * @param {Element} _elmInputLabel inputの表示用
   * @param {Element} _elmInputValue inputのDB保存用
   */
  setInputValue(_elmSelectBtn, _elmInputLabel, _elmInputValue) {
    const label = _elmSelectBtn.textContent;
    const value = _elmSelectBtn.value || _elmSelectBtn.textContent;
    _elmInputLabel.value = label;
    _elmInputValue.value = value;
  }

  /**
   * セレクトボックスを検索
   * @param {Object} _ev 検索inputのイベントオブジェクト
   * @param {Array} _selectBtns セレクトボックスの選択肢のボタン全て
   */
  searchSelect(_ev, _selectBtns) {
    const inputValue = _ev.target.value;
    // 最初に絞り込みリセット
    this.sortResetSearchSelect(_selectBtns);
    // 入力値空になったら絞り込みリセット
    if(!inputValue) {
      return;
    }
    // 入力値にマッチしなかったら選択肢を非表示
    _selectBtns.forEach(_elmSelectBtn => {
      if(!_elmSelectBtn.textContent.match(inputValue)){
        _elmSelectBtn.parentNode.style.display = 'none';
      }
    });
  }

  /**
   * 絞り込んだセレクトボックスをリセット
   * @param {Array} _selectBtns セレクトボックスの選択肢のボタン全て
   */
  sortResetSearchSelect(_selectBtns) {
    _selectBtns.forEach(_elmSelectBtn => {
      _elmSelectBtn.parentNode.style.display = 'block';
    });
  }

  addEvent() {
    this.elmParents.forEach(_elmParent => {
      const elm = {
        // 出力用input（表示専用）
        inputLabel: _elmParent.querySelector(`.c-select-search__input input[${this.attrSelectInputLabel}]`),
        // 出力用input（DBへ値渡す専用（非表示））
        inputValue: _elmParent.querySelector(`.c-select-search__input input[${this.attrSelectInputValue}]`),
        // 検索ボックスinput
        searchInput: _elmParent.querySelector('.c-select-search__dropdown-search input'),
        // セレクトボックスの親要素
        selectParent: _elmParent.querySelector('.c-select-search__dropdown'),
        // セレクトボックスの選択肢ボタン（複数）
        selectBtns: Array.prototype.slice.call(_elmParent.querySelectorAll('.c-select-search__dropdown .c-dropdown-menu__item button')),
        // セレクトボックスの選択肢ボタン（複数）を取得（動的に選択肢を変える時があるから、入力時ごとに取得する）
        getSelectBtns: function() {
          return Array.prototype.slice.call(this.selectParent.querySelectorAll('.c-select-search__dropdown .c-dropdown-menu__item button'));
        }
      }

      /**
       * inputにフォーカス - セレクトボックスを表示する
       */
      elm.inputLabel.addEventListener('focus', (_ev) => {
        // elm.searchInput.value = ''; // 検索ボックスの値を最初にリセット
        // this.sortResetSearchSelect(elm.getSelectBtns()); // 絞り込みを最初にリセット
        this.showSelect(elm.selectParent);
      })

      /**
       * セレクトボックス以外クリック - セレクトボックスを非表示にする
       */
      document.addEventListener('click', (_ev) => {
        if(_ev.target === elm.inputLabel) return;
        if(_ev.target.hasAttribute(this.attrSelectSearchBox)) return;
        if(_ev.target.firstElementChild) {
          if(_ev.target.firstElementChild.hasAttribute(this.attrSelectSearchBox)) return;
        }
        this.hideSelect(elm.selectParent);
        if (!_ev.target.hasAttribute(this.attrSelectBtn)) {
          // 検索ボックス絞り込み全解除
          // elm.selectBtns.forEach(_elmSelectBtn => {
          //   _elmSelectBtn.parentNode.style.display = 'block';
          // });
        }
      })

      /**
       * セレクトボックスの選択肢クリック - inputに値を反映
       */
      elm.selectBtns.forEach(_elmSelectBtn => {
        _elmSelectBtn.addEventListener('click', (_ev) => {
          this.setInputValue(_elmSelectBtn, elm.inputLabel, elm.inputValue);
          // セレクトボックスが「銀行名+支店名」の銀行名なら
          if(elm.inputLabel.hasAttribute(this.attrSelectBank)) {
            if(elm.inputLabel.value === '') {
              this.toggleBankStoreDisabled(elm.inputLabel, true);
            } else{
              this.toggleBankStoreDisabled(elm.inputLabel, false);
            }
          }
        })
      })

      /**
       * 検索ボックス 入力時 - 選択肢を絞り込む
       */
      elm.searchInput.addEventListener('input', (_ev) => {
        this.searchSelect(_ev, elm.getSelectBtns());
      })

    });
  }

  /**
   * html側で動的に追加した選択肢ボタンに直接クリックイベントを追加するを想定したメソッド
   */
  selectBtnClick(_elmSelectBtn, _elmInputLabel, _elmInputValue) {
    this.setInputValue(_elmSelectBtn, _elmInputLabel, _elmInputValue);
    // セレクトボックスが「銀行名+支店名」の銀行名なら
    if(_elmInputLabel.hasAttribute(this.attrSelectBank)) {
      if(_elmInputLabel.value === '') {
        this.toggleBankStoreDisabled(_elmInputLabel, true);
      } else{
        this.toggleBankStoreDisabled(_elmInputLabel, false);
      }
    }
  }

  /**
   * 「銀行名 & 支店名」のセットのセレクトボックスのページ読み込み時の処理
   */
  bankInit() {
    const elmsBankInput = Array.prototype.slice.call(document.querySelectorAll('[data-js-select-search-bank]'));
    elmsBankInput.forEach(_elmBankInput => {
      if(_elmBankInput.value === '') {
        this.toggleBankStoreDisabled(_elmBankInput, true);
      }
    });
  }

  /**
   * 「銀行名 & 支店名」のセットのセレクトボックスで、支店名のdisabledを切り替え
   * @param {Element} _elmBankInput 銀行のセレクトボックス要素
   * @param {Boolean} _isDisabled true or false. trueでdisabledにする
   */
  toggleBankStoreDisabled(_elmBankInput, _isDisabled) {
    const elmBankStoreInput = document.querySelector(`[data-js-select-search-bank-store="${_elmBankInput.getAttribute('data-js-select-search-bank')}"]`);
    // disabledにする
    if(_isDisabled) {
      elmBankStoreInput.style.pointerEvents = 'none';
      elmBankStoreInput.value = '銀行名を選択';
    }
    // disabled解除
    else{
      elmBankStoreInput.style.pointerEvents = 'auto';
      elmBankStoreInput.value = '';
    }
  }

  init() {
    this.addEvent()
    this.bankInit()
  }
}
