/**
 * 一覧画面のDataTableヘッダーの行選択チェックボックスをチェックで全選択/全解除のクラス
 */

export default class DataTableCheckAll {
  constructor(_parm) {
    // dataTable実行後のdataTableオブジェクト
    this.dataTableObject = _parm.dataTableObject;
    // ヘッダーの行選択チェックボックス要素
    this.elmTargetCheckbox = Array.prototype.slice.call(this.dataTableObject.table().header().rows[0].querySelectorAll('input[type="checkbox"]')).find(_elmCheckbox => {
      return !_elmCheckbox.classList.contains('__star');
    });
  }

  /**
   * 全てのチェックボックスをチェック/解除
   * @param {Boolean} _doChecked trueなら全チェック、falseなら全解除
   */
  toggleCheckAll(_doChecked) {
    const arrayElmsCheckbox = this.dataTableObject.rows().nodes().map(_elmRow => {
      return _elmRow.querySelector('input[type="checkbox"]:not(.__star)');
    });
    Array.prototype.slice.call(arrayElmsCheckbox).forEach(_elmCheckbox => {
      _elmCheckbox.checked = _doChecked;
    });
  }

  addEvent() {
    // ヘッダーの行選択チェックボックス要素クリックで、全チェック/全解除
    this.elmTargetCheckbox.addEventListener('input', (_ev) => {
      const isChecked = this.elmTargetCheckbox.checked;
      this.toggleCheckAll(isChecked);
    });
  }

  init() {
    if(!this.elmTargetCheckbox) return;
    this.addEvent()
  }
}
