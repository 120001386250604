/**
 * 一覧画面のDataTableヘッダーの行選択チェックボックスをチェックで全オフ/全オン/反転のクラス
 */

export default class DataTableCheck {
  constructor(_parm) {
    // dataTable実行後のdataTableオブジェクト
    this.dataTableObject = _parm.dataTableObject;
    // ヘッダーの行選択チェックボックス要素
    this.elmTargetCheckOn = Array.prototype.slice.call(document.querySelectorAll('[data-js-modal-content="table-check"] .table-check-all-on'));
    this.elmTargetCheckOff = Array.prototype.slice.call(document.querySelectorAll('[data-js-modal-content="table-check"] .table-check-all-off'));
    this.elmTargetCheckReverse = Array.prototype.slice.call(document.querySelectorAll('[data-js-modal-content="table-check"] .table-check-all-reverse'));
  }

  /**
   * 全オン
   */
  toggleCheckAllOn() {
    const arrayElmsCheckbox = this.dataTableObject.rows().nodes().map(_elmRow => {
      return _elmRow.querySelector('input[type="checkbox"]:not(.__star)');
    });
    Array.prototype.slice.call(arrayElmsCheckbox).forEach(_elmCheckbox => {
      _elmCheckbox.checked = true;
    });
    if ($('#js-toggle-check-btn') && $('#js-toggle-check-btn').attr('data-js-status') == 'true') {
      this.dataTableObject.draw(true);
    } else {
      this.dataTableObject.draw(false);
    }
  }

  /**
   * 全オフ
   */
  toggleCheckAllOff() {
    const arrayElmsCheckbox = this.dataTableObject.rows().nodes().map(_elmRow => {
      return _elmRow.querySelector('input[type="checkbox"]:not(.__star)');
    });
    Array.prototype.slice.call(arrayElmsCheckbox).forEach(_elmCheckbox => {
      _elmCheckbox.checked = false;
    });
    if ($('#js-toggle-check-btn') && $('#js-toggle-check-btn').attr('data-js-status') == 'true') {
      this.dataTableObject.draw(true);
    } else {
      this.dataTableObject.draw(false);
    }
  }

  /**
   * 反転
   */
  toggleCheckAllReverse() {
    const arrayElmsCheckbox = this.dataTableObject.rows().nodes().map(_elmRow => {
      return _elmRow.querySelector('input[type="checkbox"]:not(.__star)');
    });
    Array.prototype.slice.call(arrayElmsCheckbox).forEach(_elmCheckbox => {
      _elmCheckbox.checked = !_elmCheckbox.checked;
    });
    if ($('#js-toggle-check-btn') && $('#js-toggle-check-btn').attr('data-js-status') == 'true') {
      this.dataTableObject.draw(true);
    } else {
      this.dataTableObject.draw(false);
    }
  }


  addEvent() {
    this.elmTargetCheckOn.forEach(_elmTargetCheckOn => {
      _elmTargetCheckOn.addEventListener('click', (_ev) => {
        this.toggleCheckAllOn();
      });
    });
    this.elmTargetCheckOff.forEach(_elmTargetCheckOff => {
      _elmTargetCheckOff.addEventListener('click', (_ev) => {
        this.toggleCheckAllOff();
      });
    });
    this.elmTargetCheckReverse.forEach(_elmTargetCheckReverse => {
      _elmTargetCheckReverse.addEventListener('click', (_ev) => {
        this.toggleCheckAllReverse();
      });
    });
  }

  init() {
    if(!this.elmTargetCheckOn && !this.elmTargetCheckOff && !this.elmTargetCheckReverse) return;
    this.addEvent()
  }
}
