/**
 * 一覧に関するクラス
 * 主な機能
 * ・全ての<textarea>の見出し要素に、一覧起動ボタンを追加（textareaに.__not-text-templateを付与で除外可能）
 * ・一覧起動ボタンクリックで、非同期で一覧を取得＆結果モーダルを表示
 */
export default class AsyncCopyModel {
    constructor(_parm) {
        this.elm = {
            nowLoading: document.querySelector('#js-nowloading'),
            resultModal: document.querySelector(`[js-async-room-copy-modal]`),
            cloneInitResultTable: false, // this.createTextTemplateTable()の最後で、全件データを持った一覧<table>をcloneしてセット
        }
        this.elms = {
            attrModalOpenBtn: Array.prototype.slice.call(document.querySelectorAll(`[${_parm.attrModalOpenBtn}]`)),
            attrModalOpenBtnAttr : _parm.attrModalOpenBtn
        }
        this.resultModal = {
            wrapper: 'js-async-room-copy-modal',
            form: 'js-async-room-copy-modal-form',
            table: 'js-async-room-copy-modal-table',
        }
        this.requestUrl = 'data-js-copy-modal-url'
        this.resultSubmitRul = 'data-js-copy-modal-submit-url'
        this.dataTable = false;
        this.resultSubmitElm = 'data-js-async-copy-submit-elm'
    }

    /**
     * データをサーバーから非同期で取得する
     * @param {String} _requestUrl リクエスト用URL
     * @returns resJson() リクエストによって返ってきたオブジェクト
     */
    getRequestData(_requestUrl) {
        const resJson = async () => {
            const response = await fetch(_requestUrl);
            if(response.status == 200) {
                const data = await response.json();
                if(data.data.length) return JSON.stringify(data);
                return false;
            } else {
                throw new Error(response.status);
            }
        }
        return resJson();
    }

    /**
     * 取得したデータを元に、一覧の<table>を生成する
     * @param {Object} _requestData this.getRequestData()で取得したデータ
     * @param {String} _key this.elms.attrModalOpenBtn.getAttribute(this.elms.attrModalOpenBtnAttr)
     */
    createCopyTable(_requestData,_key) {
        const datas = JSON.parse(_requestData).data;
        const elmOutputTableWrapper = document.querySelector(`[${this.resultModal.table}='${_key}']`);

        // <table>の子要素を全て削除
        const resetTable = () => {
            if (elmOutputTableWrapper.querySelector('table')) {
                elmOutputTableWrapper.querySelector('table').remove();
            }
        }

        // <table>の中身を生成
        const createTableDom = () => {
            // DOM生成用の配列を作成（`order`を基準に`datas`を並び替える）
            const getArrayTableData = () => {
                let resultArray = [];
                for (let i = 0; i < datas.length; i++) {
                    const data = datas[i];
                    // ↓が<table>の一行ごとのデータになる
                    let arrayRowDatas = [];
                    Object.keys(data).forEach(_column => {
                        data[_column].key = _column;
                        arrayRowDatas.push(data[_column]);
                    });
                    // orderの順番に並び替える
                    arrayRowDatas.sort((a, b) => {
                        return a.order - b.order;
                    });
                    resultArray.push(arrayRowDatas);
                };
                return resultArray;
            }
            const arrayTableData = getArrayTableData();

            // 以降でDOMを生成していく
            let createDom = '<table>';

            // <thead> 生成（最初の空の<th>はラジオボタン用）
            createDom += '<thead><tr><th class="__hide-menu" style="width:40px;"></th>';
            arrayTableData[0].forEach(_columnData => {
                if(typeof _columnData.order === 'number') {
                    createDom += `<th>${_columnData.label}</th>`;
                } else{
                    createDom += `<th style="display: none;">${_columnData.label}</th>`;
                }
            });
            createDom += '<th class="__hide-menu" style="width:100%"></th></tr></thead>';

            // <tbody> 生成
            createDom += '<tbody>';
            arrayTableData.forEach((_rowData, _index) => {
                createDom += '<tr>';
                createDom += `
          <td>
            <input type="checkbox" id="target-room-copy-check-${_index}" checked>
            <label for="target-room-copy-check-${_index}" class="u-pl-0">&nbsp;</label>
            </label>
          </td>`;
                _rowData.forEach(_columnData => {
                    if(typeof _columnData.order === 'number') {
                        if(_columnData.value) {
                            createDom += `<td data-key="${_columnData.key}">${_columnData.value}</td>`;
                        } else{
                            createDom += `<td data-key="${_columnData.key}" class="u-text-center">-</td>`;
                        }
                    } else{
                        createDom += `<td style="display: none;" data-key="${_columnData.key}">${_columnData.value}</td>`;
                    }
                });
                createDom += '<td></td></tr>';
            });
            createDom += '</tbody>';
            createDom += '</table>';
            elmOutputTableWrapper.insertAdjacentHTML('beforeend', createDom);
        };

        resetTable();
        createTableDom();
    }

    /**
     * dataTable実行
     */
    dataTableInit(_tableSelector) {
        $(`${_tableSelector} thead th`).each(function (_index, _elm) {
            var childHtml = $(this).html()
            var addChildSpan = '<span>' + childHtml + '</span>'
            $(this).html(addChildSpan)
        });
        this.dataTable = $(_tableSelector).DataTable({
            displayLength: 99999,
            dom: '<"c-js-datatable"<"c-js-datatable__table"t>>',
            order: [ [ 2, 'asc' ] ],
            scrollX: true,
        });
    }

    /**
     * 非同期でリクエストを投げてから一覧モーダルを表示までの処理
     */
    initResultModal(_elmOpenBtn) {

        const key = _elmOpenBtn.getAttribute(this.elms.attrModalOpenBtnAttr);
        // ローディングを表示
        this.elm.nowLoading.setAttribute('data-active', 'true');

        // リクエスト実行
        this.getRequestData(_elmOpenBtn.getAttribute(this.requestUrl))
            // 成功時
            .then(_data => {
                // 1件もマッチしなかったら
                if(!_data){
                    this.elm.nowLoading.setAttribute('data-active', 'false');
                    alert('一覧のデータがありませんでした。')
                    return;
                }

                // dataTableを削除
                if(this.dataTable) {
                    this.dataTable.destroy();
                }

                // 取得したデータを元に、一覧の<table>を生成する
                this.createCopyTable(_data, key);

                // dataTable化する前の<table>をプロパティにセット
                this.elm.cloneInitResultTable = document.querySelector(`[${this.resultModal.table}='${key}'] table`).cloneNode(true);

                // dataTableを実行
                this.dataTableInit(`[${this.resultModal.table}='${key}'] table`);

                // 検索結果モーダル起動
                this.elm.resultModal.setAttribute('data-active', 'true');

                // ローディング解除
                this.elm.nowLoading.setAttribute('data-active', 'false');

            })
            // 失敗時
            .catch(_error => {
                // console.log(_error);
                alert('一覧の読み込みに失敗しました。\nこのエラーが何度も続く場合、お手数ですがサポートまでお問い合わせをお願い致します。\n' + _error)
                this.elm.nowLoading.setAttribute('data-active', 'false');
            });
    }


    /**
     * 各要素にイベントを付与
     */
    addEvent(_elmOpenBtn) {

        const key = _elmOpenBtn.getAttribute(this.elms.attrModalOpenBtnAttr);
        const wrapper = document.querySelector(`[${this.resultModal.wrapper}='${key}']`);
        const form = document.querySelector(`[${this.resultModal.form}='${key}']`);
        const resultSubmitElm = this.resultSubmitElm;

        /**
         * 一覧モーダルを閉じる
         */
        const closeResultModal = function () {
            wrapper.setAttribute('data-active', 'false');
        }

        /**
         * 一覧モーダル 閉じるボタン クリック
         */
        Array.prototype.slice.call(document.querySelector(`[${this.resultModal.wrapper}='${key}']`).querySelectorAll(`[data-js-modal-close-btn]`)).forEach(_elmCloseBtn => {
            $(_elmCloseBtn).off('click').on('click', function () {
                closeResultModal();
            });
        });

        /**
         * HTML側で直接書いている、一覧モーダル起動ボタン クリック
         * （HTML側で直接書いている = DOM構造が違って、this.createResultModalOpenBtn()で追加できなかったケース）
         */
        _elmOpenBtn.addEventListener('click', (_ev) => {
            this.initResultModal(_elmOpenBtn);
            let action = _elmOpenBtn.getAttribute(this.resultSubmitRul);
            form.setAttribute('action', action);
            /**
             * 一覧モーダル 右上確定ボタン クリック
             */
            $(form).find(`[data-js-modal-submit-btn]`).off('click').on('click', function () {

                let confirm = window.confirm('この処理は再読込みします。\n' +
                    '未保存の情報が無くなります。\n' +
                    'よろしいでしょうか？');
                if (!confirm){
                    return;
                }
                let arrayElmsSubmit = _elmOpenBtn.getAttribute(resultSubmitElm).split(',').map(_data => {
                    let arraySplitData = _data.split(':');
                    return {
                        submitName: arraySplitData[0],
                        selector: arraySplitData[1]
                    }
                });
                arrayElmsSubmit.forEach(_elm => {
                    form.querySelectorAll(`input[name="${_elm.submitName}"]`).forEach(_elm => {
                        _elm.remove();
                    });
                });
                let formHtml = '';
                arrayElmsSubmit.forEach(_elm => {
                    if (_elm.submitName.indexOf('[]') === -1){
                        formHtml += `<input type="hidden" name="${_elm.submitName}" value="${_elm.selector}">`;
                    }
                });
                form.querySelectorAll('tbody tr').forEach(_tr =>{
                    if (_tr.querySelector('input[type="checkbox"]').checked){
                        arrayElmsSubmit.forEach(_elm =>{
                            if ( _elm.submitName.indexOf('[]') !== -1 && _tr.querySelector(`[data-key=${_elm.selector}]`)){
                                formHtml += `<input type="hidden" name="${_elm.submitName}" value="${_tr.querySelector(`[data-key=${_elm.selector}]`).textContent}">`;
                            }
                        })
                    }
                });
                form.insertAdjacentHTML('afterbegin',formHtml);
                let params = $(form).serializeJSON();
                $.tobilark.apiSend('post', action, params,
                    function (data) {
                       $(form).find('[data-js-modal-close-btn]').click();
                       $.popupSucceedMessage(data.message);
                    },
                    function (e) {
                        $(form).find('[data-js-modal-close-btn]').click();
                        $.popupErrorMessage(e.responseJSON.errorMsg);
                    }, true,
                );
            });
        });
    }

    init() {
        this.elms.attrModalOpenBtn.forEach(_elmOpenBtn => {
            // イベント付与
            this.addEvent(_elmOpenBtn);
        });
    }
}
