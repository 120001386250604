import { isNumber } from "lodash"

/**
 * サーバーからデータを取得するクラス
 */
export default class AsyncGetSearchResult {
  /**
   * attr.searchModalOpenBtn
   * 検索モーダル起動ボタンにセットする属性。属性値 = 起動する検索モーダルのセレクター
   *
   * attr.resultElmData
   * 検索モーダル起動ボタンにセットする属性。属性値 = `出力先要素セレクター:dataのカラム名`の形式 ※複数ならカンマ区切り
   * 例）<tagname data-js-async-search-result-elm="出力先要素セレクター:dataのカラム名,出力先要素セレクター:dataのカラム名">
   *
   * attr.searchModalForm
   * 検索モーダルのフォーム
   *
   * attr.srcSearchModal
   * 検索結果モーダルにセットする属性
   * 検索結果モーダル起動時に、参照元の検索モーダルのidを属性値にセットする用
   */
  /**
   * elm.resultModa 検索結果モーダル関係
   *   wrapper 最上位親要素
   *   form <form>要素
   *   outputTable 結果を表示させる<table>要素
   * elm.nowLoading ローディング中の要素
   */
  /**
   * this.localStorageElmsSearchResultName ローカルストレージで使用するkey名
   * this.createSearchResultInputRadioName 検索結果モーダルの結果一覧 table > tr で使うラジオボタンのname値
   * this.dataTable dataTableを実行していればそのオブジェクト、していなければfalse
   */
  constructor() {
    this.attr = {
      searchModalOpenBtn: 'data-js-async-search-modal-open-btn',
      searchModalNotOpenFlag: 'data-js-async-search-modal-not-open-flag',
      resultElmData: 'data-js-async-search-result-elm',
      searchModalForm: 'data-js-async-search-modal-form',
      srcSearchModal: 'data-js-async-search-src-modal',
      idCheckTag: 'data-js-async-select-id-check'
    }
    this.id = {
      resultModal: {
        wrapper: 'js-async-result-modal',
        form: 'js-async-result-modal-form',
        outputTableWrapper: 'js-async-result-modal-output-table-wrapper',
      },
    }
    this.elm = {
      resultModal: {
        wrapper: document.querySelector(`#${this.id.resultModal.wrapper}`),
        form: document.querySelector(`#${this.id.resultModal.wrapper} #${this.id.resultModal.form}`),
        outputTableWrapper: document.querySelector(`#${this.id.resultModal.wrapper} #${this.id.resultModal.outputTableWrapper}`),
      },
      nowLoading: document.querySelector('#js-nowloading'),
    }
    this.localStorageElmsSearchResultName = 'ElmsSearchResult'
    this.localStorageElmsIdCheckSelectorName = 'ElmsIdCheckSelector'
    this.createSearchResultInputRadioName = 'search_result_table_row'
    this.dataTable = false;
  }

  /**
   * 検索モーダルを起動する
   * @param {Element} _elmSearchModalOpenBtn 検索モーダル起動ボタン
   */
  openSearchModal(_elmSearchModalOpenBtn) {
    const elmSearchModal = document.querySelector(`${_elmSearchModalOpenBtn.getAttribute(this.attr.searchModalOpenBtn)}`);
    if (!_elmSearchModalOpenBtn.hasAttribute(this.attr.searchModalNotOpenFlag)) {
      elmSearchModal.setAttribute('data-active', 'true');
    } else {
      elmSearchModal.querySelector(`button[type='submit']`).click();
    }
  }

  /**
   * ローカルストレージに、検索結果を表示させる要素のデータをセットする
   * @param {String} _datas セットするデータ = `出力先要素セレクター:dataのカラム名`の形式 ※複数ならカンマ区切り
   */
  setLsElmsSearchResult(_datas) {
    // Stringのデータを多次元連想配列に変換
    const arrayElmsSearchResult = _datas.split(',').map(_data => {
      let arraySplitData = _data.split(':');
      return {
        selector: arraySplitData[0],
        dataColumnName: arraySplitData[1]
      }
    });
    // ↑の多次元連想配列をjsonに変換してローカルストレージにセット
    const jsonStringify = JSON.stringify(arrayElmsSearchResult);
    localStorage.setItem(this.localStorageElmsSearchResultName, jsonStringify);
  }

  /**
   * ローカルストレージの「検索結果を表示させる要素のデータ」を 取得|削除 する
   */
  getLsElmsSearchResult() {
    return JSON.parse(localStorage.getItem(this.localStorageElmsSearchResultName));
  }
  removeLsElmsSearchResult() {
    localStorage.removeItem(this.localStorageElmsSearchResultName);
  }

  setLsElmsIdCheckSelector(_datas){
      const arrayElmsSearchResult = _datas.split(',').map(_data => {
          let arraySplitData = _data.split(':');
          return {
              selector: arraySplitData[0],
              dataColumnName: arraySplitData[1]
          }
      });
      // ↑の多次元連想配列をjsonに変換してローカルストレージにセット
      const jsonStringify = JSON.stringify(arrayElmsSearchResult);
      localStorage.setItem(this.localStorageElmsIdCheckSelectorName, jsonStringify);
  }

    getLsElmsIdCheck() {
        return JSON.parse(localStorage.getItem(this.localStorageElmsIdCheckSelectorName));
    }
    removeLsElmsIdCheck() {
        localStorage.removeItem(this.localStorageElmsIdCheckSelectorName);
    }
  /**
   * データをサーバーから非同期で取得する
   * @param {String} _requestUrl リクエスト用URL
   * @returns resJson() リクエストによって返ってきたオブジェクト
   */
  getRequestData(_requestUrl) {
    const resJson = async () => {
      const response = await fetch(_requestUrl);
      if(response.status == 200) {
        const data = await response.json();
        if(data.data.length) return JSON.stringify(data);
        return false;
      } else {
        throw new Error(response.status);
      }
    }
    return resJson();
  }

  /**
   * 検索結果モーダルを初期化
   * ・取得したデータを元に、<table>を生成する
   * ・各jsイベントを設定する
   * @param {Object} _requestData this.getRequestData()で取得したデータ
   */
  initResultModal(_requestData) {
    const datas = JSON.parse(_requestData).data;
    const elmOutputTable = this.elm.resultModal.outputTable;
    const elmOutputTableWrapper = this.elm.resultModal.outputTableWrapper;

    // <table>の子要素を全て削除
    const resetTable = () => {
      if (elmOutputTableWrapper.querySelector('table')) {
        elmOutputTableWrapper.querySelector('table').remove();
      }
      // while (elmOutputTable.firstChild) {
      //   elmOutputTable.removeChild(elmOutputTable.firstChild);
      // }
    }

    // <table>の中身を生成
    const createTableDom = () => {
      // DOM生成用の配列を作成（`order`を基準に`datas`を並び替える）
      const getArrayTableData = () => {
        let resultArray = [];
        for (let i = 0; i < datas.length; i++) {
          const data = datas[i];
          // ↓が<table>の一行ごとのデータになる
          let arrayRowDatas = [];
          Object.keys(data).forEach(_column => {
            data[_column].key = _column;
            arrayRowDatas.push(data[_column]);
          });
          // orderの順番に並び替える
          arrayRowDatas.sort((a, b) => {
            return a.order - b.order;
          });
          resultArray.push(arrayRowDatas);
        };
        return resultArray;
      }
      const arrayTableData = getArrayTableData();

      // 以降でDOMを生成していく
      let createDom = '<table>';

      // <thead> 生成（最初の空の<th>はラジオボタン用）
      createDom += '<thead><tr><th class="__hide-menu" style="width:40px;"></th>';
      arrayTableData[0].forEach(_columnData => {
        if(typeof _columnData.order === 'number') {
          createDom += `<th>${_columnData.label}</th>`;
        } else{
          createDom += `<th style="display: none;">${_columnData.label}</th>`;
        }
      });
      createDom += '<th class="__hide-menu" style="width:30px"></th></tr></thead>';

      // <tbody> 生成
      createDom += '<tbody>';
      arrayTableData.forEach((_rowData, _index) => {
        createDom += '<tr>';
        createDom += `
          <td>
            <label class="__radio" style="padding-left: 0;">
              <input type="radio" name="${this.createSearchResultInputRadioName}" ${this.getCheckedData(_rowData,this.getLsElmsSearchResult()) ? 'checked' : ''}>
              <span></span>
            </label>
          </td>`;
        _rowData.forEach(_columnData => {
          if(typeof _columnData.order === 'number') {
            if(_columnData.value) {
                if (_columnData.value.length > 15) {
                    createDom += `<td data-key="${_columnData.key}" class="__hover-content" __hover-content>
                    <span class="__hover-base">${_columnData.value}</span>
                    <span class="__hover-target"><span>${_columnData.value}</span></span>
                    </td>`;
                } else {
                    createDom += `<td data-key="${_columnData.key}">${_columnData.value}</td>`;
                }
            } else{
              createDom += `<td data-key="${_columnData.key}" class="u-text-center"></td>`;
            }
          } else{
            createDom += `<td style="display: none;" data-key="${_columnData.key}">${_columnData.value}</td>`;
          }
        });
        createDom += '<td></td></tr>';
      });
      createDom += '</tbody>';
      createDom += '</table>';
      elmOutputTableWrapper.insertAdjacentHTML('beforeend', createDom);
    };

    resetTable();
    createTableDom();
  }

  /**
   * 検索結果モーダルの表の行クリックでラジオボタンを選択させる（ユーザビリティUPのため）
   * @param {Element} _elmTableRow クリックした行の<tr>要素
   */
  setCheckedRadio(_elmTableRow) {
    this.dataTable.rows().every(function() {
        var rowNode = this.node();
        $(rowNode).find('input[type="radio"]').prop('checked',false);
    });
    const elmInputRadio = _elmTableRow.querySelector('input[type="radio"]');
    elmInputRadio.checked = true;
  }

  /**
   * 「検索結果モーダルの表の選択している行情報」と「ローカルストレージの検索結果を表示させる要素のデータ」をもとに、値を任意の箇所に反映させる
   * （検索結果モーダル submit時に実行）
   * @param {Element} _elmTargetTableRow 選択している行の要素
   * @param {Object} _lsData ローカルストレージの検索結果を表示させる要素のデータ
   */
  setValueSearchResult(_elmTargetTableRow, _lsData) {
    if(!_elmTargetTableRow) return;
    _lsData.forEach(_data => {
      // セットする値を持つ要素<td>を取得
      const getSetValueHasElm = Array.prototype.slice.call(_elmTargetTableRow.querySelectorAll('td')).find(_elmTableColumn => {
        return _elmTableColumn.hasAttribute('data-key') &&
          _elmTableColumn.getAttribute('data-key') === _data.dataColumnName;
      });
      if(!getSetValueHasElm) return;

      // 値を反映
      const elmTarget = document.querySelector(_data.selector);
      if(elmTarget.tagName === 'INPUT') {
        if (getSetValueHasElm.hasAttribute('__hover-content')) {
            elmTarget.value = getSetValueHasElm.querySelector('.__hover-base').textContent;
          } else {
            elmTarget.value = getSetValueHasElm.textContent;
          }
      } else if(elmTarget.tagName === 'SELECT') {
        if (getSetValueHasElm.hasAttribute('__hover-content')) {
            elmTarget.value = getSetValueHasElm.querySelector('.__hover-base').textContent;
          } else {
            elmTarget.value = getSetValueHasElm.textContent;
          }
      } else {
        if (getSetValueHasElm.hasAttribute('__hover-content')) {
            elmTarget.textContent = getSetValueHasElm.querySelector('.__hover-base').textContent;
          } else {
            elmTarget.textContent = getSetValueHasElm.textContent;
          }
      }
      $(elmTarget).change();
    });
  }

  checkResultId(_elmTargetTableRow, _lsData){
      let isExist = false;
        _lsData.forEach(_data => {
        const getSetValueHasElm = Array.prototype.slice.call(_elmTargetTableRow.querySelectorAll('td')).find(_elmTableColumn => {
            return _elmTableColumn.hasAttribute('data-key') &&
               _elmTableColumn.getAttribute('data-key') === _data.dataColumnName;
        });

        if(!getSetValueHasElm) return;
        Array.prototype.slice.call(document.querySelectorAll(_data.selector)).forEach(_elmSelectorColumn => {
            if (getSetValueHasElm.textContent == _elmSelectorColumn.value){
                isExist = true;
            }
        });
      });
      return isExist;
  }

  /**
   * 検索モーダル起動ボタンにクリックイベントを付与
   * （起動ボタンは動的に追加される可能性があるので、イベント追加を this.addEvent() で直接定義せず、別メソッドとして定義）
   * @param {Element} _elm 検索モーダル起動ボタン
   */
  addEventToSearchModalOpenBtn(_elm) {
    _elm.addEventListener('click', (_ev) => {
      this.openSearchModal(_elm);
      this.setLsElmsSearchResult(_elm.getAttribute(this.attr.resultElmData));
    })
  }

  getCheckedData(_elmTargetTableRow, _lsData){
    let flag = _lsData.every(_data => {
        const item = _elmTargetTableRow.find(item => item.key === _data.dataColumnName);
        if (!item) return false;

        const itemValue = item.value;
        const elmTarget = document.querySelector(_data.selector);
        if (!elmTarget) return false;

        switch (elmTarget.tagName) {
            case 'INPUT':
            case 'SELECT':
                return elmTarget.value == itemValue;
            default:
                return elmTarget.textContent == itemValue;
        }
    });
    console.log(flag);
    return flag;
  }

  /**
   * 各要素にイベントを付与
   */
  addEvent() {

      const _this= this;

      /**
       * 検索モーダルを起動する
       * @param {Element} _elmSearchModalOpenBtn 検索モーダル起動ボタン
       */
      let openSearchModal = function (_elmSearchModalOpenBtn) {
          const elmSearchModal = document.querySelector(`${_elmSearchModalOpenBtn.getAttribute(_this.attr.searchModalOpenBtn)}`);
          if (!_elmSearchModalOpenBtn.hasAttribute(_this.attr.searchModalNotOpenFlag)) {
              elmSearchModal.setAttribute('data-active', 'true');

              let nodeDiv = document.createElement('div');
              nodeDiv.setAttribute('class', 'col-auto search');
              let nodeButton =  document.createElement('button');
              nodeButton.setAttribute('type', 'button');
              nodeButton.setAttribute('class', 'c-btn __big');
              nodeButton.setAttribute('data-js-modal-close-btn', 'full-modal-async-result');
              nodeButton.innerHTML = '検索';
              nodeButton.setAttribute(_this.attr.searchModalOpenBtn, _elmSearchModalOpenBtn.getAttribute(_this.attr.searchModalOpenBtn));
              nodeButton.setAttribute(_this.attr.resultElmData, _elmSearchModalOpenBtn.getAttribute(_this.attr.resultElmData));
              nodeDiv.appendChild(nodeButton);
              if (_this.elm.resultModal.wrapper.querySelector('.justify-content-center .search')) {
                _this.elm.resultModal.wrapper.querySelector('.justify-content-center .search').remove();
              }
              _this.elm.resultModal.wrapper.querySelector('.justify-content-center').appendChild(nodeDiv);

          } else {
              if (_this.elm.resultModal.wrapper.querySelector('.justify-content-center .search')) {
                _this.elm.resultModal.wrapper.querySelector('.justify-content-center .search').remove();
              }
              elmSearchModal.querySelector(`button[type='submit']`).click();
          }
      }

      /**
       * ローカルストレージに、検索結果を表示させる要素のデータをセットする
       * @param {String} _datas セットするデータ = `出力先要素セレクター:dataのカラム名`の形式 ※複数ならカンマ区切り
       */
      let setLsElmsSearchResult = function (_datas) {
          // Stringのデータを多次元連想配列に変換
          const arrayElmsSearchResult = _datas.split(',').map(_data => {
              let arraySplitData = _data.split(':');
              return {
                  selector: arraySplitData[0],
                  dataColumnName: arraySplitData[1]
              }
          });
          // ↑の多次元連想配列をjsonに変換してローカルストレージにセット
          const jsonStringify = JSON.stringify(arrayElmsSearchResult);
          localStorage.setItem(_this.localStorageElmsSearchResultName, jsonStringify);
      }

      let setLsElmsIdCheckSelector = function (_datas){
          const arrayElmsSearchResult = _datas.split(',').map(_data => {
              let arraySplitData = _data.split(':');
              return {
                  selector: arraySplitData[0],
                  dataColumnName: arraySplitData[1]
              }
          });
          // ↑の多次元連想配列をjsonに変換してローカルストレージにセット
          const jsonStringify = JSON.stringify(arrayElmsSearchResult);
          localStorage.setItem(_this.localStorageElmsIdCheckSelectorName, jsonStringify);
      }
      /**
     * 検索モーダル起動ボタン クリック
     */
    $(document).on('click', `[${this.attr.searchModalOpenBtn}]`,function () {
        let _elm = this;
        openSearchModal(_elm);
        setLsElmsSearchResult(_elm.getAttribute(_this.attr.resultElmData));
        if (null != _elm.getAttribute(_this.attr.idCheckTag) &&
            "" != _elm.getAttribute(_this.attr.idCheckTag)) {
            setLsElmsIdCheckSelector(_elm.getAttribute(_this.attr.idCheckTag));
        }
    });

    /**
     * 検索モーダルのフォーム submit
     * （複数ある場合が有り）
     */
    Array.prototype.slice.call(document.querySelectorAll(`[${this.attr.searchModalForm}]`)).forEach(_elmSearchModalForm => {
      _elmSearchModalForm.addEventListener('submit', (_ev) => {
        _ev.preventDefault();
        // ローディングを表示
        this.elm.nowLoading.setAttribute('data-active', 'true');

        // リクエスト用URL取得
        const getRequestUrl = (_elmForm) => {
          // フォームアクション取得
          const action = _elmForm.getAttribute('action');
          // 検索クエリ（URLパラメータ）取得
          const getParm = () => {
            return [...new FormData(_elmForm)].map(_formData => {
              return _formData.map(encodeURIComponent).join('=');
            }).join('&');
          }
          return `${action}?${getParm()}`;
        }

        // リクエスト実行
        this.getRequestData(getRequestUrl(_ev.target))
          // 成功時
          .then(_data => {
            // 1件もマッチしなかったら
            if(!_data){
              this.elm.nowLoading.setAttribute('data-active', 'false');
              alert('該当するデータがありませんでした。検索条件を変えて再度お試しください。')
              return;
            }

            // dataTableを削除
            if(this.dataTable) {
              this.dataTable.destroy();
            }

            // 検索結果モーダルを初期化（<table>生成して、各jsイベントを付与する）
            this.initResultModal(_data);

            // dataTableを実行
            $(`#${this.id.resultModal.outputTableWrapper} table thead th`).each(function (_index, _elm) {
              var childHtml = $(this).html()
              var addChildSpan = '<span>' + childHtml + '</span>'
              $(this).html(addChildSpan)
            });
            this.dataTable = $(`#${this.id.resultModal.outputTableWrapper} table`)
            .on( 'init.dt', function () {
                let th =  $(this).find('th:visible:eq(1)');
                if ($(this).find('th:visible').length > 3) {//行が三つ以上の場合は第二行目のサイズが小さくなります。
                    th.width('5%');
                }
            } ).DataTable({
              paging: true, //
              pageLength: 10, //
              lengthMenu: [10, 25, 50, 100], //
              dom: '<"c-js-datatable"<"c-js-datatable__table"t><"c-js-datatable__footer c-card__inner--col"<"c-js-datatable__pager"pi><"c-js-datatable__select-box"l>>>',
              order: [ [ 1, 'asc' ] ],
              language: {
                sProcessing: '<div><div></div><div></div><div></div><div></div></div>',
                sInfo: '_START_〜_END_件を表示 / _TOTAL_件',
                sInfoFiltered: 'が該当（_MAX_件中）',
                sInfoEmpty: '0件',
                sZeroRecords: '該当するデータはありません',
                sLengthMenu: '表示件数 _MENU_',
                oPaginate: {
                    sNext: '',
                    sPrevious: '',
                },
              },
            });

            // 検索結果モーダル起動
            this.elm.resultModal.wrapper.setAttribute('data-active', 'true');

            // 検索結果モーダル起動時に、参照元の検索モーダルのidをセットする
            this.elm.resultModal.wrapper.setAttribute(this.attr.srcSearchModal, _elmSearchModalForm.parentNode.id);

            // ローディング解除
            this.elm.nowLoading.setAttribute('data-active', 'false');

            // 「行クリックでラジオボタンを選択させる」イベントを付与
            let _this = this;
            this.dataTable.rows().every(function() {
                var rowNode = this.node();
                $(rowNode).on('click', function() {
                    _this.setCheckedRadio(rowNode)
                });
            });
          })
          // 失敗時
          .catch(_error => {
            // console.log(_error);
            alert('検索に失敗しました。お手数ですが再度お試しください。\n' + _error)
            this.elm.nowLoading.setAttribute('data-active', 'false');
          });
      })
    });

    /**
     * 検索結果モーダル submit
     * （1つのみ）
     */
    this.elm.resultModal.form.addEventListener('submit', (_ev) => {
      _ev.preventDefault();
      // 選択した値を任意の箇所にセットする
      const elmTargetTableRow = this.dataTable.rows().row(':has(input[type="radio"]:checked)').node();

      if (null != localStorage.getItem(this.localStorageElmsIdCheckSelectorName)){
          if (this.checkResultId(elmTargetTableRow,this.getLsElmsIdCheck())){
              window.alert("同じ項目がありますので、他の項目を選択お願いいたします。")
              return;
          }
      }

        // モーダルを閉じる（検索 and 検索結果）
      const elmResultModal = this.elm.resultModal.wrapper;
      const idSearchModal = elmResultModal.getAttribute(this.attr.srcSearchModal);
      // 検索結果モーダル
      elmResultModal.setAttribute('data-active', 'false');
      // 参照元の検索モーダル
      document.querySelector(`#${idSearchModal}`).setAttribute('data-active', 'false');

      this.setValueSearchResult(elmTargetTableRow, this.getLsElmsSearchResult());

      // 値をセットしたら、ローカルストレージのデータは削除する
      this.removeLsElmsSearchResult();
      if (this.elm.resultModal.outputTableWrapper.querySelector('table')) {
        this.elm.resultModal.outputTableWrapper.querySelector('table').remove();
      }

    })

  }

  init() {
    this.addEvent()
  }
}
