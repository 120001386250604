import TableAddRow from './TableAddRow';

/**
 * テーブル（表）に関する個別メソッドをまとめたクラス
 * 基本的には、カスタムdata属性をHTMLに与えて処理（クリックイベント）を実行させる
 */

export default class TableMethods {
  constructor(_parm) {
    // 行クリックで詳細ページへ遷移 の <tr>
    this.attrRowLink = 'data-js-table-row-link'
    this.elmsRowLink = Array.prototype.slice.call(document.querySelectorAll(`[${this.attrRowLink}]`))
    // 行を追加ボタンクリックで、指定のテーブルの行を追加 の 行を追加するボタン
    this.attrBtnAddRow = 'data-js-table-add-row'
    this.elmsBtnAddRow = Array.prototype.slice.call(document.querySelectorAll(`[${this.attrBtnAddRow}]`))
  }

  addEvent() {
    /**
     * 行クリックで詳細ページへ遷移
     */
    (() => {
      let attrRowLink = this.attrRowLink;
        $('table').on('click',`[${this.attrRowLink}]`,function (_elmRowLink){
            // <input>や三点メニューをクリックしても発火させない
            if (_elmRowLink.target.tagName === 'TD' || _elmRowLink.target.tagName === 'SPAN' || _elmRowLink.target.tagName === 'PRE') {
                const link = $(this).closest('TR').attr(attrRowLink);
                // .__no-blankを持っていたらタブで遷移
                if ($(this).closest('TR').hasClass('__no-blank')) {
                    window.location.href = link
                    return;
                } else{
                    // .__no-blankを持っていなかった、新規タブで遷移
                    window.open(link, link);
                }
            }
        });
    })();

    /**
     * 行を追加ボタンクリックで、指定のテーブルの行を追加
     */
    (() => {
      if (this.elmsBtnAddRow.length === 0) return;
      this.elmsBtnAddRow.forEach(_elmBtnAddRow => {
        _elmBtnAddRow.addEventListener('click', (_ev) => {
          const elmTargetTable = document.querySelector(`table${_elmBtnAddRow.getAttribute(this.attrBtnAddRow)}`)
          const elmTargetTableRow = elmTargetTable.querySelector('tbody > tr:first-of-type')
          const tableAddRow = new TableAddRow({
            isRowReset: true,
            elmTargetTableRow: elmTargetTableRow,
          });
          tableAddRow.addRow();
        })
      });
    })();
  }

  init() {
    this.addEvent()
  }
}
