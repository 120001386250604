export default class SelectCheck {

    constructor(_parm) {
        this.selectElem = _parm.selectElem;
    }

    addEvent() {
        let keys = new Array();
        let selectElem = this.selectElem;
        let checkSelected = function (_obj) {
            let selectedValues = new Array();
            $(_obj).each(function () {
                let selectValue = null;
                if (null == $(this).val()) {
                    selectValue = $(this).children('option:selected').val();
                } else {
                    selectValue = $(this).val();
                }
                if (selectedValues.indexOf(selectValue) < 0 && '' != selectValue) {
                    selectedValues.push(selectValue);
                }
            });
            $(_obj).each(function () {
                let selectedVal = $(this).val();
                $(this).children('option').each(function () {
                    if (selectedValues.indexOf(this.value) > -1 && this.value != selectedVal) {
                        this.disabled = true;
                    } else {
                        this.disabled = false;
                    }
                });
            });
        };

        $(`select[${selectElem}]`).each(function () {
            if (keys.indexOf($(this).attr(selectElem)) < 0) {
                keys.push($(this).attr(selectElem));
            }
        });
        keys.forEach(function (_key) {
            let _obj = `select[${selectElem}=${_key}]`;
            checkSelected(_obj);
            $(document).on('change', _obj, function () {
                checkSelected(_obj);
            });
            $(_obj).closest('div').on('DOMSubtreeModified', function () {
                checkSelected(_obj);
            });
        });
    };

    init() {
        this.addEvent();
    }
}
