/**
 * PDF印刷に関するクラス
 * マニュアル：
 */

export default class ReportPrintCustomer {
    /**
     * @Property {Element} this.elmLabelModal PDF印刷のモーダル要素
     * @Property {Array} this.objDataTable dataTableオブジェクト
     */
    constructor(_parm) {
        this.elmLabelModal = document.querySelector(_parm.labelModal) || false;
        this.elmInputCheckedRowIds = document.querySelector(_parm.inputCheckedRowIds);
        this.reportGroupId = _parm.reportGroupId;
        this.reportInfoUrl = _parm.reportInfoUrl;
        this.objDataTable = _parm.objDataTable;
        this.elemReportBtns = _parm.reportBtns;
        this.attr = {
            // 一覧の各行のidを持つinput（選択したラジオのvalueと、この属性値を紐付ける）
            hasRowIdInput_value: _parm.hasRowIdInput,
            hasChecked: _parm.hasChecked || false,
            isOnePage: _parm.isOnePage || false,
        };
        this.clickFunction = _parm.clickFunction || false;
    }

    guid() {
        return `pdf-customer-xxxxxxxx-xxxx-yxxx-xxxxxxxxxx`.replace(/[xy]/g, function (c) {
            let random = Math.random() * 16 | 0, res = c == 'x' ? random : (random & 0X3 | 0x8);
            return res.toString(16)
        });
    }

    /**
     * チェック済みの一覧の行を取得
     */
    getCheckedRows() {
        return Array.prototype.slice.call(this.objDataTable.rows().nodes()).filter(_elmRow => {
            if (this.attr.hasChecked){
                return _elmRow.querySelector('input[type="checkbox"]').checked;
            } else {
                return _elmRow.querySelector('input[type="checkbox"]');
            }
        });
    }

    /**
     * イベント付与
     */
    addEvent() {
        const elmLabelModal = this.elmLabelModal;
        const reportInfoUrl = this.reportInfoUrl;
        const reportGroupId = this.reportGroupId;
        const guid = this.guid();
        let ckickButton = null;
        const formatParams = function (data) {
            const arr = [];
            for (let name in data) {
                arr.push(encodeURIComponent(name) + "=" + encodeURIComponent(data[name]));
            }
            arr.push(("v=" + Math.random()).replace(".", ""));
            return arr.join("&");
        };
        $(document).on('click', `${this.elemReportBtns}`, function () {
            elmLabelModal.querySelector(`[report-customer-info]`).innerHTML = '';
            const report_ajax = new XMLHttpRequest();
            const report_ajax_params = formatParams({
                report_group_id: reportGroupId
            });
            ckickButton = this;
            report_ajax.open('get',  reportInfoUrl + '?' + report_ajax_params);
            report_ajax.send();
            report_ajax.onreadystatechange = function () {
                if (report_ajax.readyState == 4 && report_ajax.status == 200) {
                    let data = JSON.parse(report_ajax.responseText);
                    let outHtml = '<div>';
                    for (let i = 0; i < data.length; i++) {
                        let uid = guid;
                        outHtml += '<input type="radio"  name="use_report_id" id="' + uid + i + '" value="' + data[i].int_use_report_id + '">'
                            + '<label for="' + uid + i + '">' + data[i].str_report_name + '</label></div>';
                    }
                    elmLabelModal.querySelector(`[report-customer-info]`).innerHTML = outHtml;
                }
            }
        });
        /**
         * フォーム送信時
         */
        this.elmLabelModal.querySelector('[pdf-customer-select-button]').addEventListener('click', (_ev) => {
            _ev.preventDefault();
            if (this.elmLabelModal.querySelector(`input[type=radio]:checked`) == null){
                return;
            }
            if (this.attr.isOnePage){
                this.clickFunction(this.elmLabelModal, ckickButton);
            }else {
                this.clickFunction(this.elmLabelModal);
            }
        });
    }

    init() {
        if(!this.elmLabelModal) return;
        this.addEvent();
    }

}
