import TableAddRow from '../../TableAddRow';
import { setDisabledSelectedOption } from './setDisabledSelectedOption';
// window.cloneTableRow = cloneTableRow; // function cloneTableRow() {} で関数作成したのと同じ意味

/**
 * 契約詳細の「通常テーブル」に紐づく「帳票印字テーブル」に関する処理
 */

export default class contractDetail {
  constructor(_parm) {
    this.elm = {
      baseTable: document.querySelector(_parm.baseTable), // 上のテーブル
      baseAccountTable: document.querySelector(_parm.baseTable+'-account') || false, // 上のテーブル
      addRowBtn: document.querySelector(_parm.addRowBtn), // 上のテーブル
      printTable: document.querySelector(_parm.printTable), // 下のテーブル（帳票印字一覧）
      copyBtn: document.querySelector(_parm.copyBtn), // 全行を帳票印字一覧にコピーボタン
      inputTotal: document.querySelector(_parm.inputTotal) || false, // 合計金額のinput
      btnTotalCalc: document.querySelector(_parm.btnTotalCalc) || false, // 合計金額の計算ボタン
      updateTable: document.querySelector(_parm.updateTable) || false, // 更新時のテーブル
    };
    // 月額タブならtrue（月額タブ専用の処理をするために定義している）
    this.isMonthTabTable = _parm.isMonthTabTable || false;
  }

  /**
   * 上のテーブルの税込金額の合計を計算する
   */
  calcTotalPrice() {
    // 合計計算はない場合もある
    if(!this.elm.inputTotal) return;
    // 処理
    const arrayElmsTotalInput = Array.prototype.slice.call(this.elm.baseTable.querySelectorAll('[data-calcTableRowTaxPrice="result-total"]'));
    const totalPrice = arrayElmsTotalInput.reduce((_accumulator, _elmsTotalInput) => {
      return _accumulator + parseInt(_elmsTotalInput.value.replace(/,/g, ''), 10);
    }, 0);
    this.elm.inputTotal.value = totalPrice.toLocaleString();
  }

  /**
   * 行削除
   * @param {Element} _parm.clickElm クリックした要素
   * @param {Boolean} _parm.isNothingRemove trueなら削除後に行が0個になる場合でも削除する
   */
  rowRemove(_parm) {
    const elmTable = _parm.clickElm.closest('table');
    const elmTargetRow = _parm.clickElm.closest('tr');
    const elmsRow = Array.prototype.slice.call(elmTable.querySelectorAll('tbody tr'));
    // 削除後に行が0個になる場合でも削除する
    if(_parm.isNothingRemove) {
      elmTargetRow.remove();
    } else{
      if(elmsRow.length > 1){
        elmTargetRow.remove();
      }
    }
  }

  /**
   * 帳票印字テーブルに行を追加
   * @param {Element} _elmRow 追加する行<tr>要素
   */
  setRowPrintTable(_elmRow) {
    // 帳票印字テーブルに追加済みのものは、新たに追加しない
    let selectboxValues = new Array;
    this.elm.printTable.querySelectorAll('tbody tr .data-js-detail-id').forEach((el) => {
      selectboxValues.push(el.value);
    });
      const elmPrintTableTbodyName = this.elm.printTable.dataset.name;
      const id = _elmRow.querySelector('.detail_item_'+elmPrintTableTbodyName).value;
      const index = _elmRow.querySelector('.detail_item_'+elmPrintTableTbodyName).selectedIndex;
      const totalPrice = _elmRow.querySelector('.detail_total_'+elmPrintTableTbodyName).value;
      const data = {
          id: id,
          name: _elmRow.querySelector('.detail_item_'+elmPrintTableTbodyName).options[index].text,
          totalPrice: totalPrice
      };
      const elmPrintTableTbody = this.elm.printTable.querySelector('tbody');

    // 帳票印字テーブルに追加済みか否か
    if (!selectboxValues.includes(id)) {
      let elmPrintTableTbodyCount = this.elm.printTable.dataset.count;
      elmPrintTableTbodyCount++;
      this.elm.printTable.dataset.count = elmPrintTableTbodyCount;

      let elmCloneRow = elmPrintTableTbody.firstElementChild.cloneNode(true);
      elmCloneRow.querySelector('.data-js-detail-id').value = data.id;
      elmCloneRow.querySelector('.data-js-detail-id').setAttribute('name', elmPrintTableTbodyName + '[' +elmPrintTableTbodyCount + '][int_detail_item_id]');
      elmCloneRow.querySelector('.data-js-detail-name').value = data.name;
      elmCloneRow.querySelector('.data-js-detail-name').setAttribute('name', elmPrintTableTbodyName + '[' +elmPrintTableTbodyCount + '][str_detail]');
      elmCloneRow.querySelector('.data-js-total-price').value = data.totalPrice + "円";
      elmCloneRow.querySelector('.data-js-total-price').setAttribute('name', elmPrintTableTbodyName + '[' +elmPrintTableTbodyCount + '][str_amount_with_tax]');
      elmCloneRow.style.display = 'table-row';
      elmPrintTableTbody.appendChild(elmCloneRow);
    // 追加済みは値を更新
    }else{
      const isLargeNumber = (element) => element == id;
      let wkIdx = selectboxValues.findIndex(isLargeNumber)
      elmPrintTableTbody.querySelector('[name="'+elmPrintTableTbodyName+ '[' +wkIdx + '][str_amount_with_tax]"]').value = data.totalPrice + "円"
    }
  }

  /**
   * 更新時帳票印字テーブルに行を追加
   * @param {Element} _elmRow 追加する行<tr>要素
   */
  setRowPrintTableForUpdate(_elmRow) {
    // 帳票印字テーブルに追加済みのものは、新たに追加しない
    let selectboxValues = new Array;
    this.elm.printTable.querySelectorAll('tbody tr .data-js-detail-id').forEach((el) => {
      selectboxValues.push(el.value);
    });
      const elmPrintTableTbodyName = this.elm.printTable.dataset.name;
      const id = _elmRow.querySelector('[name$="[int_detail_item_id]"]').value;
      const month = _elmRow.querySelector('[name$="[flt_month_minute]"]').value;
      const name = _elmRow.querySelector('td').textContent;
      const totalPrice =  month == "0" ? _elmRow.querySelector('[name$="[int_amount_with_tax]"]').value : month ;
      const data = {
          id: id,
          name: name,
          totalPrice: totalPrice,
          month: month
      };
      const elmPrintTableTbody = this.elm.printTable.querySelector('tbody');

    // 帳票印字テーブルに追加済みか否か
    if (!selectboxValues.includes(id)) {
      let elmPrintTableTbodyCount = this.elm.printTable.dataset.count;
      elmPrintTableTbodyCount++;
      this.elm.printTable.dataset.count = elmPrintTableTbodyCount;

      let elmCloneRow = elmPrintTableTbody.firstElementChild.cloneNode(true);
      elmCloneRow.querySelector('.data-js-detail-id').value = data.id;
      elmCloneRow.querySelector('.data-js-detail-id').setAttribute('name', elmPrintTableTbodyName + '[' +elmPrintTableTbodyCount + '][int_detail_item_id]');
      elmCloneRow.querySelector('.data-js-detail-name').value = data.name;
      elmCloneRow.querySelector('.data-js-detail-name').setAttribute('name', elmPrintTableTbodyName + '[' +elmPrintTableTbodyCount + '][str_detail]');
      elmCloneRow.querySelector('.data-js-total-price').value = data.month == "0" ? data.totalPrice + "円" : "新賃料の" + data.totalPrice +"ヶ月分";
      elmCloneRow.querySelector('.data-js-total-price').setAttribute('name', elmPrintTableTbodyName + '[' +elmPrintTableTbodyCount + '][str_amount_with_tax]');
      elmCloneRow.style.display = 'table-row';
      elmPrintTableTbody.appendChild(elmCloneRow);
    // 追加済みは値を更新
    }else{
      const isLargeNumber = (element) => element == id;
      let wkIdx = selectboxValues.findIndex(isLargeNumber)
      elmPrintTableTbody.querySelector('[name="'+elmPrintTableTbodyName+ '[' +wkIdx + '][str_amount_with_tax]"]').value = data.totalPrice + "円"
    }
  }

  /**
   * 「月額タブのthis.elm.baseTable」の項目を編集したら、「業者間取引タブのtable」の項目も同じ値にする
   * @param {Element} _elmInput 編集した this.elm.baseTable のinput, select要素
   */
  changeFeeTableValue(_elmInput) {
    // 編集したinputの親の<tr>
    const elmRow = _elmInput.closest('tr');
    // elmRowが<tbody>の何番目の<tr>かを取得
    const rowIndex = Array.prototype.slice.call(this.elm.baseTable.querySelectorAll('tbody > tr')).findIndex(_elmTr => _elmTr === elmRow) + 1; // nth-childの最初はカウントは1始まりだから+1してる
    // 値を取得
    const value = {
      detailName: elmRow.querySelector('[data-js-detail-name]').value,
      price: elmRow.querySelector('[data-calcTableRowTaxPrice="price"]').value,
    };
    // 「業者間取引タブのtable」の項目に値を反映
    const feeTableRow = document.querySelector(`#inline-js-base-table tbody tr:nth-child(${rowIndex})`);
    feeTableRow.querySelector('[data-js-detail-name]').value = value.detailName;
    feeTableRow.querySelector('[data-js-price]').value = value.price;
    feeTableRow.querySelector('[data-price]').value = value.price.replace(/,/g,'');
    // selectの重複選択をしないようにする
    const feeTableSelectElms = document.querySelectorAll('#inline-js-base-table tbody tr select[data-js-detail-name]');
    setDisabledSelectedOption(feeTableSelectElms);
  }

  /**
   * 「業者間取引タブのtable」の項目を編集したら、「月額タブのthis.elm.baseTable」の項目も同じ値にする
   * @param {Element} _elmInput 編集した 業者間タブのtalbe のselect要素
   */
  changeMonthTableValue(_elmInput) {
    // 編集したinputの親の<tr>
    const elmRow = _elmInput.closest('tr');
    const elmTable = _elmInput.closest('table');
    // elmRowが<tbody>の何番目の<tr>かを取得
    const rowIndex = Array.prototype.slice.call(elmTable.querySelectorAll('tbody > tr')).findIndex(_elmTr => _elmTr === elmRow) + 1; // nth-childの最初はカウントは1始まりだから+1してる
    // 値を取得
    const valueDetailName = elmRow.querySelector('[data-js-detail-name]').value;
    // 「賃料＞月額タブのtable」の項目に値を反映
    const monthTableRow = this.elm.baseTable.querySelector(`tbody > tr:nth-child(${rowIndex})`);
    monthTableRow.querySelector('[data-js-detail-name]').value = valueDetailName;
    // selectの重複選択をしないようにする
    if(this.isMonthTabTable) {
      const monthTableSelectElms = this.elm.baseTable.querySelectorAll('.detail_item_monthly');
      setDisabledSelectedOption(monthTableSelectElms);
    }
  }

  /**
   * 「月額タブのthis.elm.baseTable」の行追加ボタンクリックで、「業者間取引タブのtable」の行も追加する
   */
  addRowFeeTable() {
    const cloneFeeTableRow = document.querySelector('#inline-js-base-table tbody tr:first-child').cloneNode(true);
    document.querySelector('#inline-js-base-table tbody').appendChild(cloneFeeTableRow);
    // 項目のセレクトボックスにイベント付与
    // 「月額タブのthis.elm.baseTable」の項目とリンクさせる
    cloneFeeTableRow.querySelector('[data-js-detail-name]').addEventListener('blur', (_ev) => {
      this.changeMonthTableValue(_ev.currentTarget);
    });
  }

  /**
   * 「月額タブのthis.elm.baseTable」の行の三点メニューの削除クリックで、「業者間取引タブのtable」の行も削除する
   * @param {Number} _rowIndex クリックした三点メニューの親のtrが何個目か
   */
  removeRowFeeTable(_rowIndex) {
    const feeTableRow = document.querySelector(`#inline-js-base-table tbody tr:nth-child(${_rowIndex})`);
    feeTableRow.remove();
  }

  /**
   * イベントを付与
   */
  addEvent() {
    // 合計金額の計算ボタン
    if(this.elm.btnTotalCalc) {
      this.elm.btnTotalCalc.addEventListener('click', (_ev) => {
        this.calcTotalPrice();
      });
    }

    // 全行を帳票印字一覧にコピーボタン
    this.elm.copyBtn.addEventListener('click', (_ev) => {
      let arrayElmsAllRow = Array.prototype.slice.call(this.elm.printTable.querySelector('tbody').querySelectorAll('tr'));
        // ベース行を残して残りは削除
        arrayElmsAllRow.forEach(function (tr, index) {
            if (index > 0){
              tr.parentNode.removeChild(tr)
            }
        })
        this.elm.printTable.dataset.count=0
      arrayElmsAllRow = Array.prototype.slice.call(this.elm.baseTable.querySelector('tbody').querySelectorAll('tr'));
      arrayElmsAllRow.forEach((_elmRow, _index) => {
        // 一番最初の行は複製用のtrだからコピーしない
        if(_index === 0) return;
        this.setRowPrintTable(_elmRow);
      });
      if (this.elm.updateTable) {
        arrayElmsAllRow = Array.prototype.slice.call(this.elm.updateTable.querySelector('tbody').querySelectorAll('tr'));
        arrayElmsAllRow.forEach((_elmRow, _index) => {
            this.setRowPrintTableForUpdate(_elmRow);
        });
      }
    });

    // 各行の帳票印字一覧にコピーボタン
    Array.prototype.slice.call(this.elm.baseTable.querySelectorAll('.c-hide-menu [data-js-copy-row-print-table]')).forEach(_elmCooyRowPrintBtn => {
      _elmCooyRowPrintBtn.addEventListener('click', (_ev) => {
        this.setRowPrintTable(_elmCooyRowPrintBtn.closest('tr'));
      });
    });

    // 各行の削除ボタン
    Array.prototype.slice.call(this.elm.baseTable.querySelectorAll('.c-hide-menu [data-js-row-remove]')).forEach(_elmRowRemoveBtn => {
      _elmRowRemoveBtn.addEventListener('click', (_ev) => {
        // 月額タブ専用。「業者間取引タブのtable」の行も削除する
        if(this.isMonthTabTable) {
          const elmRow = _elmRowRemoveBtn.closest('tr');
          // elmRowが<tbody>の何番目の<tr>かを取得
          const rowIndex = Array.prototype.slice.call(this.elm.baseTable.querySelectorAll('tbody > tr')).findIndex(_elmTr => _elmTr === elmRow) + 1;
          this.removeRowFeeTable(rowIndex);
        }
        // 行削除
        this.rowRemove({
          clickElm: _ev.target,
          isNothingRemove: true,
        });
      });
    });

    // 行追加ボタン
    // 別ファイルのTableAddRowの処理を実行後、三点メニュー内のボタンにもイベントを付与している
    this.elm.addRowBtn.addEventListener('click', (_ev) => {
      const elmTargetTableRow = this.elm.baseTable.querySelector('tbody > tr:first-of-type')
      const tableAddRow = new TableAddRow({
        isRowReset: false,
        elmTargetTableRow: elmTargetTableRow,
      });
      tableAddRow.addRow();
      if (this.elm.baseAccountTable) {
        const elmTargetAccountTableRow = this.elm.baseAccountTable.querySelector('tbody > tr:first-of-type')
        const tableAccountAddRow = new TableAddRow({
          isRowReset: false,
          elmTargetTableRow: elmTargetAccountTableRow,
        });
        tableAccountAddRow.addRow();
      }
      // 追加した行要素
      const elmAddRow = tableAddRow.elmTargetCloneTableRow;
      // 三点メニューの「帳票印字一覧にコピー」と「削除」のイベントを付与
      elmAddRow.querySelector('.c-hide-menu [data-js-copy-row-print-table]').addEventListener('click', (_ev) => {
        this.setRowPrintTable(elmAddRow);
      });
      elmAddRow.querySelector('.c-hide-menu [data-js-row-remove]').addEventListener('click', (_ev) => {
        // 月額タブ専用。「業者間取引タブのtable」の行も削除する
        if(this.isMonthTabTable) {
          const elmRow = _ev.currentTarget.closest('tr');
          // elmRowが<tbody>の何番目の<tr>かを取得
          const rowIndex = Array.prototype.slice.call(this.elm.baseTable.querySelectorAll('tbody > tr')).findIndex(_elmTr => _elmTr === elmRow) + 1;
          this.removeRowFeeTable(rowIndex);
        }
        // 行削除
        this.rowRemove({
          clickElm: _ev.target,
          isNothingRemove: true,
        });
      });

      // 月額タブ専用
      if(this.isMonthTabTable) {
        // 「月額タブのthis.elm.baseTable」の項目を編集したら、「業者間取引タブのtable」の項目も同じ値にするのinputのイベントを付与
        Array.prototype.slice.call(elmAddRow.querySelectorAll('input, select')).forEach(_elmInput => {
          _elmInput.addEventListener('blur', (_ev) => {
            this.changeFeeTableValue(_elmInput);
          });
        });
        // 「業者間取引タブのtable」の行を追加
        this.addRowFeeTable();
      }
    });

    // 月額タブ専用
    if(this.isMonthTabTable) {
      // 「月額タブのthis.elm.baseTable」の項目を編集したら、「業者間取引タブのtable」の項目も同じ値にする
      Array.prototype.slice.call(this.elm.baseTable.querySelectorAll('input, select')).forEach(_elmInput => {
        _elmInput.addEventListener('blur', (_ev) => {
          this.changeFeeTableValue(_elmInput);
        });
      });
      // 「業者間取引タブのtable」の項目を編集したら、「月額タブのthis.elm.baseTable」の項目も同じ値にする
      Array.prototype.slice.call(document.querySelectorAll('#inline-js-base-table select[data-js-detail-name]')).forEach(_elmInput => {
        _elmInput.addEventListener('blur', (_ev) => {
          this.changeMonthTableValue(_elmInput);
        });
      });
    }
  }

  init() {
    // イベントセット
    this.addEvent();

    // ページ読み込み時にも実行する処理
    this.calcTotalPrice();

    if(this.isMonthTabTable) {
      // ページ読み込み時に業者間取引タブのテーブルのselectの重複選択をしないようにする
      const feeTableSelectElms = document.querySelectorAll('#inline-js-base-table tbody tr select[data-js-detail-name]');
      setDisabledSelectedOption(feeTableSelectElms);
      // インラインjs用（selectタグのoninputイベントで実行）
      window.feeTableSetDisabledSelectedOption = function(_this) {
        const parentTable = _this.closest('table');
        const feeTableSelectElms = parentTable.querySelectorAll('tbody tr select[data-js-detail-name]');
        setDisabledSelectedOption(feeTableSelectElms);
      };
    }
  }
}
