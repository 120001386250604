export default class TagSelect {
  constructor(_parm) {
    this.elmParents = Array.prototype.slice.call(document.querySelectorAll('.c-tag-select'))
    this.attrActive = 'data-active'
    this.attrAddSelectSearchBox = 'data-js-add-select-search-box'
    this.attrTagSelectBtn = 'data-js-tag-select-btns'
  }

  /**
   * セレクトボックスの表示非表示切り替え
   */
  showSelect(_elmSelect) {
    _elmSelect.setAttribute(this.attrActive, 'true');

    // クリックした時に、セレクトボックスの位置によっては画面外に見切れることがあるから、
    // 座標を取得して位置を調整する
    _elmSelect.style.marginLeft = 'auto';
    const offsetX = _elmSelect.getBoundingClientRect().left;
    const selectWidth = _elmSelect.getBoundingClientRect().width;
    const windowWidth = window.innerWidth;
    if(offsetX + selectWidth > windowWidth) {
      _elmSelect.style.marginLeft = `${windowWidth - (offsetX + selectWidth) - 50}px`;
    } else{
      _elmSelect.style.marginLeft = 'auto';
    }
  }
  hideSelect(_elmSelect) {
    _elmSelect.setAttribute(this.attrActive, 'false');
  }

  /**
   * タグを追加する
   */
  addTag(_tagsParent, _value, _id, _selectBtns) {
    // 同じタグを追加しようとしたら処理中断（重複選択はNG）
    const isDuplicate = Array.prototype.slice.call(_tagsParent.querySelectorAll('[data-text]')).some(_elmTag => {
      return _value === _elmTag.textContent;
    });
    if(isDuplicate) return;

    // 親要素の<li>
    let createTagItem = document.createElement('li');
    createTagItem.classList.add('c-tag-select__tags-item');

    // 表示させるテキスト要素
    let createTagText = document.createElement('div');
    createTagText.textContent = _value;
    createTagText.setAttribute('data-text', '');

    // DB保存用の<input type="hidden">
    let createTagInput = document.createElement('input');
    createTagInput.value = _id ?? _value;
    createTagInput.setAttribute('type', 'hidden');
    createTagInput.setAttribute('name', _tagsParent.parentNode.getAttribute('data-name'));

    // 削除ボタン
    let createTagButton = document.createElement('button');
    createTagButton.setAttribute('type', 'button');
    createTagButton.setAttribute('title', '削除');
    createTagButton.addEventListener('click', (_ev) => {
      this.removeTag(_ev, _id ?? _value, _selectBtns)
    })

    createTagItem.appendChild(createTagText)
    createTagItem.appendChild(createTagInput)
    createTagItem.appendChild(createTagButton)

    _tagsParent.appendChild(createTagItem);
  }

  /**
   * タグを削除する（自身を削除）
   */
  removeTag(_ev, _value, _selectBtns) {
    const elmTag = _ev.target.parentNode
    elmTag.parentNode.removeChild(elmTag);
    _selectBtns.forEach(_elmSelectBtn => {
      if (_elmSelectBtn.value == _value) {
        _elmSelectBtn.parentNode.style.display = 'block';
      }
    });
  }

  /**
   * 検索ボックス要素を生成
   */
  createElmSearch(_elmSelect) {
    const createTag = `
      <li class="c-dropdown-menu__item __search">
        <input type="text" ${this.attrAddSelectSearchBox} class="__search" placeholder="絞り込む">
      </li>
    `;
    _elmSelect.querySelector('.c-dropdown-menu__content').insertAdjacentHTML('afterbegin', createTag);
  }

  /**
   * セレクトボックスを検索
   */
  searchSelect(_ev, _selectBtns) {
    const inputValue = _ev.target.value;
    const resetSearch = () => {
      _selectBtns.forEach(_elmSelectBtn => {
        _elmSelectBtn.parentNode.style.display = 'block';
      });
    }
    // 最初にリセット
    resetSearch();
    // 入力値にマッチしなかったら選択肢非表示
    _selectBtns.forEach(_elmSelectBtn => {
      if(!_elmSelectBtn.textContent.match(inputValue)){
        _elmSelectBtn.parentNode.style.display = 'none';
      }
      // 入力値空になったらリセット
      if(!inputValue) resetSearch();
    });
  }

  addEvent() {
    this.elmParents.forEach(_elmParent => {
      const elm = {
        tagsParent: _elmParent.querySelector('.c-tag-select__tags'),
        addBtn: _elmParent.querySelector('.c-tag-select__add-btn > button'),
        select: _elmParent.querySelector('.c-tag-select__select'),
        selectBtns: Array.prototype.slice.call(_elmParent.querySelectorAll(`.c-tag-select__select [${this.attrTagSelectBtn}]`))
      }
      let isSelectShow = false
      elm.tagsParent.querySelectorAll(`.c-tag-select__tags-item`).forEach(_elmTag => {
        let _value = _elmTag.querySelector('[data-text]').value;
        let _id = _elmTag.querySelector('input').value;
        let _selectBtns = elm.selectBtns;
        if (_elmTag.querySelector('button')) {
          _elmTag.querySelector('button').addEventListener('click', (_ev) => {
            this.removeTag(_ev, _id ?? _value, _selectBtns);
          });
        }
        elm.selectBtns.forEach(_elmSelectBtn => {
          if (_elmSelectBtn.value == _id ?? _value) {
            _elmSelectBtn.parentNode.style.display = 'none';
          }
        });
      });
      
      // 親要素に、inputのname属性をセットして、選択肢の空inputは削除する（サーバーサイドへ空valueが送信されるのを防ぐ）
      const elmTagBaseInput = elm.tagsParent.children[0].querySelector('input[type="hidden"]');
      _elmParent.setAttribute('data-name', elmTagBaseInput.getAttribute('name'));
      elmTagBaseInput.parentNode.remove();

      // 検索ボックスを生成
      this.createElmSearch(elm.select);
      // 検索ボックスにイベント設定
      const elmSelectSearchBox = elm.select.querySelector(`[${this.attrAddSelectSearchBox}]`);
      elmSelectSearchBox.addEventListener('input', (_ev) => {
        this.searchSelect(_ev, elm.selectBtns);
      })

      // セレクトボックスのボタンクリックでタグを追加
      elm.selectBtns.forEach(_elmSelectBtn => {
        _elmSelectBtn.addEventListener('click', (_ev) => {
          if (!_elmSelectBtn.value) {
            this.addTag(elm.tagsParent, _elmSelectBtn.textContent, null, elm.selectBtns);
          } else {
            this.addTag(elm.tagsParent, _elmSelectBtn.textContent, _elmSelectBtn.value, elm.selectBtns);
            _elmSelectBtn.parentNode.style.display = 'none';
          }
        })
      });

      // 追加ボタンクリックでセレクトボックス表示非表示を切り替え
      elm.addBtn.addEventListener('click', (_ev) => {
        isSelectShow ? this.hideSelect(elm.select) : this.showSelect(elm.select);
        isSelectShow = !isSelectShow;
      })

      // セレクトボックス以外クリックで自身を非表示にする
      document.addEventListener('click', (_ev) => {
        if(_ev.target === elm.addBtn) return;
        if(_ev.target.hasAttribute(this.attrAddSelectSearchBox)) return;
        if (!_ev.target.hasAttribute(this.attrTagSelectBtn) && isSelectShow) {
          this.hideSelect(elm.select);
          isSelectShow = !isSelectShow;
          // 検索ボックス絞り込み全解除
          elm.selectBtns.forEach(_elmSelectBtn => {
            const isDuplicate = Array.prototype.slice.call(elm.tagsParent.querySelectorAll('[data-text]')).some(_elmTag => {
              return _elmSelectBtn.textContent === _elmTag.textContent;
            });
            if(!isDuplicate) {
              _elmSelectBtn.parentNode.style.display = 'block';
            };
          });
        }
      })
    });
  }

  init() {
    this.addEvent()
  }
}
