export default class HideMenu {
  constructor(_parm) {
    this.tableHideMenuSelector = {
      wrapper: _parm[0]._target,
      toggleBtn: _parm[0].toggleBtn,
      menu: _parm[0].menu,
    }
    this.dropdownMenuSelector = {
      wrapper: _parm[1]._target,
      toggleBtn: _parm[1].toggleBtn,
      menu: _parm[1].menu,
    }
  }

  /**
   * 全メニューを非表示にする
   */
  hideAll() {
      $(`${this.tableHideMenuSelector.wrapper}`).each(function (){
          $(this).attr('data-active', 'false');
      });
      $(`${this.dropdownMenuSelector.wrapper}`).each(function (){
          $(this).attr('data-active', 'false');
      });
  }

  /**
   * ボタンにクリックイベントを設定
   * （三点メニューなどの要素を含む行が複製された時に、再度イベント設定できるように、ここでは一括でイベント設定はしない）
   */
  addEventToToggleBtn() {
      let tableHideMenuSelector = this.tableHideMenuSelector;
      let dropdownMenuSelector = this.dropdownMenuSelector;
      let toggleDisplay = function (_elmWrapper,_menu) {
          const elmToggleMenu = _elmWrapper.querySelector(_menu);
          const getIsShow = () => {
              if(!elmToggleMenu.hasAttribute('data-active')) return false;
              if(elmToggleMenu.getAttribute('data-active') === 'true') return true;
              return false;
          }
          if (getIsShow()) {
              $(elmToggleMenu).attr('data-active', 'false');
          } else {
              $(elmToggleMenu).attr('data-active', 'true');
          }
      }
      // ToggleBtnクリックで表示非表示切り替え
      $(document).on('click', `${this.tableHideMenuSelector.wrapper}`, function () {
          let _elmWrapper = this;
          // 既に開いているのを非表示にする
          let elmsOpenHideMenu = Array.prototype.slice.call(document.querySelectorAll('.c-hide-menu__content[data-active="true"], .c-dropdown-menu__content[data-active="true"]'));
          elmsOpenHideMenu.forEach(_elmOpenHideMenu => {
              if (!_elmWrapper.contains(_elmOpenHideMenu)) {
                  _elmOpenHideMenu.setAttribute('data-active', 'false');
              }
          });
          if (_elmWrapper.closest('.dataTables_scrollBody')) {
            let wrapperScrollTop = _elmWrapper.closest('.dataTables_scrollBody').scrollTop;
            let scrollBodyOffsetHeight = _elmWrapper.closest('.dataTables_scrollBody').offsetHeight;
            let wrapperOffsetTop = _elmWrapper.offsetTop;
            let wrapperOffsetHeight = _elmWrapper.offsetHeight - 8;
            let menuOuterHeight = $(_elmWrapper).find(tableHideMenuSelector.menu).outerHeight();
            if (wrapperOffsetTop + wrapperOffsetHeight + menuOuterHeight - wrapperScrollTop > scrollBodyOffsetHeight) {
                $(_elmWrapper).find(tableHideMenuSelector.menu).css({"top":'auto',"bottom":"30px"});
            } else {
                $(_elmWrapper).find(tableHideMenuSelector.menu).css({"top":'30px',"bottom":"auto"});
            }
          }
          // 表示非表示切り替え
          toggleDisplay(_elmWrapper,tableHideMenuSelector.menu)
      })
      $(document).on('click', `${this.dropdownMenuSelector.wrapper}`, function () {
          let _elmWrapper = this;
          // 既に開いているのを非表示にする
          let elmsOpenHideMenu = Array.prototype.slice.call(document.querySelectorAll('.c-hide-menu__content[data-active="true"], .c-dropdown-menu__content[data-active="true"]'));
          elmsOpenHideMenu.forEach(_elmOpenHideMenu => {
              if (!_elmWrapper.contains(_elmOpenHideMenu)) {
                  _elmOpenHideMenu.setAttribute('data-active', 'false');
              }
          });
          // 表示非表示切り替え
          toggleDisplay(_elmWrapper,dropdownMenuSelector.menu)
      })
  }

  init() {
      this.addEventToToggleBtn();
  }
}
