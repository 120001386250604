import Modal from './Modal';
import AsyncGetSearchResult from "./AsyncGetSearchResult";

/**
 * テーブルの行を追加するクラス
 * constructorには、追加する行の情報を与えること
 * こうすることで、行を追加ボタン、行を複製ボタンどちらにも対応できる
 *
 * ここでは処理のみを記述しており、実際に実行する処理を記述するのは別ファイルにすること
 * （動的に追加される「複製ボタン」など様々な箇所で使用されるから）
 */

export default class TableAddRow {
  /**
   * @param {Boolean} _parm.isResetRow 行追加時に、行の中の<input>などの値をリセットするかどうか trueで実行
   * @param {Array} _parm.isValueReset 行追加時に、行の中の<input>などの値の初期値の設定
   * @param {Element} _parm.elmTargetTableRow 追加する行<tr>のElementオブジェクト
   */
  constructor(_parm) {
    this.isRowReset = _parm.isRowReset
    // this.setInitValue = _parm.setInitValue || false
    this.elmTargetTable = _parm.elmTargetTableRow.closest('table') || false
    this.elmTargetTableRow = _parm.elmTargetTableRow || false
    this.elmTargetCloneTableRow = _parm.elmTargetTableRow.cloneNode(true) || false
  }

  resetRow() {
    /**
     * input, select系をリセット
     */
    const elmsInput = Array.prototype.slice.call(this.elmTargetCloneTableRow.querySelectorAll('input, select'))
    elmsInput.forEach(_elmInput => {
      // <select>タグなら
      if (_elmInput.tagName === 'SELECT') {
        Array.prototype.slice.call(_elmInput.children).forEach((_option, _index) => {
          if (_option.hasAttribute('selected')) _option.removeAttribute('selected');
          if (_index === 0) _option.setAttribute('selected', '');
        });
        _elmInput.options.selectedIndex = 0;
        return;
      }
      // <input type="radio|checkbox">タグなら
      if(_elmInput.getAttribute('type').match(/radio|checkbox/)) {
        _elmInput.checked = false;
        return;
      }
      // 上記以外の<input>タグなら & u-text-rightクラスが付いてたら数値なので初期値を0にする
      if(_elmInput.classList.contains('u-text-right')) {
        _elmInput.value = '0';
      } else{
        _elmInput.value = '';
      }
    });
    /**
     * テキスト系
     */
    const elmsTd = Array.prototype.slice.call(this.elmTargetCloneTableRow.querySelectorAll('td'))
    elmsTd.forEach(_elmTd => {
      // 子要素があるなら何もしない
      if(_elmTd.childElementCount > 0) return;
      // テキストリセット
      _elmTd.textContent = '';
    });
  }

  addRow() {
    if(!this.elmTargetCloneTableRow) return;

    /**
     * 行の中にモーダルがあれば、Modal.jsでクリックイベントを設定する
     */
    const initModal = () => {
      const elmsModalOpenBtn = this.elmTargetCloneTableRow.querySelectorAll('[data-js-modal-open-btn]') || false;
      if (!elmsModalOpenBtn) return;
      // 起動ボタンのkeyをもとに、モーダルの親要素を全て取得
      const getElmsModalWrapper = () => {
        let resultElmsModalWrapper = [];
        elmsModalOpenBtn.forEach(_elmModalOpenBtn => {
          const key = _elmModalOpenBtn.getAttribute('data-js-modal-open-btn');
          resultElmsModalWrapper.push(document.querySelector(`[data-js-modal-content=${key}]`));
        });
        return resultElmsModalWrapper;
      }
      const elmsModalWrapper = getElmsModalWrapper();
      // クラス実行
      class ModalAddRow extends Modal {
        constructor(_parm) {
          super(_parm);
          this.elmsModalWrapper = elmsModalWrapper; // 上書き
        }
      }
      const modalAddRow = new ModalAddRow({
        modalWrappersAttr: 'data-js-modal-content',
      });
      elmsModalWrapper.forEach(_elmModalWrapper => {
        modalAddRow.addEvent(_elmModalWrapper);
      });
    }

    // data-row属性を持っていれば、値を+1する用の変数
    const rowNum = this.elmTargetTableRow.closest('table').querySelectorAll('tbody tr').length + 1;

    /**
     * 行の中に非同期検索のモーダル起動ボタンがあれば、AsyncGetSearchResult.jsでクリックイベントを設定する
     */
    const initAsyncGetSearchResult = () => {
      const elmsSearchModalOpenBtn = this.elmTargetCloneTableRow.querySelectorAll('[data-js-async-search-modal-open-btn]') || false;
      if (!elmsSearchModalOpenBtn) return;

      const asyncGetSearchResult = new AsyncGetSearchResult();
      elmsSearchModalOpenBtn.forEach(_elm => {
        // クリックイベント付与
        asyncGetSearchResult.addEventToSearchModalOpenBtn(_elm);
        // data-js-async-search-result-elm の値の data-row を+1する
        let attrValue = _elm.getAttribute('data-js-async-search-result-elm');
        const ptn = /data-row=\'1\'/;
        const rep = attrValue.replace(new RegExp(ptn, 'g'), `data-row=\'${rowNum}\'`);
        _elm.setAttribute('data-js-async-search-result-elm', rep);
      });
    }

    // <input>などの値をリセット
    if(this.isRowReset) {
      this.resetRow();
    }

    // <tbody>の最後に複製した<tr>要素を追加する
    this.elmTargetTable.querySelector('tbody').appendChild(this.elmTargetCloneTableRow);

    // data-row属性を持っていれば、値を+1する
    Array.prototype.slice.call(this.elmTargetCloneTableRow.querySelectorAll('[data-row]')).forEach(_elm => {
      _elm.setAttribute('data-row', rowNum);
    });

    // イベント付与
    initModal();
    initAsyncGetSearchResult();
  }

}
