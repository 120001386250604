/**
 * 文例集に関するクラス
 * 主な機能
 * ・全ての<textarea>の見出し要素に、文例集起動ボタンを追加（textareaに.__not-text-templateを付与で除外可能）
 * ・文例集起動ボタンクリックで、非同期で文例集を取得＆結果モーダルを表示
 */
export default class AsyncTextTemplate {
  constructor() {
    this.elm = {
      nowLoading: document.querySelector('#js-nowloading'),
      resultModal: false, // this.createBaseResultModal()実行後、モーダルをセット
      cloneInitResultTable: false, // this.createTextTemplateTable()の最後で、全件データを持った一覧<table>をcloneしてセット
      targetTextarea: false, // モーダル起動ボタンに紐づく<textarea>
    }
    this.elms = {
      textarea: Array.prototype.slice.call(document.querySelectorAll('textarea:not(.__not-text-template)'))
    }
    this.resultModalId = {
      wrapper: 'js-async-text-template-modal',
      form: 'js-async-text-template-modal-form',
      table: 'js-async-text-template-modal-table',
    }
    this.sortSelect = {
      elm: false,
      id: 'js-async-text-template-sort-select-wrap',
    }
    this.requestUrl = '/api/v1/textTemplateHandler'
    this.addTextTemplatetUrl = '/setting/text-template/new/comp-ajax'
    this.editTextTemplatetUrl = '/setting/text-template/edit/comp-ajax'
    this.detailTextTemplatetUrl = '/setting/text-template/detail-ajax/int_text_template_id'
    this.deleteTextTemplatetUrl = '/setting/text-template/delete-ajax/int_text_template_id'
    this.rowInputRadioName = 'is_select_row'
    this.classPreLimit = '__limit'
    this.attrPreLimitBtn = 'data-js-show-limit-btn' // this.createResultModalOpenBtn()で動的に追加した起動ボタン
    this.attrEditBtn = 'data-js-text-template-edit-btn'
    this.attrDeleteBtn = 'data-js-text-template-delete-btn'
    this.attrModalOpenBtn = 'data-js-text-template-open-btn' // HTML側で直接定義してる起動ボタン
    this.attrCreateModalOpenBtn = 'data-js-create-text-template-open-btn' // this.createResultModalOpenBtn()で動的に追加した起動ボタン
    this.dataTable = false;
  }

  /**
   * 文例集を表示させるベースのモーダルを生成する
   * @returns 生成した文例集モーダルのElementオブジェクト
   */
  createBaseResultModal() {
    const createDom = `
      <div class="c-modal--full __result" id="${this.resultModalId.wrapper}" style="display: none;">
        <form class="content-wrapper p-main-content" id="${this.resultModalId.form}">
          <div class="p-main-content-header">
            <div class="p-main-content-header__inner">
              <div class="p-main-content-header__upper">
                <div class="container-fluid">
                  <div class="row justify-content-between">
                    <div class="col-auto row align-items-center">
                      <div class="l-flex __row-center">
                        <button type="button" class="c-icn--close" data-js-modal-close-btn></button>
                      </div>
                      <div class="u-ml-s">
                        <span class="c-heading--1">文例集</span>
                      </div>
                    </div>
                    <div class="row justify-content-center align-items-center flex-row-reverse is-sp-hide">
                      <div class="col-auto">
                        <button type="submit" class="c-btn __big">確定</button>
                      </div>
                      <div class="col-auto">
                        <button type="button" class="c-btn __text __gray" data-js-modal-close-btn>キャンセル</button>
                      </div>
                      <div class="col-auto">
                        <button type="button" class="c-btn __big" id="${this.resultModalId.form}-add-buton">新規</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content p-main-content-body">
            <div class="container-fluid">
              <div id="${this.sortSelect.id}" style="margin: 0 0 10px;"></div>
              <div class="c-card">
                <div class="c-card__body u-p0">
                  <div id="${this.resultModalId.table}"></div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="c-modal" id="${this.resultModalId.form}-add" data-js-modal-content="${this.resultModalId.form}-add" style="display: none!important;z-index: 100000!important;">
        <form class="c-modal__inner" action="" method="POST" target="_blank">
            <a href="javascript:void(0);" class="c-modal__close-btn" data-js-modal-close-btn="${this.resultModalId.form}-add"></a>
            <div class="c-card">
            <p class="c-card__header">文例集新規</p>
            <div class="c-card__body">
                <div class="c-form__row">
                    <div class="form-row">
                    <div class="col-auto form-group">
                        <p class="c-form__head">用途</p>
                        <input type="text" name="str_use" value="">
                    </div>
                    <div class="col-auto form-group">
                        <p class="c-form__head">順位</p>
                        <input type="number" name="int_order" value="" class="u-w60">
                    </div>
                    <div class="col-auto form-group">
                        <p class="c-form__head">区分</p>
                        <input type="text" name="str_memo" value="">
                    </div>
                    <div class="col-auto form-group">
                        <p class="c-form__head">デフォルト</p>
                        <input type="checkbox" name="bol_default_flg" id="${this.resultModalId.form}-checkbox_bol_default_flg-add" value="1">
                        <label for="${this.resultModalId.form}-checkbox_bol_default_flg-add">&nbsp;</label>
                    </div>
                    <div class="col-12 form-group">
                        <p class="c-form__head">内容</p>
                        <textarea name="str_text" class="__not-text-template"></textarea>
                    </div>
                </div>
            </div>
            </div>
            <div class="c-card__footer">
                <div class="container-fluid">
                    <div class="row justify-content-end align-items-center">
                    <div class="col-auto">
                        <button type="button" class="c-btn __text __gray" data-js-modal-close-btn="${this.resultModalId.form}-add">キャンセル</button>
                    </div>
                    <div class="col-auto">
                        <button type="submit" class="c-btn">追加</button>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </form>
    </div>
    <div class="c-modal" id="${this.resultModalId.form}-edit" data-js-modal-content="${this.resultModalId.form}-edit" style="display: none!important;z-index: 100000!important;">
        <form class="c-modal__inner" action="" method="POST" target="_blank">
            <a href="javascript:void(0);" class="c-modal__close-btn" data-js-modal-close-btn="${this.resultModalId.form}-edit"></a>
            <input type="hidden" name="int_text_template_id" value=""/>
            <div class="c-card">
            <p class="c-card__header">文例集編集</p>
            <div class="c-card__body">
                <div class="c-form__row">
                    <div class="form-row">
                    <div class="col-auto form-group">
                        <p class="c-form__head">用途</p>
                        <input type="text" name="str_use" value="">
                    </div>
                    <div class="col-auto form-group">
                        <p class="c-form__head">順位</p>
                        <input type="number" name="int_order" value="" class="u-w60">
                    </div>
                    <div class="col-auto form-group">
                        <p class="c-form__head">区分</p>
                        <input type="text" name="str_memo" value="">
                    </div>
                    <div class="col-auto form-group">
                        <p class="c-form__head">デフォルト</p>
                        <input type="checkbox" name="bol_default_flg" id="${this.resultModalId.form}-checkbox_bol_default_flg-edit" value="1">
                        <label for="${this.resultModalId.form}-checkbox_bol_default_flg-edit">&nbsp;</label>
                    </div>
                    <div class="col-12 form-group">
                        <p class="c-form__head">内容</p>
                        <textarea name="str_text" class="__not-text-template"></textarea>
                    </div>
                </div>
            </div>
            </div>
            <div class="c-card__footer">
                <div class="container-fluid">
                    <div class="row justify-content-end align-items-center">
                    <div class="col-auto">
                        <button type="button" class="c-btn __text __gray" data-js-modal-close-btn="${this.resultModalId.form}-edit">キャンセル</button>
                    </div>
                    <div class="col-auto">
                        <button type="submit" class="c-btn">保存</button>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </form>
    </div>
    <div class="c-modal" id="${this.resultModalId.form}-delete" data-js-modal-content="${this.resultModalId.form}-delete" style="display: none!important;z-index: 100000!important;">
        <form class="c-modal__inner" action="" method="POST" target="_blank">
            <a href="javascript:void(0);" class="c-modal__close-btn" data-js-modal-close-btn="${this.resultModalId.form}-delete"></a>
            <input type="hidden" name="int_text_template_id" value=""/>
            <div class="c-card">
            <p class="c-card__header">文例集削除</p>
            <div class="c-card__body">
                <p>該当文例集を削除しますか？</p>
            </div>
            <div class="c-card__footer">
                <div class="container-fluid">
                    <div class="row justify-content-end align-items-center">
                    <div class="col-auto">
                        <button type="button" class="c-btn __text __gray" data-js-modal-close-btn="${this.resultModalId.form}-delete">キャンセル</button>
                    </div>
                    <div class="col-auto">
                        <button type="submit" class="c-btn">削除</button>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </form>
    </div>
    `;
    document.querySelector('#wrapper').insertAdjacentHTML('beforeend', createDom);
    this.elm.resultModal = document.querySelector(`#${this.resultModalId.wrapper}`);
    return this.elm.resultModal;
  }

  /**
   * データをサーバーから非同期で取得する
   * @param {String} _requestUrl リクエスト用URL
   * @returns resJson() リクエストによって返ってきたオブジェクト
   */
  getRequestData(_requestUrl) {
    const resJson = async () => {
      const response = await fetch(_requestUrl);
      if(response.status == 200) {
        const data = await response.json();
        if(data.data.length) return JSON.stringify(data);
        return false;
      } else {
        throw new Error(response.status);
      }
    }
    return resJson();
  }

  /**
   * 取得したデータを元に、文例集の<table>を生成する
   * @param {Object} _requestData this.getRequestData()で取得したデータ
   */
  createTextTemplateTable(_requestData) {
    const datas = JSON.parse(_requestData).data;
    const elmOutputTableWrapper = document.querySelector(`#${this.resultModalId.table}`);

    // <table>の子要素を全て削除
    const resetTable = () => {
      if (elmOutputTableWrapper.querySelector('table')) {
        elmOutputTableWrapper.querySelector('table').remove();
      }
    }

    // <table>の中身を生成
    const createTableDom = () => {
      // DOM生成用の配列を作成（`order`を基準に`datas`を並び替える）
      const getArrayTableData = () => {
        let resultArray = [];
        if (!datas) {
            return resultArray;
        }
        for (let i = 0; i < datas.length; i++) {
          const data = datas[i];
          // ↓が<table>の一行ごとのデータになる
          let arrayRowDatas = [];
          Object.keys(data).forEach(_column => {
            data[_column].key = _column;
            arrayRowDatas.push(data[_column]);
          });
          // orderの順番に並び替える
          arrayRowDatas.sort((a, b) => {
            return a.order - b.order;
          });
          resultArray.push(arrayRowDatas);
        };
        return resultArray;
      }
      const arrayTableData = getArrayTableData();

      // 以降でDOMを生成していく
      let createDom = '<table>';

      // <thead> 生成（最初の空の<th>はラジオボタン用）
      createDom += '<thead><tr><th class="__hide-menu" style="width:40px;"></th>';
      if (arrayTableData.length > 0) {
          arrayTableData[0].forEach(_columnData => {
            if(typeof _columnData.order === 'number') {
              createDom += `<th style="white-space: nowrap;">${_columnData.label}</th>`;
            } else{
              createDom += `<th style="display: none;">${_columnData.label}</th>`;
            }
          });
      } else {
          createDom += `<th style="width:10%">順位</th>`;
          createDom += `<th style="width:15%">用途</th>`;
          createDom += `<th style="width:15%">区分</th>`;
          createDom += `<th style="width:60%">内容</th>`;
      }
      createDom += `<th>&nbsp;</th>`;
      createDom += `<th>&nbsp;</th>`;
      createDom += '</tr></thead>';

      // <tbody> 生成
      createDom += '<tbody>';
      arrayTableData.forEach((_rowData, _index) => {
        const getBolDefaultFlagValue = () => {
          return _rowData.find(_data => {
            return _data.key === 'bol_default_flg';
          }).value
        }
        createDom += '<tr>';
        createDom += `
          <td>
            <label class="__checkbox" style="padding-left: 0;">
              <input type="checkbox" name="${this.rowInputRadioName}" ${getBolDefaultFlagValue() ? 'checked' : ''}>
              <span>&nbsp;</span>
            </label>
          </td>`;
        _rowData.forEach(_columnData => {
          if(typeof _columnData.order === 'number') {
            // 値があるなら
            if(_columnData.value) {
              // 'str_text'なら<td>の横幅を100%にする
              switch (_columnData.key) {
                case 'int_order':
                  createDom += `<td data-key="${_columnData.key}" class="u-text-center" style="white-space: nowrap;">${_columnData.value}</td>`;
                  break;
                case 'str_text':
                  createDom += `<td data-key="${_columnData.key}" style="width: 100%;"><pre style="white-space: pre-wrap;">${_columnData.value}</pre></td>`;
                  break;
                default:
                  createDom += `<td data-key="${_columnData.key}" style="white-space: nowrap;">${_columnData.value}</td>`;
                  break;
              }
            } else{
              createDom += `<td data-key="${_columnData.key}"></td>`;
            }
          } else{
            createDom += `<td style="display: none;" data-key="${_columnData.key}">${_columnData.value}</td>`;
          }
        });
        createDom += `<td><button type="button" class="c-btn __border __small u-mt-s" ${this.attrEditBtn}>編集</button></td>`;
        createDom += `<td><button type="button" class="c-btn __border __small u-mt-s" ${this.attrDeleteBtn}>削除</button></td>`;

        createDom += '</tr>';
      });
      createDom += '</tbody>';
      createDom += '</table>';

      // DOM追加
      elmOutputTableWrapper.insertAdjacentHTML('beforeend', createDom);

      // 内容（str_text）の文字数が多かったら、全文表示ボタンで高さ制限する
      const createShowLimitBtn = () => {
        Array.prototype.slice.call(document.querySelectorAll(`#${this.resultModalId.table} tbody tr td[data-key="str_text"] pre`)).forEach(_elmPre => {
          if(_elmPre.clientHeight < 140) return;
          _elmPre.classList.add(this.classPreLimit);
          _elmPre.insertAdjacentHTML('afterend', `<button type="button" class="c-btn __border __small u-mt-s" ${this.attrPreLimitBtn}>全文表示</button>`);
        });
      }
      createShowLimitBtn();
    };

    resetTable();
    createTableDom();
  }

  /**
   * 取得したデータを元に、ソートする<select>を生成する（ul_text_template.str_use）
   * イベントも付与する
   * @param {Object} _requestData this.getRequestData()で取得したデータ
   */
  createSelectStrUse(_requestData) {
    const datas = JSON.parse(_requestData).array_str_use_column;
    const elmWrapper = document.querySelector(`#${this.sortSelect.id}`);

    while (elmWrapper.hasChildNodes()) {
        elmWrapper.removeChild(elmWrapper.firstChild);
    }

    let createDom = `
      <select style="display: inline-block; width: auto;">
        <option value="">ALL</option>
    `;
    if (datas) {
        datas.forEach(_data => {
          createDom += `<option value="${_data.str_use}">${_data.str_use}</option>`;
        });
    }
    createDom += `</select>`;
    elmWrapper.insertAdjacentHTML('beforeend', createDom);
    this.sortSelect.elm = elmWrapper.querySelector('select');

    // イベント付与
    this.sortSelect.elm.addEventListener('input', (_ev) => {
      this.sortTable(_ev.target.options[_ev.target.selectedIndex], this.elm.cloneInitResultTable);
    })
  }

  /**
   * dataTable実行
   */
  dataTableInit(_tableSelector) {
    $(`${_tableSelector} thead th`).each(function (_index, _elm) {
      var childHtml = $(this).html()
      var addChildSpan = '<span>' + childHtml + '</span>'
      $(this).html(addChildSpan)
    });
    this.dataTable = $(_tableSelector).DataTable({
      displayLength: 99999,
      dom: '<"c-js-datatable"<"c-js-datatable__table"t>>',
      order: [ [ 2, 'asc' ] ],
      scrollX: true,
      language: {
        sZeroRecords: '文例集のデータがありませんでした。',
      },
    });
  }

  /**
   * 非同期でリクエストを投げてから文例集モーダルを表示までの処理
   */
  initResultModal() {

    // ローディングを表示
    this.elm.nowLoading.setAttribute('data-active', 'true');

    // リクエスト実行
    this.getRequestData(this.requestUrl)
      // 成功時
      .then(_data => {

        // dataTableを削除
        if(this.dataTable) {
          this.dataTable.destroy();
        }

        // 取得したデータを元に、文例集の<table>を生成する
        this.createTextTemplateTable(_data);

        // dataTable化する前の<table>をプロパティにセット
        this.elm.cloneInitResultTable = document.querySelector(`#${this.resultModalId.table} table`).cloneNode(true);

        // ソート用<select>を生成する
        this.createSelectStrUse(_data);

        // dataTableを実行
        this.dataTableInit(`#${this.resultModalId.table} table`);

        // 検索結果モーダル起動
        this.elm.resultModal.setAttribute('data-active', 'true');

        // ローディング解除
        this.elm.nowLoading.setAttribute('data-active', 'false');

        // 「行クリックでラジオボタンを選択させる」イベントを付与
        Array.prototype.slice.call(document.querySelectorAll(`#${this.resultModalId.table} table tbody tr`)).forEach(_elmTableRow => {
          _elmTableRow.addEventListener('click', (_ev) => {
            if(_ev.target.tagName === 'BUTTON') return;
            this.setCheckedRadio(_elmTableRow);
          })
        });

        // 「全文表示ボタンクリックで表示制限してた内容を切り替え」イベント付与
        this.addEventPreLimitBtn();
        this.addEventEditBtn();
        this.addEventDeleteBtn();

        ///SC-3683で修正///
        let selectId = this.sortSelect.id;
          let templateOptions = $("#js-async-text-template-modal").find("select option");
          let templateOptionsValue = [];
          $.each(templateOptions, function(index, value) {
              templateOptionsValue.push(value.text);
          });
          let result = $.inArray($("#js-async-text-template-modal").val(), templateOptionsValue);
          if(result !=- 1){
              document.querySelector(`#${selectId}`).querySelector('select').value = $("#js-async-text-template-modal").val();
              document.querySelector(`#${selectId}`).querySelector('select').dispatchEvent(new Event('input'));
          }else{
              $("#js-async-text-template-modal").find("select").prop("selectedIndex", 0);
              document.querySelector(`#${selectId}`).querySelector('select').dispatchEvent(new Event('input'));
          }
          ////////////

      })
      // 失敗時
      .catch(_error => {
        //console.log(_error);
        alert('文例集の読み込みに失敗しました。\nこのエラーが何度も続く場合、お手数ですがサポートまでお問い合わせをお願い致します。\n' + _error)
        this.elm.nowLoading.setAttribute('data-active', 'false');
      });
  }

  /**
   * 文例集モーダルを閉じる
   */
  closeResultModal() {
    document.querySelector(`#${this.resultModalId.wrapper}`).setAttribute('data-active', 'false');
  }

  /**
   * 文例集の<table>の行クリックでラジオボタンを選択させる（ユーザビリティUPのため）
   * @param {Element} _elmTableRow クリックした行の<tr>要素
   */
  setCheckedRadio(_elmTableRow) {
    const elmInputRadio = _elmTableRow.querySelector('input[type="checkbox"]')
    elmInputRadio.checked = !elmInputRadio.checked;
  }

  /**
   * <textarea>（this.elms.textarea）に文例集モーダル起動ボタンを追加する
   */
  createResultModalOpenBtn(_elmTextarea) {
    const elmTextareaHeading = _elmTextarea.previousElementSibling;
    if(!elmTextareaHeading) return;
    if(!elmTextareaHeading.classList.contains('c-form__head')) return;
    elmTextareaHeading.insertAdjacentHTML('beforeend', `<button class="template-btn" type="button" ${this.attrCreateModalOpenBtn}><i class="far fa-clipboard"></i></button>`);
  }

  /**
   * <table>をソート
   * @param {Element} _elmSelectedOption 選択中の<option>
   * @param {Element} _elmCloneInitResultTable 非同期で生成した<table>（this.elm.cloneInitResultTable）
   */
  sortTable(_elmSelectedOption, _elmCloneInitResultTable) {
    // dataTable解除
    if(this.dataTable) {
      this.dataTable.destroy();
    }

    // <table>をDOMごと削除
    const elmOutputTableWrapper = document.querySelector(`#${this.resultModalId.table}`);
    while(elmOutputTableWrapper.lastChild){
      elmOutputTableWrapper.removeChild(elmOutputTableWrapper.lastChild);
    }

    // <table>を再生成
    let elmCloneTable = _elmCloneInitResultTable.cloneNode(true);
    elmOutputTableWrapper.appendChild(elmCloneTable);
    // <tr>を絞り込む & 「行クリックでラジオボタンを選択させる」イベントを付与
    let elmsTableRow = Array.prototype.slice.call(elmCloneTable.querySelectorAll('table tbody tr'));
    elmsTableRow.forEach(_elmRow => {
      const sortKey = _elmSelectedOption.value;
      const elmTargetColumn = _elmRow.querySelector('td[data-key="str_use"]');
      if(sortKey && elmTargetColumn.textContent !== sortKey) {
        _elmRow.remove();
      } else{
        _elmRow.addEventListener('click', (_ev) => {
          if(_ev.target.tagName === 'BUTTON') return;
          this.setCheckedRadio(_elmRow);
        })
      }
    });

    // dataTable実行
    this.dataTableInit(`#${this.resultModalId.table} table`);

    // 「全文表示ボタンクリックで表示制限してた内容を切り替え」イベント付与
    this.addEventPreLimitBtn();
    this.addEventEditBtn();
    this.addEventDeleteBtn();
  }

  /**
   * 文例集モーダルの選択されている行の本文（str_text）を<textarea>に反映する
   * @param {Element} _elmTargetTextarea 値を反映する<textarea>
   */
  setCheckedStrText(_elmTargetTextarea) {
    const elmsTableRow = Array.prototype.slice.call(document.querySelectorAll(`#${this.resultModalId.table} .dataTables_scrollBody table tbody tr`));
    const targetRowDatas = elmsTableRow.map(_elmRow => {
    if(_elmRow.querySelector(`input[type="checkbox"][name="${this.rowInputRadioName}"]`).checked) {
        return {
          int_order: Number(_elmRow.querySelector('td[data-key="int_order"]').textContent),
          str_text: _elmRow.querySelector('td[data-key="str_text"] pre').textContent
        }
      }
    }).filter(Boolean).sort((a, b) => a.int_order - b.int_order);
    const createSetValue = () => {
      let resultSetValue = '';
      targetRowDatas.forEach((_data, _index) => {
        if(_index > 0) resultSetValue += '\n\n';
        resultSetValue += _data.str_text;
      });
      return resultSetValue;
    }
    _elmTargetTextarea.value = createSetValue();
  }

  /**
   * 表示制限しているテーブルの「内容（str_text）」の表示制限を切り替える
   */
  toggleLimitPreStrText(_elmHasEventBtn) {
    const elmPreStrText = _elmHasEventBtn.previousElementSibling;
    if(elmPreStrText.classList.contains(this.classPreLimit)) {
      elmPreStrText.classList.remove(this.classPreLimit);
      _elmHasEventBtn.textContent = '閉じる';
    } else{
      elmPreStrText.classList.add(this.classPreLimit);
      _elmHasEventBtn.textContent = '全文表示';
    }
  }

  /**
   * 「全文表示ボタンクリックで表示制限してた内容を切り替え」イベント付与
   */
  addEventPreLimitBtn() {
    Array.prototype.slice.call(document.querySelectorAll(`#${this.resultModalId.table} tbody tr td[data-key="str_text"] button[${this.attrPreLimitBtn}]`)).forEach(_elmPreLimitBtn => {
      _elmPreLimitBtn.addEventListener('click', (_ev) => {
        this.toggleLimitPreStrText(_elmPreLimitBtn);
      })
    });
  }

  addEventEditBtn() {
    var _this = this;
    this.dataTable.rows().every(function() {
        var rowNode = this.node();
        $(rowNode).find(`[${_this.attrEditBtn}]`).off('click').on('click', function () {
            var int_text_template_id = $(this).closest('tr').find('[data-key="int_text_template_id"]').text();
            $(`#${_this.resultModalId.form}-edit`).find('[name="int_text_template_id"]').val(int_text_template_id);
            _this.detailTextTemplate();
        });
    });
  }

  addEventDeleteBtn() {
    var _this = this;
    this.dataTable.rows().every(function() {
        var rowNode = this.node();
        $(rowNode).find(`[${_this.attrDeleteBtn}]`).off('click').on('click', function () {
            var int_text_template_id = $(this).closest('tr').find('[data-key="int_text_template_id"]').text();
            $(`#${_this.resultModalId.form}-delete`).find('[name="int_text_template_id"]').val(int_text_template_id);
            document.querySelector(`#${_this.resultModalId.form}-delete`).style.setProperty('display', 'flex', 'important');
        });
    });
  }

  addTextTemplate() {

    var token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    var formData = new FormData(document.querySelector(`#${this.resultModalId.form}-add form`));
    formData.append('_token', token);
    this.elm.nowLoading.setAttribute('data-active', 'true');
    fetch(this.addTextTemplatetUrl , {
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': token
          },
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        document.querySelector(`#${this.resultModalId.form}-add`).style.setProperty('display', 'none', 'important');
        this.elm.nowLoading.setAttribute('data-active', 'false');
        this.initResultModal();
      })
      .catch(error => {
        console.error(error);
        this.elm.nowLoading.setAttribute('data-active', 'false');
        alert('文例集の新規登録が失敗しました。');
      });

  }

  editTextTemplate() {

    var token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    var formData = new FormData(document.querySelector(`#${this.resultModalId.form}-edit form`));
    formData.append('_token', token);
    this.elm.nowLoading.setAttribute('data-active', 'true');
    fetch(this.editTextTemplatetUrl , {
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': token
          },
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        document.querySelector(`#${this.resultModalId.form}-edit`).style.setProperty('display', 'none', 'important');
        this.elm.nowLoading.setAttribute('data-active', 'false');
        this.initResultModal();
      })
      .catch(error => {
        console.error(error);
        this.elm.nowLoading.setAttribute('data-active', 'false');
        alert('文例集の編集が失敗しました。');
      });

  }

  detailTextTemplate() {

    var token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    var int_text_template_id =  document.querySelector(`#${this.resultModalId.form}-edit form [name="int_text_template_id"]`).value;
    this.elm.nowLoading.setAttribute('data-active', 'true');
    var detailTextTemplatetUrl = this.detailTextTemplatetUrl.replace('int_text_template_id', int_text_template_id);
    fetch(detailTextTemplatetUrl , {
        method: 'GET',
        headers: {
            'X-CSRF-TOKEN': token
          },
      })
      .then(response => response.json())
      .then(data => {
        document.querySelector(`#${this.resultModalId.form}-edit`).style.setProperty('display', 'flex', 'important');
        this.elm.nowLoading.setAttribute('data-active', 'false');
        document.querySelector(`#${this.resultModalId.form}-edit [name="int_text_template_id"]`).value = data.int_text_template_id;
        document.querySelector(`#${this.resultModalId.form}-edit [name="int_order"]`).value = data.int_order;
        document.querySelector(`#${this.resultModalId.form}-edit [name="str_use"]`).value = data.str_use;
        document.querySelector(`#${this.resultModalId.form}-edit [name="str_memo"]`).value = data.str_memo;
        document.querySelector(`#${this.resultModalId.form}-edit [name="str_text"]`).value = data.str_text;
        document.querySelector(`#${this.resultModalId.form}-edit [name="bol_default_flg"]`).setAttribute('checked', data.bol_default_flg ? "checked" : "");
      })
      .catch(error => {
        console.error(error);
        this.elm.nowLoading.setAttribute('data-active', 'false');
        alert('文例の詳細取得が失敗しました。');
      });

  }

  deleteTextTemplate() {

    var token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    var int_text_template_id =  document.querySelector(`#${this.resultModalId.form}-delete form [name="int_text_template_id"]`).value;
    this.elm.nowLoading.setAttribute('data-active', 'true');
    var deleteTextTemplatetUrl = this.deleteTextTemplatetUrl.replace('int_text_template_id', int_text_template_id);
    fetch(deleteTextTemplatetUrl , {
        method: 'GET',
        headers: {
            'X-CSRF-TOKEN': token
          },
      })
      .then(response => response.json())
      .then(data => {
        document.querySelector(`#${this.resultModalId.form}-delete`).style.setProperty('display', 'none', 'important');
        this.elm.nowLoading.setAttribute('data-active', 'false');
        this.initResultModal();
      })
      .catch(error => {
        console.error(error);
        this.elm.nowLoading.setAttribute('data-active', 'false');
        alert('文例集の削除が失敗しました。');
      });

  }

  /**
   * 各要素にイベントを付与
   */
  addEvent() {
    /**
     * 文例集モーダル 閉じるボタン クリック
     */
    Array.prototype.slice.call(document.querySelectorAll(`#${this.resultModalId.wrapper} [data-js-modal-close-btn]`)).forEach(_elmCloseBtn => {
      _elmCloseBtn.addEventListener('click', (_ev) => {
        this.closeResultModal();
      })
    });

    /**
     * HTML側で直接書いている、文例集モーダル起動ボタン クリック
     * （HTML側で直接書いている = DOM構造が違って、this.createResultModalOpenBtn()で追加できなかったケース）
     */
    Array.prototype.slice.call(document.querySelectorAll(`[${this.attrModalOpenBtn}]`)).forEach(_elmOpenBtn => {
      _elmOpenBtn.addEventListener('click', (_ev) => {
        const getTextareaName = _elmOpenBtn.getAttribute(this.attrModalOpenBtn);
        const targetTextarea = document.querySelector(`textarea[name="${getTextareaName}"]`);
        this.elm.targetTextarea = targetTextarea;
        this.initResultModal();
      })
    });

    /**
     * 動的に追加した、文例集モーダル起動ボタン クリック
     */
    Array.prototype.slice.call(document.querySelectorAll(`[${this.attrCreateModalOpenBtn}]`)).forEach(_elmOpenBtn => {
      _elmOpenBtn.addEventListener('click', (_ev) => {
        this.elm.targetTextarea = _ev.currentTarget.parentNode.nextElementSibling;
        this.initResultModal();
      })
    });

    /**
     * 文例集モーダル 右上確定ボタン クリック
     */
    document.querySelector(`#${this.resultModalId.form}`).addEventListener('submit', (_ev) => {
      _ev.preventDefault();
      this.setCheckedStrText(this.elm.targetTextarea);
      this.closeResultModal();
      this.sortSelect.elm.selectedIndex = 0; // ソートのセレクトボックスのselectedをリセット
    });

    document.querySelector(`#${this.resultModalId.form}-add-buton`).addEventListener('click', (_ev) => {
      _ev.preventDefault();
      document.querySelector(`#${this.resultModalId.form}-add`).querySelector('form').reset();
      document.querySelector(`#${this.resultModalId.form}-add`).style.setProperty('display', 'flex', 'important');
    });

    Array.prototype.slice.call(document.querySelectorAll(`[data-js-modal-close-btn="${this.resultModalId.form}-add"]`)).forEach(_elmCloseBtn => {
        _elmCloseBtn.addEventListener('click', (_ev) => {
        _ev.preventDefault();
         document.querySelector(`#${this.resultModalId.form}-add`).style.setProperty('display', 'none', 'important');
        });
    });

    document.querySelector(`#${this.resultModalId.form}-add`).addEventListener('submit', (_ev) => {
        _ev.preventDefault();
        this.addTextTemplate();
    });

    Array.prototype.slice.call(document.querySelectorAll(`[data-js-modal-close-btn="${this.resultModalId.form}-edit"]`)).forEach(_elmCloseBtn => {
        _elmCloseBtn.addEventListener('click', (_ev) => {
        _ev.preventDefault();
         document.querySelector(`#${this.resultModalId.form}-edit`).style.setProperty('display', 'none', 'important');
        });
    });

    document.querySelector(`#${this.resultModalId.form}-edit`).addEventListener('submit', (_ev) => {
        _ev.preventDefault();
        this.editTextTemplate();
    });

    Array.prototype.slice.call(document.querySelectorAll(`[data-js-modal-close-btn="${this.resultModalId.form}-delete"]`)).forEach(_elmCloseBtn => {
        _elmCloseBtn.addEventListener('click', (_ev) => {
        _ev.preventDefault();
         document.querySelector(`#${this.resultModalId.form}-delete`).style.setProperty('display', 'none', 'important');
        });
    });

    document.querySelector(`#${this.resultModalId.form}-delete`).addEventListener('submit', (_ev) => {
        _ev.preventDefault();
        this.deleteTextTemplate();
    });
  }

  init() {
     // モーダルのベースを生成
    this.createBaseResultModal();
     // モーダル起動ボタンを全ての<textarea>の手前に追加
    this.elms.textarea.forEach(_elmTextarea => {
      this.createResultModalOpenBtn(_elmTextarea);
    });
    // イベント付与
    this.addEvent()
  }
}
