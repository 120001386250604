import TableAddRow from '../TableAddRow';

export const cloneTableRow = (_this) => {
  _this.preventDefault();
  const elmTargetTableRow = _this.currentTarget.closest('tr')
  const tableAddRow = new TableAddRow({
    isRowReset: false,
    elmTargetTableRow: elmTargetTableRow,
  });
  console.log(tableAddRow)
  tableAddRow.addRow();
}
