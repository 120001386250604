import polyfillClosest from "./polyfill/closest";

import {cloneTableRow} from "./inlineEvent/cloneTableRow";

window.cloneTableRow = cloneTableRow; // function cloneTableRow() {} で関数作成したのと同じ意味

// 共通パーツ
import HideMenu from "./HideMenu";
import Modal from "./Modal";
import Tab from "./Tab";
import Alert from "./Alert";
import AsyncTextTemplate from "./AsyncTextTemplate";
import AsyncFavorite from "./AsyncFavorite";
import LabelPrint from "./LabelPrint";
import ReportPrint from "./ReportPrint";
import ReportPrintCustomer from "./ReportPrintCustomer";
import AsyncCopyModel from "./AsyncCopyModel";
import SelectCheck from "./SelectCheck"

// フォーム系
import FormValidation from "./FormValidation";
import FormMethods from "./FormMethods";
import TagSelect from "./TagSelect";
import SelectSearch from "./SelectSearch";
import AsyncGetSearchResult from "./AsyncGetSearchResult";

// テーブル系
import TableMethods from "./TableMethods";
import DataTableCheckAll from "./DataTableCheckAll";
import DataTableCheck from "./DataTableCheck";

// サイドバー
// import SearchSidebar from './SearchSidebar' // 現在未使用
import SidebarNav from "./SidebarNav";
import SidebarToggle from "./SidebarToggle";

// 外部プラグイン
import CustomDataTable from "./CustomDataTable";

// 各ページ特有
import PageContractDetail from "./page/ContractDetail/ContractDetail";

// PCの最小ウィンドウ幅
const pcW = 970;

// dataTableを自作ライブラリとして定義（関連ファイル => ./CustomDataTable）
// ※利用できるオプションをここで制限している
window.CUSTOM_DATA_TABLE = {};
CUSTOM_DATA_TABLE.init = function (selectorTable, _options) {
    const elmDataTableBefore = document.querySelector(selectorTable).cloneNode(true);
    _options = _options ? _options : {};
    const customDataTable = new CustomDataTable({
        _selectorTable: selectorTable,
        _searchColNums: _options.searchColNums ? _options.searchColNums : [0],
        _selectorSearchInput: _options.selectorSearchInput ? _options.selectorSearchInput : false,
        _order: _options.order ? _options.order : [],
        _hidePager: _options.hidePager ? _options.hidePager : false,
        _defaultDisplayLength: _options.defaultDisplayLength ? _options.defaultDisplayLength : 50,
        _orderIdColumn: _options.orderIdColumn >= 0 ? _options.orderIdColumn : false,
        _processing : _options.processing ? _options.processing : false,
        _ajax : _options.ajax ? _options.ajax : false,
        _columns : _options.columns ? _options.columns : false,
        _columnDefs : _options.columnDefs ? _options.columnDefs : false,
        _drawCallback : _options.drawCallback ? _options.drawCallback : false,
        _rowCallback : _options.rowCallback ? _options.rowCallback : false,
        _serverSide : _options.serverSide ? _options.serverSide : false,
        _fixedColumns : _options.fixedColumns ? _options.fixedColumns : false,
    });
    const customDataTableInit = customDataTable.init();

    // お気に入り機能がある画面なら、お気に入りjsを実行
    if (document.querySelector('#js-favorite-thead')) {
        const asyncFavorite = new AsyncFavorite({
            elmDataTableBefore: elmDataTableBefore,
            dataTableObject: customDataTableInit,
            favoriteType: _options.favorite.type
        });
        asyncFavorite.init();
    }

    // 選択チェックボックスをチェックで、全選択/全解除
    (() => {
        const dataTableCheckAll = new DataTableCheckAll({
            dataTableObject: customDataTableInit,
        });
        dataTableCheckAll.init();
    })();
    //チェックで全オフ/全オン/反転のクラス
    (() => {
        const dataTableCheck = new DataTableCheck({
            dataTableObject: customDataTableInit,
        });
        dataTableCheck.init();
    })();

    return customDataTableInit;
};

// dataTableのお気に入り機能
// _parm.dataTable = viewで実行しているdataTableのオブジェクト
// _parm.favoriteType = int_favorite_type.php で定義している画面の種類
// window.CUSTOM_DATA_TABLE_FAVORITE = {};
// CUSTOM_DATA_TABLE_FAVORITE.init = function(_parm) {
//   window.addEventListener("DOMContentLoaded", () => {
//     const asyncFavorite = new AsyncFavorite({
//       elmDataTableBefore: _parm.elmDataTableBefore,
//       dataTableObject: _parm.dataTable,
//       favoriteType: _parm.favoriteType
//     });
//     asyncFavorite.init();
//   });
// }
// バリデーションの更新 FormValidation.js
// html側で動的に追加した選択肢ボタンに直接クリックイベントを追加する時に実行する関数
window.updateValidation = {};
updateValidation.addRow = function () {
    if (!document.querySelector("form")) return;
    const formValidation = new FormValidation();
    formValidation.init();
}

// セレクトボックス 検索機能付き SelectSearch.js
// html側で動的に追加した選択肢ボタンに直接クリックイベントを追加する時に実行する関数
window.selectSearch = {};
selectSearch.selectBtnClick = function (_this) {
    const elmSelectBtn = _this;
    const targetInputValue = document.querySelector(`input[name="${elmSelectBtn.getAttribute('data-js-selectsearch')}"]`);
    const targetInputVLabel = targetInputValue.previousElementSibling;
    const selectSearchFunc = new SelectSearch();
    selectSearchFunc.selectBtnClick(elmSelectBtn, targetInputVLabel, targetInputValue);
}


// セレクトボックス 検索機能付き SelectSearch.js
// html側で動的に追加した選択肢ボタンに直接クリックイベントを追加する時に実行する関数
window.LABEL_PRINT = function (_parm) {
    const objLabelPrint = new LabelPrint({
        labelModal: _parm.labelModal,
        inputUseReportId: _parm.inputUseReportId,
        inputUseReportListType: _parm.inputUseReportListType,
        inputCheckedRowIds: _parm.inputCheckedRowIds,
        labelPrint12Id: _parm.labelPrint12Id,
        radioInput: _parm.radioInput,
        objDataTable: _parm.objDataTable,
        labelPrint12ButtonId: _parm.labelPrint12ButtonId,
    });
    objLabelPrint.init();
}

window.REPORT_PRINT = function (_parm) {
    const objReportPrint = new ReportPrint({
        labelModal: _parm.labelModal,
        inputCheckedRowIds: _parm.inputCheckedRowIds,
        reportGroupId: _parm.reportGroupId,
        reportInfoUrl: _parm.reportInfoUrl,
        reportBtns: _parm.reportBtns,
        objDataTable: _parm.objDataTable,
        hasRowIdInput: _parm.hasRowIdInput,
        hasChecked: _parm.hasChecked,
    });
    objReportPrint.init();
}

window.REPORT_PRINT_CUSTOMER = function (_parm) {
    const objReportPrintCustomer = new ReportPrintCustomer({
        labelModal: _parm.labelModal,
        inputCheckedRowIds: _parm.inputCheckedRowIds,
        reportGroupId: _parm.reportGroupId,
        reportInfoUrl: _parm.reportInfoUrl,
        reportBtns: _parm.reportBtns,
        objDataTable: _parm.objDataTable,
        hasRowIdInput: _parm.hasRowIdInput,
        hasChecked: _parm.hasChecked,
        isOnePage: _parm.isOnePage,
        clickFunction: _parm.clickFunction,
    });
    objReportPrintCustomer.init();
}


const init = () => {

    /**
     * 共通パーツ
     */
    (commonFunctions => {
        // 非表示メニュー
        // 非表示メニュー（表の三点メニュー）
        (() => {
            if (!document.querySelector(".c-hide-menu") && !document.querySelector(".c-dropdown-menu")) return;
            const tableHideMenu = new HideMenu([
                {
                    _target: '.c-hide-menu',
                    toggleBtn: ".c-hide-menu",
                    menu: ".c-hide-menu__content",
                },
                {
                    _target: '.c-dropdown-menu',
                    toggleBtn: ".c-dropdown-menu__btn",
                    menu: ".c-dropdown-menu__content",
                }]);
            tableHideMenu.init();
        })();
        // モーダル
        (() => {
            if (!document.querySelector("[data-js-modal-content]")) return;
            const modal = new Modal({
                modalWrappersAttr: "data-js-modal-content"
            });
            modal.init();
        })();
        // タブ
        (() => {
            if (!document.querySelector("[data-js-tab-nav]")) return;
            const tab = new Tab();
            tab.init();
        })();
        // アラート
        (() => {
            if (!document.querySelector("[data-js-alert-content]")) return;
            const alert = new Alert();
            alert.init();
        })();
        // 文例集
        (() => {
            if (!document.querySelector("textarea:not(.__not-text-template)")) return;
            const asyncTextTemplate = new AsyncTextTemplate();
            asyncTextTemplate.init();
        })();
        // 文例集
        (() => {
            if (!document.querySelector("[data-js-copy-modal-open-btn]")) return;
            const asyncCopyModel = new AsyncCopyModel(
                {attrModalOpenBtn: "data-js-copy-modal-open-btn"}
            );
            asyncCopyModel.init();
        })();
        // select
        (() => {
            if (!document.querySelector("select[data-js-select-check]")) return;
            const selectCheck = new SelectCheck(
                {selectElem: "data-js-select-check"}
            );
            selectCheck.init();
        })();
    })();

    /**
     * フォーム系
     * `FormMethods`は`FormValidation`より先に実行する
     * `FormMethods.getChangeMtshrDate()`に影響する
     */
    (formFunctions => {
        // フォーム内の各機能
        (() => {
            if (!document.querySelector("form")) return;
            const formMethods = new FormMethods();
            formMethods.init();
        })();
        // バリデーション
        (() => {
            if (!document.querySelector("form")) return;
            const formValidation = new FormValidation();
            formValidation.init();
        })();
        // セレクトボックス選択で複数のinputを出力
        (() => {
            if (!document.querySelector(".c-tag-select")) return;
            const tagSelect = new TagSelect();
            tagSelect.init();
        })();
        // セレクトボックス選択でひとつのinputを出力
        (() => {
            if (!document.querySelector(".c-select-search")) return;
            const selectSearch = new SelectSearch();
            selectSearch.init();
        })();
        // 非同期で○○一覧を取得して全画面モーダルに出力
        (() => {
            if (!document.querySelector("[data-js-async-search-modal-form]")) return;
            const asyncGetSearchResult = new AsyncGetSearchResult();
            asyncGetSearchResult.init();
        })();
    })();

    /**
     * テーブル系
     */
    (tableFunctions => {
        // テーブル内の各機能
        (() => {
            if (!document.querySelector("table")) return;
            const tableMethods = new TableMethods();
            document.querySelectorAll("table").forEach(_table =>{
                _table.addEventListener('keydown', function (_event) {
                    if (_event.key == 'Enter'){
                        _event.preventDefault();
                        return false;
                    }
                });
            });
            tableMethods.init();
        })();
    })();

    /**
     * サイドバー
     */
    (sidebarFunctions => {
        // サイドバーメニューを検索 // `SearchSidebar`は現在未使用
        // (() => {
        //   if (!document.querySelector("#js-sidebar-nav")) return;
        //   const searchSidebar = new SearchSidebar();
        //   searchSidebar.init();
        // })();
        // メニュー階層を切り替え
        (() => {
            if (!document.querySelector("#js-sidebar")) return;
            const sidebarNav = new SidebarNav();
            sidebarNav.init();
        })();
        // サイドバー自身の開閉
        (() => {
            if (!document.querySelector("#js-sidebar")) return;
            const sidebarToggle = new SidebarToggle();
            sidebarToggle.init();
        })();
    })();

    /**
     * 各ページ
     */
    (pageContractDetailFunctions => {
        if (document.querySelector("#js-table-tab-chinryo-1_1")) {
            const chinryo_getugaku_contractDetail = new PageContractDetail({
                baseTable: '#js-table-tab-chinryo-1_1',
                addRowBtn: '#js-add-row-btn-chinryo-1_1',
                printTable: '#js-print-table-tab-chinryo-1_1',
                copyBtn: '#js-copy-btn-tab-chinryo-1_1',
                inputTotal: '#js-input-total-tab-chinryo-1_1',
                btnTotalCalc: '#js-btn-total-calc-chinryo-1_1',
                isMonthTabTable: true, // 月額タブを指す
            });
            chinryo_getugaku_contractDetail.init();
        }
        if (document.querySelector("#js-table-tab-chinryo-1_2")) {
            const chinryo_keiyaku_contractDetail = new PageContractDetail({
                baseTable: '#js-table-tab-chinryo-1_2',
                addRowBtn: '#js-add-row-btn-chinryo-1_2',
                printTable: '#js-print-table-tab-chinryo-1_2',
                copyBtn: '#js-copy-btn-tab-chinryo-1_2',
                inputTotal: '#js-input-total-tab-chinryo-1_2',
                btnTotalCalc: '#js-btn-total-calc-chinryo-1_2',
            });
            chinryo_keiyaku_contractDetail.init();
        }
        if (document.querySelector("#js-table-tab-chinryo-1_3")) {
            const chinryo_kousin_contractDetail = new PageContractDetail({
                baseTable: '#js-table-tab-chinryo-1_3',
                addRowBtn: '#js-add-row-btn-chinryo-1_3',
                printTable: '#js-print-table-tab-chinryo-1_3',
                copyBtn: '#js-copy-btn-tab-chinryo-1_3',
                inputTotal: '#js-input-total-tab-chinryo-1_3',
                btnTotalCalc: '#js-btn-total-calc-chinryo-1_3',
                updateTable: '#js-table-tab-chinryo-1_3_update_renewal_fee',
            });
            chinryo_kousin_contractDetail.init();
        }
        if (document.querySelector("#js-table-tab-chinryo-1_4")) {
            const chinryo_taikyo_contractDetail = new PageContractDetail({
                baseTable: '#js-table-tab-chinryo-1_4',
                addRowBtn: '#js-add-row-btn-chinryo-1_4',
                printTable: '#js-print-table-tab-chinryo-1_4',
                copyBtn: '#js-copy-btn-tab-chinryo-1_4',
                inputTotal: '#js-input-total-tab-chinryo-1_4',
                btnTotalCalc: '#js-btn-total-calc-chinryo-1_4',
            });
            chinryo_taikyo_contractDetail.init();
        }
        if (document.querySelector("#js-print-table-tab-chinryo-1_6-1")) {
            const chinryo_sonota_1_contractDetail = new PageContractDetail({
                baseTable: '#js-table-tab-chinryo-1_6-1',
                addRowBtn: '#js-add-row-btn-chinryo-1_6-1',
                printTable: '#js-print-table-tab-chinryo-1_6-1',
                copyBtn: '#js-copy-btn-tab-chinryo-1_6-1',
                inputTotal: false,
                btnTotalCalc: false,
            });
            chinryo_sonota_1_contractDetail.init();
        }
        if (document.querySelector("#js-print-table-tab-chinryo-1_6-2")) {
            const chinryo_sonota_2_contractDetail = new PageContractDetail({
                baseTable: '#js-table-tab-chinryo-1_6-2',
                addRowBtn: '#js-add-row-btn-chinryo-1_6-2',
                printTable: '#js-print-table-tab-chinryo-1_6-2',
                copyBtn: '#js-copy-btn-tab-chinryo-1_6-2',
                inputTotal: false,
                btnTotalCalc: false,
            });
            chinryo_sonota_2_contractDetail.init();
        }
    })();

}; // / init();

window.addEventListener("DOMContentLoaded", () => {
    polyfillClosest();
    init();
});

document.querySelectorAll("input").forEach(_input =>{
    _input.addEventListener('keydown', function (_event) {
        if (_event.key == 'Enter'){
            _event.preventDefault();
            return false;
        }
    });
});

//行を追加クリック時、新規追加行の検索モーダルを更新
window.addRowEventReload = function () {
    if (!document.querySelector("[data-js-async-search-modal-form]")) return;
    const asyncGetSearchResult = new AsyncGetSearchResult();
    Array.prototype.slice.call(document.querySelectorAll(`[${asyncGetSearchResult.attr.searchModalOpenBtn}]`)).forEach(_elmSearchModalOpenBtn => {
        asyncGetSearchResult.addEventToSearchModalOpenBtn(_elmSearchModalOpenBtn);
    });
};
// 画面クリック時、開いている三点メニューがあれば全て閉じる
document.addEventListener('click', (_ev) => {
    if (!_ev.target.closest('.c-hide-menu') && !_ev.target.closest('.c-dropdown-menu')) {
        const elmsOpenHideMenu = Array.prototype.slice.call(document.querySelectorAll('.c-hide-menu__content[data-active="true"], .c-dropdown-menu__content[data-active="true"]'));
        elmsOpenHideMenu.forEach(_elmOpenHideMenu => {
            _elmOpenHideMenu.setAttribute('data-active', 'false');
        });
    }
}, true)
