/**
 * PDF印刷に関するクラス
 * マニュアル：
 */

export default class ReportPrint {
    /**
     * @Property {Element} this.elmLabelModal PDF印刷のモーダル要素
     * @Property {Array} this.objDataTable dataTableオブジェクト
     */
    constructor(_parm) {
        this.elmLabelModal = document.querySelector(_parm.labelModal) || false;
        this.elmInputCheckedRowIds = document.querySelector(_parm.inputCheckedRowIds);
        this.reportGroupId = _parm.reportGroupId;
        this.reportInfoUrl = _parm.reportInfoUrl;
        this.objDataTable = _parm.objDataTable;
        this.elemReportBtns = document.querySelectorAll(_parm.reportBtns) || false;
        this.attr = {
            // 一覧の各行のidを持つinput（選択したラジオのvalueと、この属性値を紐付ける）
            hasRowIdInput_value: _parm.hasRowIdInput,
            hasChecked: _parm.hasChecked || false,
        }
    }

    /**
     * PDF印刷の ids の<input hidden>のvalueに値をセットする
     * @param {Array} _checkedRows 全てのチェック済みの行要素
     */
    setIds(_checkedRows) {

        // ラジオボタンがあれば選択済みの値とマッチするIDを取得
        const ids = _checkedRows.map(_elmCheckedRow => {
            return _elmCheckedRow.querySelector(`input[${this.attr.hasRowIdInput_value}]`).value;
        });
        this.elmInputCheckedRowIds.value = ids.join(',');
    }

    /**
     * チェック済みの一覧の行を取得
     */
    getCheckedRows() {
        return Array.prototype.slice.call(this.objDataTable.rows().nodes()).filter(_elmRow => {
            if (this.attr.hasChecked){
                return _elmRow.querySelector(`input[${this.attr.hasRowIdInput_value}]`).closest('td').querySelector('input[type="checkbox"]').checked;
            } else {
                return true;
            }
        });
    }

    /**
     * イベント付与
     */
    addEvent() {

        this.elemReportBtns.forEach(_elmOpenBtn => {
            _elmOpenBtn.addEventListener('click', () => {
                document.getElementById('report-info-message').hidden = true;
                document.getElementById('report-info').innerHTML = '';
                const report_ajax = new XMLHttpRequest();
                const report_ajax_params = this.formatParams({
                    report_group_id: this.reportGroupId
                });
                report_ajax.open('get', this.reportInfoUrl + '?' + report_ajax_params);
                report_ajax.send();
                report_ajax.onreadystatechange = function () {
                    if (report_ajax.readyState == 4 && report_ajax.status == 200) {
                        let data = JSON.parse(report_ajax.responseText);
                        let outHtml = '<div>';
                        if (data.length > 0) {
                            document.getElementById('report-info-message').hidden = false;
                        }
                        for (let i = 0; i < data.length; i++) {
                            outHtml += `<div><input type="radio" name="use_report_id" id="form_pdf_${i}" value="${data[i].int_use_report_id}" hasChecked="${data[i].bol_print_checked_rows ? '1': '0'}">`
                                + `<label for="form_pdf_${i}">${data[i].str_report_name}</label></div>`;
                        }
                        outHtml += '</div>';
                        document.getElementById('report-info').innerHTML = outHtml;
                    }
                }
            });
        });
        /**
         * フォーム送信時
         */
        this.elmLabelModal.querySelector('form').addEventListener('submit', (_ev) => {
            _ev.preventDefault();
            if (this.elmLabelModal.querySelector(`input[type=radio]:checked`) == null){
                return;
            }
            if (this.elmLabelModal.querySelector(`input[type=radio]:checked`).getAttribute('hasChecked') == '1') {
                this.attr.hasChecked = true;
            } else {
                this.attr.hasChecked = false;
            }
            // PDF印刷の ids の<input hidden>のvalueに値をセットする
            this.setIds(this.getCheckedRows());
            // 送信
            if (this.elmInputCheckedRowIds.value == '' ||
                (this.elmLabelModal.querySelector('[name="checkedIds"]') != null
                && this.elmLabelModal.querySelector('[name="checkedIds"]').value != ''
                && JSON.parse(this.elmLabelModal.querySelector('[name="checkedIds"]').value).length == 0)) {
                if (this.attr.hasChecked || this.elmLabelModal.querySelector('[name="checkedIds"]') != null){
                    alert('印刷の行をチェックしてください。');
                } else {
                    alert('印刷の行はないです。');
                }
            } else {
                _ev.target.submit();
            }
        });
    }

    init() {
        if(!this.elmLabelModal) return;
        this.addEvent();
    }

    formatParams(data) {
        const arr = [];
        for (let name in data) {
            arr.push(encodeURIComponent(name) + "=" + encodeURIComponent(data[name]));
        }
        arr.push(("v=" + Math.random()).replace(".", ""));
        return arr.join("&");
    }
}
