export default class FormValidation {
  constructor(_parm) {
    this.forms = Array.prototype.slice.call(document.querySelectorAll('form'))
    this.idErrorAlert = 'js-validate-error'
    this.classErrorMessage = '__error-message' // エラーメッセージのclass
    this.classInputStatusError = '__is-error' // エラー時のinputに付与するclass
    this.classInputStatusOk = '__is-ok' // OK時のinputに付与するclass
  }

  /**
   *  エラーチェック系メソッド
   * **********************************************************************
   */

  /**
   * エラーチェック（空かどうか）
   * （「空かどうか」だけは全部のinputで実行する）
   */
  checkUndefined(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-undefined')) return;
    if (!_elm.value || _elm.value === undefined) {
      if (_elm.tagName === 'SELECT') {
        this.crateErrorMessage(_elm, '値を選択してください')
      } else {
        if(_elm.tagName === 'INPUT' && _elm.getAttribute('type') === 'file'){
          this.crateErrorMessage(_elm, 'ファイルを選択してください')
        } else{
          this.crateErrorMessage(_elm, '値を入力してください')
        }
      }
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（いずれかが必須）
   */
  checkGroupUndefined(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-group-undefined')) return;
    const key = _elm.getAttribute('data-vjs-group-undefined')
    const elmErrorMessage = document.querySelector(`[data-vjs-group-undefined-error="${key}"]`)
    const elmGroups = Array.prototype.slice.call(document.querySelectorAll(`[data-vjs-group-undefined="${key}"]`))

    // チェック
    let is_undefined = elmGroups.every(_elm => _elm.value === '')
    if (is_undefined) {
      elmGroups.forEach(_elm => {
        _elm.classList.add(this.classInputStatusError)
      });

      const changeInsertMessage = elmErrorMessage.getAttribute('data-js-validate-error-message-change-insert') || false
      if (changeInsertMessage) {
        if(document.querySelector(changeInsertMessage).hasChildNodes()){
          // 既にエラーがあったら抜ける
          return true;
        }
      }

      // エラーメッセージ
      const createError = document.createElement('div')
      createError.classList.add(this.classErrorMessage)
      createError.textContent = elmErrorMessage.getAttribute('title')
      this.crateErrorMessage(elmErrorMessage, elmErrorMessage.getAttribute('title'))
    } else {
      return true
    }
  }
  /**
   * エラーチェック（半角数字のみかどうか）
   */
  checkNumber(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-number')) return;
    const pattern = /^[0-9]*$/
    if (!pattern.test(_elm.value)) {
      this.crateErrorMessage(_elm, '半角数字で入力してください')
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
    /**
     * エラーチェック（半角数字のみかつ、0以外かどうか）
     */
    checkNumberNotZero(_elm) {
        if (_elm.classList.contains(this.classInputStatusError)) return;
        if (!_elm.hasAttribute('data-vjs-type-number-not-zero')) return;
        if(_elm.value == "0") {
            this.crateErrorMessage(_elm, '0以外の半角数字で入力してください')
            _elm.classList.add(this.classInputStatusError)
        } else {
            return true
        }
    }
  /**
   * エラーチェック（半角数字＋小数点のみかどうか）
   */
  checkRealNumber(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-real-number')) return;
    if (_elm.value == "") return;
      // const pattern = /^[+-]?(?:\d+\.?\d{1,3}|\.\d+)$/
    const pattern = /^[+-]?(?:\d+\.?\d{0,3}|\.\d{0,3})$/
    if (!pattern.test(_elm.value)) {
      this.crateErrorMessage(_elm, '半角数字と小数点のみで入力してください（小数点以下3桁まで）')
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
    /**
     * エラーチェック（符号+半角数字のみかどうか）
     */
    checkSignNumber(_elm) {
        if (_elm.classList.contains(this.classInputStatusError)) return;
        if (!_elm.hasAttribute('data-vjs-type-sign-number')) return;
        if (_elm.value == "") return;
        // const pattern = /^[+-]?(?:\d+\.?\d{1,3}|\.\d+)$/
        const pattern = /^[+-]?[0-9]*$/
        if (!pattern.test(_elm.value)) {
            this.crateErrorMessage(_elm, '半角数字のみで入力してください')
            _elm.classList.add(this.classInputStatusError)
        } else {
            return true
        }
    }
  /**
   * エラーチェック（半角英数字のみかどうか）
   */
  checkNumberAndEnglish(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-number-english')) return;
    const pattern = /^[0-9a-zA-Z]*$/
    if (!pattern.test(_elm.value)) {
      this.crateErrorMessage(_elm, '半角英数字で入力してください')
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（半角英数字記号のみかどうか）
   */
  checkNumberAndEnglishAndSigne(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-number-english-signe')) return;
    const pattern = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/
    if (!pattern.test(_elm.value)) {
      this.crateErrorMessage(_elm, '半角英数字記号のみで入力してください')
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（郵便番号かどうか）
   */
  checkYuubin(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-yuubin')) return;
    if (!_elm.value) return;
    const pattern = /^\d{3}-\d{4}$/
    if (!pattern.test(_elm.value)) {
      this.crateErrorMessage(_elm, '郵便番号の形式（xxx-xxxx）で入力してください')
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（メールアドレスかどうか）
   */
  checkMail(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-mail')) return;
    if (!_elm.value) return;
    const pattern = /[\w.\-]+@[\w\-]+\.[\w.\-]+/
    if (!pattern.test(_elm.value)) {
      this.crateErrorMessage(_elm, 'メールアドレスの形式（xxx@yyyy.zzz）で入力してください')
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（日付かどうか）
   */
  checkDate(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-date')) return;
    if (!_elm.value) return;
    const pattern = /\d{4}[\/-]\d{1,2}[\/-]\d{1,2}/
    if (!pattern.test(_elm.value)) {
      this.crateErrorMessage(_elm, '日付の形式（yyyy/mm/dd）で入力してください')
      _elm.classList.add(this.classInputStatusError)
    }else if (isNaN(Date.parse(_elm.value))) {
      this.crateErrorMessage(_elm, '日付の形式（yyyy/mm/dd）で入力してください')
      _elm.classList.add(this.classInputStatusError)
    } else {
        if (_elm.hasAttribute('data-vjs-type-date-min')) {
            const valueMin = _elm.getAttribute('data-vjs-type-date-min')
            if(!isNaN(Date.parse(valueMin))){
                console.log(valueMin)
                console.log(Date.parse(valueMin))
                console.log(_elm.value)
                console.log(Date.parse(_elm.value))
                if(Date.parse(valueMin) > Date.parse(_elm.value)){
                    if(_elm.hasAttribute('data-vjs-type-date-msg')){
                        this.crateErrorMessage(_elm, _elm.getAttribute('data-vjs-type-date-msg'))
                    }else{
                        this.crateErrorMessage(_elm, valueMin + '以降の日付で入力してください。')
                    }
                    _elm.classList.add(this.classInputStatusError)
                }
            }
        }
        if (_elm.hasAttribute('data-vjs-type-date-max')) {
            const valueMax = _elm.getAttribute('data-vjs-type-date-max')
            if(!isNaN(Date.parse(valueMax))){
                if(Date.parse(valueMax) < Date.parse(_elm.value)){
                    if(_elm.hasAttribute('data-vjs-type-date-msg')){
                        this.crateErrorMessage(_elm, _elm.getAttribute('data-vjs-type-date-msg'))
                    }else{
                        this.crateErrorMessage(_elm, valueMax + '以前の日付で入力してください。')
                    }
                    _elm.classList.add(this.classInputStatusError)
                }
            }
        }
      return true
    }
  }
    /**
     * エラーチェック（時間かどうか）
     */
    checkTime(_elm) {
        if (_elm.classList.contains(this.classInputStatusError)) return;
        if (!_elm.hasAttribute('data-vjs-type-time')) return;
        if (!_elm.value) return;
        const pattern = /\d{1,2}[:]\d{1,2}/
        if (!pattern.test(_elm.value)) {
            this.crateErrorMessage(_elm, '時間の形式（hh:mm）で入力してください')
            _elm.classList.add(this.classInputStatusError)
            return
        } else {
            let wk = _elm.value.split(':')
            if (wk.length != 2){
                this.crateErrorMessage(_elm, '時間の形式（hh:mm）で入力してください')
                _elm.classList.add(this.classInputStatusError)
                return
            }
            if (0 > wk[0] && wk[0] > 23){
                this.crateErrorMessage(_elm, '時間の形式（hh:mm）で入力してください')
                _elm.classList.add(this.classInputStatusError)
                return
            }
            if (0 > wk[1] && wk[1] > 59){
                this.crateErrorMessage(_elm, '時間の形式（hh:mm）で入力してください')
                _elm.classList.add(this.classInputStatusError)
                return
            }
            return true
        }
    }
  /**
   * エラーチェック（○文字以上かどうか）
   */
  checkLengthMin(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-length-min')) return;
    if (!_elm.value) return;
    const textLength = Number(_elm.getAttribute('data-vjs-type-length-min'))
    if (_elm.value.length < textLength) {
      this.crateErrorMessage(_elm, `${textLength}文字以上で入力してください`)
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（○文字以下かどうか）
   */
  checkLengthMax(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-length-max')) return;
    if (!_elm.value) return;
    const textLength = Number(_elm.getAttribute('data-vjs-type-length-max'))
    if (_elm.value.length > textLength) {
      this.crateErrorMessage(_elm, `${textLength}文字以下で入力してください`)
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（○文字ちょうどかどうか）
   */
  checkLengthJust(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-length-just')) return;
    if (!_elm.value) return;
    const textLength = Number(_elm.getAttribute('data-vjs-type-length-just'))
    if (_elm.value.length !== textLength) {
      this.crateErrorMessage(_elm, `${textLength}文字で入力してください`)
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（○○がチェックの場合、xxを必須（not undefined）にする）
   */
  checkIsCheckedNotUndefined(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-check-is-not-undefined')) return;
    // チェックなら`true`が返る
    const getIsChecked = () => {
      const key = _elm.getAttribute('data-vjs-type-check-is-not-undefined')
      const elmKeyInput = document.querySelector(`[data-vjs-type-check-is-not-undefined-key="${key}"]`)
        let opt_selected = false
        if(elmKeyInput.tagName == 'OPTION'){
            Array.prototype.slice.call(document.querySelectorAll(`[data-vjs-type-check-is-not-undefined-key="${key}"]`)).some(__elm => {
                if (__elm.selected){
                    opt_selected=true
                    return true
                }
            })
        }
      return elmKeyInput.checked || opt_selected ? true : false;
    }
    if (getIsChecked() && (!_elm.value || _elm.value === undefined)) {
      if (_elm.tagName === 'SELECT') {
        this.crateErrorMessage(_elm, '値を選択してください')
      } else {
        if(_elm.tagName === 'INPUT' && _elm.getAttribute('type') === 'file'){
          this.crateErrorMessage(_elm, 'ファイルを選択してください')
        } else{
          this.crateErrorMessage(_elm, '値を入力してください')
        }
      }
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（任意の要素と同じ値かどうか → パスワード再入力とか）
   */
  checkEqualValue(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-equal')) return;
    // if (!_elm.value) return;
    const valueCompare = document.querySelector(_elm.getAttribute('data-vjs-equal')).value
    if (_elm.value !== valueCompare) {
      this.crateErrorMessage(_elm, '同じ値を入力してください')
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（パスワード用）
   */
  checkPassword(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-password')) return;
    if (!_elm.value) return;
    const patterns = [
      [/[a-z]/, '半角英小文字'],
      [/[A-Z]/, '半角英大文字'],
      [/[0-9]/, '半角数字']
    ]
    let errorMessages = []
    for (let i = 0; i < patterns.length; i++) {
      const _pattern = patterns[i][0]
      const _errorMessage = patterns[i][1]
      if (!_pattern.test(_elm.value)) {
        errorMessages.push(_errorMessage)
      }
    }
    if (errorMessages.length) {
      const resultErrorMessage = errorMessages.reduce((_target, _result) => {
        return _target + '・' + _result;
      })
      this.crateErrorMessage(_elm, `${resultErrorMessage}をパスワードに含めてください`)
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（全角ひらがなのみかどうか）
   */
  checkZenkakuHiragana(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-zenkaku-hiragana')) return;
    const pattern = /^[ぁ-ん|ー]*$/
    if (!pattern.test(_elm.value)) {
      this.crateErrorMessage(_elm, '全角ひらがなで入力してください')
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（全角カタカナのみかどうか）
   */
  checkZenkakuKatakana(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-zenkaku-katakana')) return;
    const pattern = /^[ァ-ヺヽヾ゛゜ゝゞァ-ヺ１-９０Ａ-Ｚ￥「」（）－，．　／〔\/［\.\s]+$/
    if (!pattern.test(_elm.value)) {
      this.crateErrorMessage(_elm, '全角カタカナで入力してください')
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（環境依存文字にマッチするかどうか）
   */
  checkIzonText(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-izon-text')) return;
    if (!_elm.value) return;
    const izonText = '№㏍℡㊤㊥㊦㊧㊨㈱㈲㈹㍾㍽㍼㍻①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳ⅠⅡⅢⅣⅤ≡∑∫∮√⊥∠∟⊿∵∩∪㍉㎜㎝㎞㎎㎏㏄㍉㌔㌢㍍㌘㌧㌃㌶㍑㍗㌍㌦㌣㌫㍊㌻纊鍈蓜炻棈兊夋奛奣寬﨑嵂ⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩ';
    const pattern = new RegExp(izonText.split('').join('|'), 'g')
    const match = _elm.value.match(pattern);
    if (match) {
      this.crateErrorMessage(_elm, `使用できない文字が含まれています（${match.join(',')}）`)
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }
  /**
   * エラーチェック（正規表現にマッチするかどうか）
   */
  checkPattern(_elm) {
    if (_elm.classList.contains(this.classInputStatusError)) return;
    if (!_elm.hasAttribute('data-vjs-type-pattern')) return;
    if (!_elm.value) return;
    const pattern = new RegExp(_elm.getAttribute('data-vjs-type-pattern'))
    if (!_elm.value.match(pattern)) {
      this.crateErrorMessage(_elm, `入力した値の形式が正しくありません`)
      _elm.classList.add(this.classInputStatusError)
    } else {
      return true
    }
  }

  /**
   *  その他のメソッド
   * **********************************************************************
   */

  /**
   * エラーメッセージを表示
   */
  crateErrorMessage(_elmInput, _message) {
    const changeInsertMessage = _elmInput.getAttribute('data-js-validate-error-message-change-insert') || false
    const elmErrorMessage = document.createElement('div')
    elmErrorMessage.classList.add(this.classErrorMessage)
    elmErrorMessage.textContent = _message
    if (!changeInsertMessage) { // 通常時
      _elmInput.parentNode.insertBefore(elmErrorMessage, _elmInput.nextSibling)
    } else { // エラーメッセージ出現位置を変更する場合
      document.querySelector(changeInsertMessage).appendChild(elmErrorMessage)
    }
  }

  /**
   * バリデート実行前にエラー関係をリセット
   */
  resetError(_form) {
    // 全エラーメッセージを最初に削除
    Array.prototype.slice.call(_form.querySelectorAll(`.${this.classErrorMessage}`)).forEach(_elm => {
      _elm.parentNode.removeChild(_elm)
    })
    // 全inputのエラーclassを最初に削除
    Array.prototype.slice.call(_form.querySelectorAll(`.${this.classInputStatusError}`)).forEach(_elm => {
      _elm.classList.remove(this.classInputStatusOk)
      _elm.classList.remove(this.classInputStatusError)
    })
  }

  /**
   * タブにエラー数を反映
   */
  setTabErrorCount(_form) {
    const tabNavAttr = 'data-js-tab-nav'
    const tabContentAttr = 'data-js-tab-content'
    const tabNavErrorCountClass = '__error-count'
    const elmTabNavItems = Array.prototype.slice.call(_form.querySelectorAll(`[${tabNavAttr}]`))
    elmTabNavItems.forEach(_elmTabNavItem => {
      const tabNavValue = _elmTabNavItem.getAttribute(tabNavAttr)
      const elmTabContent = _form.querySelector(`[${tabContentAttr}=${tabNavValue}]`)
      const errorCount = Number(elmTabContent.querySelectorAll(`.${this.classInputStatusError}`).length)
      const crateElmTabErrorCount = _errorCount => {
        const elm = document.createElement('span')
        elm.classList.add(tabNavErrorCountClass)
        elm.textContent = _errorCount
        return elm;
      }
      // タブのエラー数要素を最初に削除
      const elmTabNavErrorCount = _elmTabNavItem.querySelector(`.${tabNavErrorCountClass}`) || false
      if (elmTabNavErrorCount) {
        elmTabNavErrorCount.parentNode.removeChild(elmTabNavErrorCount)
      }
      // 次にエラー数を反映（こうしないと、どんどん要素が増えてしまう）
      if (errorCount > 0) {
        _elmTabNavItem.querySelector('a').appendChild(crateElmTabErrorCount(errorCount))
      }
    })
  }

  /**
   * エラー時のアラート要素を作成
   */
  crateErrorAlert() {
    const crateElm = `
      <div class="c-alert--warning" id="${this.idErrorAlert}" data-js-alert-content="js-validate-error" data-active="false">
        <div class="c-alert__inner">
          <p class="c-alert__text">入力内容に不備があります</p>
          <a href="javascript:void(0);" class="c-alert__close-btn" data-js-alert-close-btn="js-validate-error"></a>
        </div>
      </div>
    `;
    document.querySelector('body').insertAdjacentHTML('beforeend', crateElm)
  }

  /**
   *  イベントを設定
   * **********************************************************************
   */

  addEvent() {
    this.forms.forEach(_form => { // formの数だけ繰り返し

      // 2回目以降は入力時にもバリデーションさせるためのフラグ変数
      let isChallenged = false

      // セレクトボックスを取得
      const elmSelects = Array.prototype.slice.call(_form.querySelectorAll('select[aria-required]'))

      // チェックボックス、ラジオボタン以外を取得
      const elmInputTexts = Array.prototype.slice.call(_form.querySelectorAll('input[aria-required]:not([type="checkbox"]):not([type="radio"]), textarea[aria-required]'))

      // チェックボックス、ラジオボタンを取得
      const getAttrNamesCheckAndRadios = () => {
        const elmInputCheckAndRadios = Array.prototype.slice.call(_form.querySelectorAll('input[type="checkbox"][aria-required], input[type="radio"][aria-required]'))
        let attrNamesCheckAndRadios = elmInputCheckAndRadios.map(function (_elm) {
          return _elm.getAttribute('name')
        })
        return Array.prototype.slice.call(new Set(attrNamesCheckAndRadios)) // 重複削除
      }

      // バリデーション対象の要素がなかったらreturn
      if (!elmSelects.length && !elmInputTexts.length && !getAttrNamesCheckAndRadios().length) return;

      // バリデートチェック〜エラーメッセージ表示までの関数（チェックボックス、ラジオボタン以外）
      const doValidate_typeText = (_elmInput) => {
        this.checkUndefined(_elmInput)
        this.checkGroupUndefined(_elmInput)
        this.checkNumber(_elmInput)
        // this.checkNumberNotZero(_elmInput)
        this.checkRealNumber(_elmInput)
        this.checkNumberAndEnglish(_elmInput)
        this.checkNumberAndEnglishAndSigne(_elmInput)
        this.checkYuubin(_elmInput)
        this.checkMail(_elmInput)
        this.checkDate(_elmInput)
        this.checkTime(_elmInput)
        this.checkLengthMin(_elmInput)
        this.checkLengthMax(_elmInput)
        this.checkLengthJust(_elmInput)
        this.checkIsCheckedNotUndefined(_elmInput)
        this.checkEqualValue(_elmInput)
        this.checkPassword(_elmInput)
        this.checkZenkakuHiragana(_elmInput)
        this.checkZenkakuKatakana(_elmInput)
        this.checkIzonText(_elmInput)
        this.checkPattern(_elmInput)
      }

      // OK, Errorクラスを削除
      const removeStatusClass = (_elm) => {
        _elm.classList.remove(this.classInputStatusOk)
        _elm.classList.remove(this.classInputStatusError)
      }

      // バリデートチェックしてOKならクラス付与
      const setOkClass = (_elmInput) => {
        if (!_elmInput.classList.contains(this.classInputStatusError) && _elmInput.value) {
          _elmInput.classList.add(this.classInputStatusOk)
        }
      }

      // ********************************************************************************
      //  送信ボタン実行時
      // ********************************************************************************
      // const eventSet = (_ev) => {
      //   console.log(_form)
      //   _form.off('submit')
      //   _form.on('submit', function(_ev){
      _form.addEventListener('submit', (_ev) => {
        _ev.preventDefault();
        this.resetError(_form);
        isChallenged = true;

        // セレクトボックスをチェック
        elmSelects.forEach(_elmSelect => {
          doValidate_typeText(_elmSelect)
          setOkClass(_elmSelect)
        })
        // チェックボックス、ラジオボタン以外をチェック
        elmInputTexts.forEach(_elmInputText => {
          doValidate_typeText(_elmInputText)
          setOkClass(_elmInputText)
        })
        // チェックボックス、ラジオボタンをチェック
        getAttrNamesCheckAndRadios().forEach(_attrNameCheckAndRadio => {
          const elmTargets = Array.prototype.slice.call(document.querySelectorAll(`input[name="${_attrNameCheckAndRadio}"]`))
          let flagChecked = false
          for (let i = 0; i < elmTargets.length; i++) {
            const elmTarget = elmTargets[i]
            if (elmTarget.checked) {
              flagChecked = true
              break;
            }
          }
          // ひとつもチェックされていなかったらエラーにする
          // エラーメッセージの表示箇所は、グループ1つ目の要素を参照している
          // （'data-js-validate-error-message-change-insert'をグループ1つ目のinputに付与すること）
          if (!flagChecked) {
            this.crateErrorMessage(elmTargets[0], '値を選択してください')
            elmTargets[0].classList.add(this.classInputStatusError)
          }
        })

        // エラーが0ならフォーム送信
        const errorInputElms = Array.prototype.slice.call(_form.querySelectorAll(`.${this.classInputStatusError}`))
        const errorCount = errorInputElms.length
        if (errorCount === 0) {
          console.log('OK!')
          _form.submit()
        } else {
          console.log('Error 【' + errorCount + '】')
          // 1つ目のエラー箇所に移動（スクロール）させる
          errorInputElms[0].focus()
          // アラート表示
          document.querySelector(`#${this.idErrorAlert}`).setAttribute('data-active', 'true')
          setTimeout(() => {
            document.querySelector(`#${this.idErrorAlert}`).setAttribute('data-active', 'false')
          }, 4000);
          // タブにエラー数を反映
          this.setTabErrorCount(_form)
        }
        console.log(_ev)
        _ev.stopImmediatePropagation()
          // イベント伝搬の停止
          _ev.stopPropagation();
          // イベントキャンセル
          _ev.preventDefault();
          console.log('stopPropagation preventDefault')
      })
      // console.log("event listener set")
      // _form.removeEventListener('submit', eventSet)
      // _form.addEventListener('submit', eventSet)

      // ********************************************************************************
      //  セレクトボックスで値を選択したら
      // ********************************************************************************
      elmSelects.forEach(_elmSelect => {
        _elmSelect.addEventListener('change', (_ev) => {
          // エラーclassリセット
          removeStatusClass(_elmSelect)
          // エラーメッセージリセット
          const changeInsertMessage = _elmSelect.getAttribute('data-js-validate-error-message-change-insert') || false
          if (!changeInsertMessage) { // 通常時
            const errorMessage = _elmSelect.nextSibling || false
            if (errorMessage) {
              _elmSelect.nextSibling.parentNode.removeChild(_elmSelect.nextSibling)
            }
          } else { // エラーメッセージ出現位置を変更している場合
            const errorMessage = document.querySelector(`${changeInsertMessage} .${this.classErrorMessage}`) || false
            if (errorMessage) {
              errorMessage.parentNode.removeChild(errorMessage)
            }
          }
          // バリデーション実行
          doValidate_typeText(_elmSelect)
          // タブにエラー数を反映
          this.setTabErrorCount(_form)
          // OKクラスを付与
          setOkClass(_elmSelect)
        })
      })

      // ********************************************************************************
      //  フォーカス外れたら
      // ********************************************************************************
      /**
       * チェックボックス、ラジオボタン以外をチェック
       */
      const funcNormalInputValidate = (_elmInputText) => {
        // if (!isChallenged) return;
        // エラーclassリセット
        removeStatusClass(_elmInputText)
        // エラーメッセージリセット
        const changeInsertMessage = _elmInputText.getAttribute('data-js-validate-error-message-change-insert') || false
        if (!changeInsertMessage) { // 通常時
          const errorMessage = _elmInputText.nextSibling || false
          if (errorMessage) {
            _elmInputText.nextSibling.parentNode.removeChild(_elmInputText.nextSibling)
          }
        } else { // エラーメッセージ出現位置を変更している場合
          const errorMessage = document.querySelector(`${changeInsertMessage} .${this.classErrorMessage}`) || false
          if (errorMessage) {
            errorMessage.parentNode.removeChild(errorMessage)
          }
        }
        // バリデーション実行
        doValidate_typeText(_elmInputText)
        // タブにエラー数を反映
        this.setTabErrorCount(_form)
        // OKクラスを付与
        setOkClass(_elmInputText)
      };
      elmInputTexts.forEach(_elmInputText => {
        _elmInputText.addEventListener('blur', (_ev) => {
          if (_elmInputText.hasAttribute('data-js-date')) {
            // dataPickerで値を書き換えして少しだけ待ってバリデーションを実行
            setTimeout(() => {
              funcNormalInputValidate(_elmInputText)
            }, 500);
          } else {
            funcNormalInputValidate(_elmInputText)
          }
        })
      })
      /**
       * チェックボックス、ラジオボタンをチェック
       */
      getAttrNamesCheckAndRadios().forEach(_attrNameCheckAndRadio => {
        const elmTargets = Array.prototype.slice.call(_form.querySelectorAll(`input[name="${_attrNameCheckAndRadio}"]`))
        elmTargets.forEach(_elmTarget => {
          _elmTarget.addEventListener('input', (_ev) => {
            // if (!isChallenged) return;
            // エラーclassリセット
            removeStatusClass(elmTargets[0])
            // エラーメッセージリセット
            const errorMessage = _form.querySelector(elmTargets[0].getAttribute('data-js-validate-error-message-change-insert')).querySelector(`.${this.classErrorMessage}`) || false
            if (errorMessage) {
              errorMessage.parentNode.removeChild(errorMessage)
            }
            // チェックして一つも選択していなかったらエラー処理
            let flagChecked = false
            for (let i = 0; i < elmTargets.length; i++) {
              const elmTarget = elmTargets[i]
              if (elmTarget.checked) {
                flagChecked = true
                break;
              }
            }
            if (!flagChecked) {
              this.crateErrorMessage(elmTargets[0], '値を選択してください')
              elmTargets[0].classList.add(this.classInputStatusError)
            }
            // タブにエラー数を反映
            this.setTabErrorCount(_form)
          })
        })
      })

    })
  }

  init() {
    this.addEvent()
    this.crateErrorAlert()
  }
}
