export default class SidebarToggle {
  constructor(_parm) {
    this.elmSidebar = document.querySelector('#js-sidebar');
    this.elmToggleBtn = document.querySelector('#js-sidebar-toggle-btn');
    this.bodyCollapseClass = '__is-collapse';
    this.localStorageKeyCollapseFlag = 'collapseFlag';
  }
  toggleBodyClass() {
    document.querySelector('body').classList.toggle(this.bodyCollapseClass);
  }
  removeBodyClass() {
    document.querySelector('body').classList.remove(this.bodyCollapseClass);
  }
  addBodyClass() {
    document.querySelector('body').classList.add(this.bodyCollapseClass);
  }
  getSidebarIsCollapse() {
    if (document.querySelector('body').classList.contains(this.bodyCollapseClass)) {
      return true;
    } else {
      return false;
    }
  }
  setLocalStorageCollapseFlag(_which) {
    localStorage.setItem(this.localStorageKeyCollapseFlag, _which);
  }
  getLocalStorageCollapseFlag() {
    return localStorage.getItem(this.localStorageKeyCollapseFlag);
  }
  addEvent() {
    let flagBodyHasCollapseClass = false;
    this.elmToggleBtn.addEventListener('click', (_ev) => {
      this.toggleBodyClass();
      // setTimeout(() => {
      //   window.resizeBy(-100, 0);
      // }, 300);
      if (this.getSidebarIsCollapse()) {
        this.setLocalStorageCollapseFlag('true');
      } else {
        this.setLocalStorageCollapseFlag('false');
      }
    });
    this.elmSidebar.addEventListener('click', (_ev) => {
      if (this.getSidebarIsCollapse()) {
        this.toggleBodyClass();
        this.setLocalStorageCollapseFlag('true');
      }
    });
  }
  init() {
    // const initToggleSidebar = () => {
    //   if (this.getLocalStorageCollapseFlag() === 'true') {
    //     this.addBodyClass();
    //   } else if (this.getLocalStorageCollapseFlag() === 'false') {
    //     this.removeBodyClass();
    //   }
    // }
    /**
     * <body>の直後に以下を実行している
     * こうすればページ読み込み時のCSSアニメーションは見えない
     * 上記の`initToggleSidebar()`だとアニメーションが見えてしまう
     * <body>のほうと`initToggleSidebar()`は若干記述が違うが処理内容は同じ
     * <script>
        (function(){
          var lscf = localStorage.getItem('collapseFlag');
          if (lscf === 'true') {
            document.querySelector('body').classList.add('__is-collapse');
          } else if (lscf === 'false') {
            document.querySelector('body').classList.remove('__is-collapse');
          }
        })();
      </script>
     */
    this.addEvent();
  }
}
