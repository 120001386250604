/**
 * セレクトボックスの選択済みの項目は選択できないようにする（tableの各行のセレクトボックスで使う）
 * @param {Eelements} _selectElems 各行のセレクトボックス要素をquerySelectorAllで渡す
 */
export const setDisabledSelectedOption = (_selectElems) => {
  // 各行のselectを配列に変換
  const arraySelect = Array.prototype.slice.call(_selectElems);
  // 選択済みのselectのoptionのインデックスを配列で取得する関数定義
  const getArraySelectedValue = () => arraySelect.map(_elmSelect => _elmSelect.value);
  // selectの数だけ実行
    arraySelect.forEach(_elmSelect => {
    // 最初にdisabled全部解除
    Array.prototype.slice.call(_elmSelect.options).forEach(_elmOption => {
      _elmOption.disabled = false;
    });
    // 選択済みならdisabledにする
    getArraySelectedValue(_elmSelect).forEach(_selectedOptionValue => {
      if(_selectedOptionValue === 0) return;
      if (_elmSelect.value != _selectedOptionValue){
          Array.prototype.slice.call(_elmSelect.options).forEach(_elmOption => {
              if (_elmOption.value == _selectedOptionValue){
                  _elmOption.disabled = true;
              }
          });
      }
    });
  });
}
