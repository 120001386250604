/**
 * DataTables（jQueryプラグイン）を使い回すためのクラス
 * https://datatables.net/
 *
 * 使用するベージでは以下順番で読み込ませる
 * 1. jQuery本体
 * 2. webpack.js
 * 3. _index.jsに記述している自作ライブラリ`CUSTOM_DATA_TABLE`
 *    自作ライブラリを実行すると、このファイルの`this.init()`が実行される
 *    実行時のパラメータが全ページで固定、変動と分かれるので自作ライブラリを作成している
 *    実行例 1 - オプションを指定する場合
 *    CUSTOM_DATA_TABLE.init('#js-datatable', {
        searchColNums: [0, 3],
        selectorSearchInput: "#js-search-table",
        order: [2, 'asc'],
      });
 *    実行例 2 - オプションを指定しない場合
 *    CUSTOM_DATA_TABLE.init('#js-datatable');
 */

export default class CustomDataTable {
    constructor(_parm) {
        // 実行時の引数（パラメータ）は、`this.init()`の最初で変数に格納している
        this.parm = _parm;
        this.elmToggleCheckBtn = document.querySelector('#js-toggle-check-btn') || false;
        this.elmToggleCheckStatusAttr = 'data-js-status';
        this.elmToggleFilterNameAttr = 'data-js-filter';
    }

    /**
     * チェックされたのみ表示/全表示
     */
    toggleFilter(_table) {
        let star_filter_flag = false;
        if ('true' == $(`[${this.elmToggleFilterNameAttr}='star']`).attr(this.elmToggleCheckStatusAttr)) {
            star_filter_flag = true;
        }
        let check_filter_flag = false;
        if ('true' == $(`[${this.elmToggleFilterNameAttr}='check']`).attr(this.elmToggleCheckStatusAttr)) {
            check_filter_flag = true;
        }
        if (star_filter_flag || check_filter_flag) {
            $.fn.dataTable.ext.afnFiltering.push(function (oSettings, aData, iDataIndex) {
                if (oSettings.nTable.id == _table.table().node().id) {
                    let flag = false;
                    if (star_filter_flag) {
                        _table.rows(iDataIndex).nodes().map(_elmRow => {
                            if (_elmRow.querySelector('input[type="checkbox"]:is(.__star)').checked) {
                                flag = true;
                            }
                        });
                    }
                    if (check_filter_flag) {
                        _table.rows(iDataIndex).nodes().map(_elmRow => {
                            if (_elmRow.querySelector('input[type="checkbox"]:not(.__star)').checked) {
                                flag = true;
                            }
                        });
                    }
                    return flag;
                 } else {
                   return true;
                 }
            });
        } else {
            $.fn.dataTable.ext.afnFiltering.pop();
        }
    }

    init() {
        /**
         * `constructor`の`this`を使うと、jQueryの`this`を指してしまうから、最初に変数に格納
         * selectorTable {string} - 対象となる<table>のid - ex. #hoge
         * searchColNums {number - Array} - 検索ボックスの検索対象の列番号
         * selectorSearchInput {string} - 検索ボックスののid
         * order {number, string - Array} - デフォルトの並び順
         * hidePager {boolean} - ページャーと表示件数の表示可否
         * defaultDisplayLength {number} - 表示件数の初期値
         * orderIdColumn {number} - 並び替え用のIDをセットしたカラムを指定
         */
        var options = {
            selectorTable: this.parm._selectorTable,
            searchColNums: this.parm._searchColNums,
            selectorSearchInput: this.parm._selectorSearchInput,
            order: this.parm._order,
            hidePager: this.parm._hidePager,
            defaultDisplayLength: this.parm._defaultDisplayLength,
            orderIdColumn: this.parm._orderIdColumn,
            processing : this.parm._processing,
            columns : this.parm._columns,
            columnDefs : this.parm.columnDefs,
            ajax : this.parm._ajax,
            drawCallback : this.parm._drawCallback,
            rowCallback : this.parm._rowCallback,
            serverSide : this.parm._serverSide,
            fixedColumns : this.parm._fixedColumns,
        };

        var table;

        /**
         * dataTableにあらかじめ用意されているメソッド（拡張機能のイメージ）
         * 独自の検索ボックスを作成する
         * `$.fn.dataTable.ext.search.push()`にtrueを返すと行が表示、falseを返すと行が非表示になる
         */
        if (options.selectorSearchInput) {
            $.fn.dataTable.ext.search.push(function (_settings, _data, _dataIndex) {
                var $searchInput = $(options.selectorSearchInput);
                var inputValue = $searchInput.val() ? $searchInput.val().replaceAll('　', ' ').trimRight().split(' ') : '';
                // 入力値が空なら全件数表示
                if (inputValue === '') return true;
                var flag = [];
                // オプションで渡された列の数だけ検索する
                for (let i = 0; i < options.searchColNums.length; i++) {
                    let colValue = _data[options.searchColNums[i]];
                    // let base = "";
                    console.log("check start:"+i)
                    for(let j = 0; j < inputValue.length; j++) {
                        if (inputValue[j] != ""){
                            let regexp = new RegExp(inputValue[j] + '(.*?)', 'i');
                            if (colValue.match(regexp)) {
                                flag[j] = true;
                            }
                        }
                    }
                }

                for (let i = 0; i < flag.length; i++) {
                    if (flag[i] == true){
                    }else{
                        return false;
                    }
                }
                if (flag.length ==inputValue.length){
                    console.log("ok")
                    return true;
                }
                return false;
            });
        }

        /**
         * DataTable(options)を実行
         * optionsにパラメータを渡している
         * └ 全ページ固定のoption値は、このファイルで指定
         * └ ページにより変わる可能性があるoption値は、自作ライブラリ`CUSTOM_DATA_TABLE`実行時に指定する
         */

        /**
         * ソートの矢印のため（CSS）に、`thead > th`の中身を<span>で囲む
         */
        $(options.selectorTable + ' thead th').each(function (_index, _elm) {
            var childHtml = $(this).html()
            var addChildSpan = '<span>' + childHtml + '</span>'
            $(this).html(addChildSpan)
        });

        /*
         * 対象のtableを指定して`DataTable()`を実行
         */
        var opts = {
            // 動的に出力される要素の出現位置を変更
            // t=table, p=pager, i=x件中yからzまで表示, l=表示件数セレクトボックス, f=検索ボックス
            // 公式ドキュメント：https://datatables.net/examples/basic_init/dom.html
            dom: options.hidePager
                ? '<"c-js-datatable"<"c-js-datatable__table"<"c-js-datatable__processing"r>t>>'
                : '<"c-js-datatable"<"c-js-datatable__table"<"c-js-datatable__processing"r>t><"c-js-datatable__footer c-card__inner--col"<"c-js-datatable__pager"pi><"c-js-datatable__select-box"l>>>',

            // デフォルトの並び順を変更（初期値 => 1列目昇順）
            // ex)「1行目昇順 + 2行目降順」 => [ [ 0, 'asc' ], [ 1, 'desc'] ]
            // ex)「並び替えをしない」 => []
            order: options.order, // 昇順 = 'asc', 降順 = 'desc'

            // 表示件数のセレクトボックスの値
            // ※ 10を入れないと`stateSave: true`でセレクトボックスの初期値が空になってしまう
            // lengthMenu: [10, 30, 50, 100],
            lengthMenu: [50, 100, 300, 500, 1000],

            // 表示件数の初期値
            displayLength: options.defaultDisplayLength,

            // 非表示にするカラムを指定
            // 今回は並び替え用のIDをセットしたカラムを指定する（任意）
            columnDefs: [
                {targets: options.orderIdColumn, visible: false}
            ],

            // ヘッダー固定の縦スクロールを可能にする
            scrollY: '55vh',
            // 横スクロールを可能にする
            scrollX: true,

            // scrollY の値で高さを固定したくないなら true
            scrollCollapse: true,

            // 列幅自動計算
            autoWidth: false,

            // 出力される文言を変更
            language: {
                sProcessing: '<div><div></div><div></div><div></div><div></div></div>',
                sInfo: '_START_〜_END_件を表示 / _TOTAL_件',
                sInfoFiltered: 'が該当（_MAX_件中）',
                sInfoEmpty: '0件',
                sZeroRecords: '該当するデータはありません',
                sLengthMenu: '表示件数 _MENU_',
                oPaginate: {
                    sNext: '',
                    sPrevious: '',
                },
            },

            // 描画後処理
            drawCallback: options.drawCallback,

            rowCallback: options.rowCallback,

            serverSide : options.serverSide,

            fixedColumns : options.fixedColumns,

        };

        if (options.ajax) {
            var complete = options.ajax.complete;
            var _elmToggleCheckBtn = this.elmToggleCheckBtn;
            var _elmToggleFilterNameAttr = this.elmToggleFilterNameAttr;
            var _elmToggleCheckStatusAttr = this.elmToggleCheckStatusAttr;
            options.ajax.complete = function () {
                if (complete != null) {
                    complete();
                }
                if ('true' == $(`[${_elmToggleFilterNameAttr}='check']`).attr(_elmToggleCheckStatusAttr)) {
                    _elmToggleCheckBtn.dispatchEvent(new Event('click'));
                }
            };
            var ajaxOpts = {
                processing: options.processing,
                columns: options.columns,
                ajax: options.ajax,
            }
            Object.assign(opts, ajaxOpts);
        }
        $.fn.dataTable.ext.errMode = 'none';
        table = $(options.selectorTable).on( 'error.dt', function ( e, settings, techNote, message ) {
            $.popupErrorMessage([
                '予期せぬエラーが発生しました。お手数ですが、直前に行った操作を再度お試しください。',
                'このエラーが何度も続く場合、システム管理者へお問い合わせください。',
                'エラーコード：' + e.timeStamp
            ]);
            console.error(message);
        }).DataTable(opts);
        var initDataTable = table;

        /**
         * `$.fn.dataTable.ext.search.push()`で設定した内容を、input入力時に実行する
         */
        $(options.selectorSearchInput).keyup(function () {
            table.draw();
        });

        /**
         * 横スクロール
         */
        $(options.selectorTable).parent().on('scroll', function () {
            var parent = $(this).parent();
            var scrollWidthFull = $(this).children().width();
            var scrollWidthView = $(this).width();
            var nowScrollLeft = $(this).scrollLeft();
            if (nowScrollLeft >= scrollWidthFull - scrollWidthView) {
                parent.addClass('__scroll-finish')
            } else {
                parent.removeClass('__scroll-finish')
            }
        });

        // ↓ 高さ固定オプションを使うなら実行
        /**
         * サイドバーの開閉時にテーブルの幅を再計算させる
         * https://datatables.net/reference/api/columns.adjust()
         */
        var sidebar = document.querySelector('#js-sidebar')
        var toggleBtn = document.querySelector('#js-sidebar-toggle-btn')
        var tableColumnsAjust = function () {
        }
        sidebar.addEventListener('mouseenter', (_ev) => {
            tableColumnsAjust()
        })
        sidebar.addEventListener('mouseleave', (_ev) => {
            tableColumnsAjust()
        })
        toggleBtn.addEventListener('click', (_ev) => {
            tableColumnsAjust()
        })

        // チェックされたのみ表示ボタンクリックで、チェックされたのみ表示/全表示
        if (this.elmToggleCheckBtn){
            this.elmToggleCheckBtn.addEventListener('click', (_ev) => {
                _ev.preventDefault();
                let data_js_status = this.elmToggleCheckBtn.getAttribute(this.elmToggleCheckStatusAttr);
                if (data_js_status == "false") {
                    this.elmToggleCheckBtn.innerHTML = '全表示';
                    this.elmToggleCheckBtn.setAttribute(this.elmToggleCheckStatusAttr, 'true');
                } else {
                    this.elmToggleCheckBtn.innerHTML = '☑のみ表示';
                    this.elmToggleCheckBtn.setAttribute(this.elmToggleCheckStatusAttr, 'false');
                }
                this.toggleFilter(table);
                table.draw(true);
            });
        }
        return initDataTable;
    }
}
